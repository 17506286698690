import Header from "./include/header";
import Footer from "./include/footer";
import { Col, Container, Row } from 'react-bootstrap';
import Images from '../../constant/images';
import GetRequestProposalForm from "../getRequestProposalForm";
import CommonMeta from './include/CommonMeta';
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import MobileFooter from "./include/mobileFooter";
import MobileHeader from "./include/mobileHeader";
function RequestProposal() {

  return (
    <div>
      <CommonMeta
        title='Request Proposal'
        description='Remote Philippines Dashboard | Request Proposal'
        keywords='Remote Philippines Dashboard | Request Proposal'
        name='Remote Philippines'
        type='article' />
      {isDesktop || isTablet ? <Header /> : <MobileHeader />}
      <div className='global-banner-2 bg-sea-1'>
        <Container>
          <Row>
            <Col md={6}>
              <div className="request-a-proposal-header-text">
                <h1 className="">Request a proposal</h1>
                <p className="">Get access to top Professionals across 7000+ islands in the Philippines. Build a high-performing team to grow your business.</p>
              </div>
            </Col>
            <Col md={6}>
              <div className="global-banner-2__gradient-overlay d-flex justify-content-end">
                <div className="gradient-bar gradient-primary-2 d-lg-block" ></div>
                <div className="gradient-bar gradient-primarys-1 d-lg-block"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='request-contact'>
        <Container>
          <Row>
            <Col>
              <div className='onboard-talent'>
                <div className='img-section'>
                  <img loading="lazy" src={Images.hireRemoteStaff} alt=''></img>
                </div>
                <div className='text-section'>
                  <h1>Hire a remote staff, fast!</h1>
                  <p>Get access to a highly-skilled pool of remote talent who can start <br /> and actually get to work ASAP.</p>
                </div>
              </div>
              <div className='onboard-talent'>
                <div className='img-section'>

                  <img loading="lazy" src={Images.workPartner} alt=''></img>
                </div>
                <div className='text-section'>
                  <h1>Work with a trusted outsourcing Partner.</h1>
                  <p>Remote Philippines will be your grow partner and always know that we <br /> are with you every step of the way.</p>
                </div>
              </div>
              <div className='onboard-talent'>
                <div className='img-section'>
                  <img loading="lazy" src={Images.premium} alt=''></img>
                </div>
                <div className='text-section'>
                  <h1>Leverage premium Filipino talents.</h1>
                  <p>We have a huge network of top administrative and technology talent <br /> available, and we're prepared to take on your most crucial projects.</p>
                </div>
              </div>
            </Col>
            <Col>
              <div className='request-contact-form'>
                <h2>Unlock a new world of talent with outsourcing.</h2>
                <p>One of our outsourcing experts will get in touch with you right away.</p>
                <GetRequestProposalForm />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {isMobile ? <MobileFooter /> : <Footer />}
    </div>
  );
}

export default RequestProposal;
