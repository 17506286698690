import React, { useRef, useLayoutEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Logo from '../../Logo';
import SearchBox from '../../searchBox';
// import MessengerCustomerChat from 'react-messenger-customer-chat';

function Header() {
    const stickyHeader = useRef()
    const [sticky, setSticky] = useState(false);
    useLayoutEffect(() => {
        const mainHeader = document.getElementById('mainHeader')
        let fixedTop = stickyHeader.current.offsetTop + 150
        const fixedHeader = () => {
            if (window.pageYOffset > fixedTop) {
                mainHeader.classList.add('fixedTop')
                setSticky(true);
            } else {
                mainHeader.classList.remove('fixedTop')
                setSticky(false);
            }
        }
        window.addEventListener('scroll', fixedHeader)
    }, [])

    return (
        <>
            <div className="header">
                <div className='top-header'>
                    <Container>
                        <Row>
                            <Col><p>Learn all that your business can accomplish with Remote Philippines. <Link className="sales-btn" to="/request-proposal">Connect with Sales</Link> </p></Col>
                        </Row>
                    </Container>
                </div>
                <div className='main-header'>
                    <div className='middle-header'>
                        <Container fluid>
                            <Row>
                                <Col>
                                    <ul className="main-nav">

                                        <li className="top-level-link mob-dropdown">
                                            <a href='tel:+61391112490' className="mega-menu book-a-meeting me-0"><i className="fa-solid fa-phone"></i> <span> +61-3-9111-2490</span></a>
                                            <div className="sub-menu-block mobile-nav">
                                                <Row>
                                                    <div className="col-12">
                                                        <ul className="sub-menu-lists">
                                                            <li><Link to='tel:+61391112490'><i className="fa-solid fa-phone"></i> +61-3-9111-2490</Link></li>
                                                            <li><Link to='tel:+19294470422'><i className="fa-solid fa-phone"></i> +1-929-447-0422</Link></li>
                                                            <li><Link to='tel:+4442045770764'><i className="fa-solid fa-phone"></i> +44-420-4577-0764</Link></li>
                                                        </ul>
                                                    </div>
                                                </Row>
                                            </div>
                                        </li>
                                        <li className='book-a-meeting-btn'><a className="me-0" target='_blank' href="https://smartlink.metricool.com/public/smartlink/remote-philippines"> Apply for a Job</a></li>
                                        <li className='book-a-meeting-btn me-0'><Link className="me-0" to="/request-proposal"> Request a Proposal</Link></li>
                                        {/* <li className='book-a-meeting-btn'><Link target='_blank' to="https://calendly.com/remotephilippines/"> Book a meeting</Link></li> */}
                                        {/* <li className="top-level-link">
                                            <a className="mega-menu lang-menu"><span>English</span></a>
                                            <div className="sub-menu-block lang-nav">
                                                <Row>
                                                    <div className="col-md-6 col-lg-6 col-sm-6 border-end">
                                                        <ul className="sub-menu-lists">
                                                            <li><Link to="/">عَرَبِيّ</Link></li>
                                                            <li><Link to="/">Bahasa Indonesia</Link></li>
                                                            <li><Link to="/">Deutsch</Link></li>
                                                            <li><Link to="/">Español</Link></li>
                                                            <li><Link to="/">Français</Link></li>
                                                            <li><Link to="/">हिंदी</Link></li>
                                                            <li><Link to="/">עִברִית</Link></li>
                                                            <li><Link to="/">Italiano</Link> </li>
                                                            <li><Link to="/"> 日本語</Link></li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-6 col-lg-6 col-sm-6">
                                                        <ul className="sub-menu-lists">
                                                            <li><Link to="/"> 한국어</Link></li>
                                                            <li><Link to="/"> Nederlands</Link></li>
                                                            <li><Link to="/"> Português</Link></li>
                                                            <li><Link to="/"> Polski</Link></li>
                                                            <li><Link to="/"> Svenska</Link></li>
                                                            <li><Link to="/"> ภาษาไทย</Link></li>
                                                            <li><Link to="/"> 中文（简）</Link></li>
                                                            <li><Link to="/"> 中文（粤）</Link></li>
                                                            <li><Link to="/"> 中文（繁）</Link></li>
                                                        </ul>
                                                    </div>
                                                </Row>
                                            </div>
                                        </li> */}
                                        {/* <li className="top-level-link">
                                            <Link className='user-login-btn' to='/'>Login <i className="fa-regular fa-user"></i></Link>
                                        </li> */}
                                    </ul>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="mainHeader navbars " id="mainHeader" ref={stickyHeader}>
                        <div className='bottom-header'>
                            <Container fluid className='navbar-inner'>
                                <Row className='align-items-center'>
                                    <Col md={4} lg={2}>
                                        <div className='logo-section'>
                                            <Link to='/'> <Logo sticky={sticky} /></Link>
                                        </div>
                                    </Col>
                                    <Col md={6} lg={8}>
                                        <div className='navbar-inner-wrapper'>
                                            <ul className="main-nav">
                                                <li className="top-level-link">
                                                    <a className="mega-menu"><span>About</span></a>
                                                    <div className="sub-menu-block">
                                                        <Row>
                                                            <div className="col-md-6 col-lg-6 col-sm-6 border-end">
                                                                <ul className="sub-menu-lists">
                                                                    <li><Link to='/our-mission'>Our Mission</Link></li>
                                                                    <li><Link to='/our-people'>Our People</Link></li>
                                                                    <li><Link to='/work-with-us'>Why Work with Us</Link></li>
                                                                    <li><Link to='/our-services'>Our Services</Link></li>
                                                                    <li><Link to='/benefits'>Benefits</Link></li>
                                                                </ul>
                                                            </div>
                                                            <div className="col-md-6 col-lg-6 col-sm-6">
                                                                <ul className="sub-menu-lists">
                                                                    <li><Link to='/the-philippines'>The Philippines</Link></li>
                                                                    <li><Link to='/engagement-process'>Engagement Process</Link></li>
                                                                    <li><Link to='/community'>Community</Link></li>
                                                                    <li><Link to='/legal?title=terms-of-service'>Legal</Link></li>
                                                                    <li><Link to='/contact-us'>Contact Us</Link></li>
                                                                </ul>
                                                            </div>
                                                        </Row>
                                                    </div>
                                                </li>
                                                <li className="top-level-link">
                                                    <a className="mega-menu"><span>Employers</span></a>
                                                    <div className="sub-menu-block employers-nav">
                                                        <Row>
                                                            <div className="col-md-4 col-lg-4 col-sm-4 border-end">
                                                                <div className="sub-menu-header"><Link to='/'> SECTOR</Link> </div>
                                                                <ul className="sub-menu-lists">
                                                                    <li><Link to='/information-technology'> Information Technology</Link></li>
                                                                    <li><Link to='/real-estate'> Real Estate</Link></li>
                                                                    <li><Link to='/hospitality'> Hospitality</Link></li>
                                                                    <li><Link to='/legal-services'> Legal Services</Link></li>
                                                                    <li><Link to='/data-management'> Data Management</Link></li>
                                                                    <li><Link to='/telecommunications'> Telecommunications</Link></li>
                                                                    <li><Link to='/healthcare'> Healthcare</Link></li>
                                                                    <li><Link to='/transportation'> Transportation</Link></li>
                                                                    <li><Link to='/utilities-and-energy'> Utilities and Energy </Link></li>
                                                                    <li><Link to='/travel'> Travel</Link></li>
                                                                    <li><Link to='/e-commerce'> e-Commerce</Link></li>
                                                                    <li><Link to='/education'> Education</Link></li>
                                                                    <li><Link to='/engineering'> Engineering </Link></li>
                                                                    <li><Link to='/financial-services'> Financial Services</Link></li>
                                                                    <li><Link to='/logistics'>Logistics </Link></li>
                                                                </ul>
                                                            </div>
                                                            <div className="col-md-4 col-lg-4 col-sm-4 border-end">
                                                                <div className="sub-menu-header"> <Link to='/'>ROLE</Link></div>
                                                                <ul className="sub-menu-lists">
                                                                    <li><Link to='/finance-and-accounting'>Finance & Accounting </Link></li>
                                                                    <li><Link to='/customer-service'>Customer Service </Link></li>
                                                                    <li><Link to='/lead-gen-and-sales'>Lead Gen & Sales </Link></li>
                                                                    <li><Link to='/digital-marketing'>Digital Marketing </Link></li>
                                                                    <li><Link to='/human-resources'>Human Resources </Link></li>
                                                                    <li><Link to='/design-and-graphics'>Design & Graphics </Link></li>
                                                                    <li><Link to='/software-development'>Software Development </Link></li>
                                                                    <li><Link to='/virtual-assistance'>Virtual Assistance </Link></li>
                                                                    <li><Link to='/engineer'>Engineering </Link></li>
                                                                    <li><Link to='/medical'>Medical </Link></li>
                                                                    <li><Link to='/data-science'>Data Science </Link></li>
                                                                    <li><Link to='/technical-support'>Technical Support </Link></li>
                                                                    <li><Link to='/project-management'>Project Management </Link></li>
                                                                    <li><Link to='/c-executives'>C-Executives </Link></li>
                                                                </ul>
                                                            </div>
                                                            <div className="col-md-4 col-lg-4 col-sm-4">
                                                                <div className="sub-menu-header"><Link to='/'>COMPANY</Link></div>
                                                                <ul className="sub-menu-lists">
                                                                    <li><Link to='/startup'>Startups </Link></li>
                                                                    <li><Link to='/small-and-medium-sized-businesses'>Small & Medium Sized Businesses </Link></li>
                                                                    <li><Link to='/enterprise'>Enterprise </Link></li>
                                                                    <li><Link to='/high-growth'>High-Growth </Link></li>
                                                                    <li><Link to='/cost-cutting'>Cost-Cutting </Link></li>
                                                                    <li><Link to='/agency'>Agency </Link></li>
                                                                    <li><Link to='/shared-services'>Shared Services </Link></li>
                                                                    <li><Link to='/technology'>Technology </Link></li>
                                                                    <li><Link to='/bootstrapped'>Bootstrapped </Link></li>
                                                                </ul>
                                                            </div>
                                                        </Row>
                                                    </div>
                                                </li>
                                                <li className="top-level-link">
                                                    <a className="mega-menu"><span>Talents</span></a>
                                                    <div className="sub-menu-block talents-nav">
                                                        <Row>
                                                            <div className="col-md-12 col-lg-12 col-sm-12">
                                                                <ul className="sub-menu-lists">
                                                                    <li><Link to='/careers'>We’re Hiring </Link></li>
                                                                    <li><Link to='/hiring-process'>Hiring Process </Link></li>
                                                                    {/* <li><Link to='/gallery'>Gallery </Link></li> */}
                                                                </ul>
                                                            </div>
                                                        </Row>
                                                    </div>
                                                </li>
                                                <li className="top-level-link">
                                                    <a className="mega-menu"><span>Knowledge</span></a>
                                                    <div className="sub-menu-block knowledge-nav">
                                                        <Row>
                                                            <div className="col-md-12 col-lg-12 col-sm-12">
                                                                <ul className="sub-menu-lists">
                                                                    {/* <li><Link to='/'>Webinars</Link></li>
                                                                    <li><Link to='/'>Templates</Link></li>
                                                                    <li><Link to='/'>E-Course</Link></li>
                                                                    <li><Link to='/'>E-Book</Link></li> */}
                                                                    <li><a href='https://www.remotephilippines.com/blog/'>Blog</a></li>
                                                                    <li><Link to='/faq'>FAQ</Link></li>
                                                                </ul>
                                                            </div>
                                                        </Row>
                                                    </div>
                                                </li>
                                                {/* <li className="top-level-link">
                                                    <Link to='/price-guide'>Price Guide</Link>
                                                </li> */}
                                            </ul>
                                            {/* <SearchBox /> */}
                                        </div>
                                    </Col>
                                    
                                    <Col md={2} lg={2}>
                                        <div className='request-a-proposal-btn'><Link className="request-a-proposal" target='_blank' to="https://calendly.com/remotephilippines/"> Book a meeting</Link></div>
                                    </Col>
                                </Row>
                                {/* <div className='MessengerCustomerChat'>
                                    <MessengerCustomerChat
                                        pageId="100382141496705"
                                        appId="925057228705646"
                                    />
                                </div> */}
                            </Container>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
export default Header;
