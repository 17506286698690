
import logoWhite from '../assets/images/rph_logo_white.png';
import outsourcing_partner from '../assets/images/outsourcing_partner.PNG';
import filipino_talents from '../assets/images/filipino_talents.PNG';
import contact_img from '../assets/images/contact-img.jpg';
import our_solution_finance_content2 from '../assets/images/our-solution-finance-content2.jpg';
import our_solution_finance_content1 from '../assets/images/our-solution-finance-content1.jpg';
import superior_expertis from '../assets/images/superior-expertis.PNG';
import woman_phone from '../assets/images/woman-phone.PNG';
import people_office from '../assets/images/people-office.jpg';
import people_monitor from '../assets/images/people-monitor.jpg';
import people_work from '../assets/images/people-work.jpg';
import people_work_2 from '../assets/images/people-work-2.jpg';
import php from '../assets/images/logo/php.png';
import java from '../assets/images/logo/java.png';
import wordpress from '../assets/images/logo/wordpress.png';
import sql from '../assets/images/logo/sql.png';
import admin_customer_support from '../assets/images/admin-customer-support.PNG';
import sales from '../assets/images/icons/sales.png';
import ask_anything from '../assets/images/icons/ask-anything.png';
import careers1 from '../assets/images/icons/careers.png';
import globalpedia_hero_us1 from '../assets/images/globalpedia-hero-us-1.jpg';
import flag from '../assets/images/flag.PNG';
import be_partner_about_us from '../assets/images/be-partner-about-us.jpg';
import Hamburger from '../assets/images/hamburger.png';
import Hamburger2 from '../assets/images/more.png';
import cancel from '../assets/images/cancel.png';
import au from '../assets/images/au.svg';
import ca from '../assets/images/ca.svg';
import eu from '../assets/images/eu.svg';
import sg from '../assets/images/sg.svg';
import gb from '../assets/images/gb.svg';
import driftLogo from '../assets/images/drift-svg-logo.svg';
import seattleSeahawksLogo from '../assets/images/seattle-seahawks-svg-logo.svg';
import gongLogo from '../assets/images/gong-svg-logo.svg';
import smileDirectClubLogo from '../assets/images/smile-direct-club-svg-logo.svg';
import universityChicagoLogo from '../assets/images/university-chicago-svg-logo.svg';
import bg_Platform from '../assets/images/bg-platform.jpg';
import neurala from '../assets/images/neurala.svg';
import sonicWall from '../assets/images/sonic-wall.svg';
import teamWord from '../assets/images/team-word.svg';
import step1 from '../assets/images/1.svg';
import step2 from '../assets/images/2.svg';
import step3 from '../assets/images/3.svg';
import bgSmile from '../assets/images/bg-smile.png';
import linkedin from '../assets/images/linkedin.png';
import bannerImgTop from '../assets/images/banner-img-top.png';
import pangeoAwards from '../assets/images/pangeo-awards.jpg';
import oursolutionHighlights from '../assets/images/oursolution-highlights-1.jpg';
import englishProficiency from '../assets/images/english-proficiency.PNG';
import workEthics from '../assets/images/work-ethics.PNG';
import bgOpportunity from '../assets/images/bg-opportunity.jpg';
import logo1 from '../assets/images/logo/1.webp';
import logo2 from '../assets/images/logo/2.webp';
import logo3 from '../assets/images/logo/3.webp';
import logo4 from '../assets/images/logo/4.webp';
import logo5 from '../assets/images/logo/5.webp';
import logo6 from '../assets/images/logo/6.webp';
import logo7 from '../assets/images/logo/7.webp';
import logo8 from '../assets/images/logo/8.webp';
import logo9 from '../assets/images/logo/9.webp';
import logo10 from '../assets/images/logo/10.webp';
import logo11 from '../assets/images/logo/11.webp';
import logo12 from '../assets/images/logo/12.webp';
import logo13 from '../assets/images/logo/13.webp';
import logo14 from '../assets/images/logo/14.webp';
import logo15 from '../assets/images/logo/15.webp';
import logo16 from '../assets/images/logo/16.png';
import logo17 from '../assets/images/logo/17.webp';
import logo18 from '../assets/images/logo/18.webp';
import logo19 from '../assets/images/logo/19.webp';
import logo20 from '../assets/images/logo/20.webp';
import logo21 from '../assets/images/logo/21.webp';
import logo22 from '../assets/images/logo/22.webp';
import logo23 from '../assets/images/logo/23.webp';
import logo24 from '../assets/images/logo/24.webp';
import logo25 from '../assets/images/logo/25.webp';
import logo26 from '../assets/images/logo/26.webp';
import logo27 from '../assets/images/logo/27.webp';
import logo28 from '../assets/images/logo/28.webp';
import logo29 from '../assets/images/logo/29.webp';
import logo30 from '../assets/images/logo/30.webp';
import logo31 from '../assets/images/logo/31.webp';
import logo32 from '../assets/images/logo/32.webp';
import logo33 from '../assets/images/logo/33.webp';
import logo34 from '../assets/images/logo/34.webp';
import logo35 from '../assets/images/logo/35.webp';
import logo36 from '../assets/images/logo/36.webp';
import logo37 from '../assets/images/logo/37.webp';
import logo38 from '../assets/images/logo/38.webp';
import logo39 from '../assets/images/logo/39.webp';
import logo40 from '../assets/images/logo/40.webp';
import logo41 from '../assets/images/logo/41.webp';
import ourSolution2 from '../assets/images/our-solution2.jpg';
import downArrow from '../assets/images/down-arrow.png';
import downArrow1 from '../assets/images/down-arrow1.png';
import comply from '../assets/images/home/services/comply.png';
import hire from '../assets/images/home/services/hire.png';
import hr from '../assets/images/home/services/hr.png';
import pay from '../assets/images/home/services/pay.png';
import recruit from '../assets/images/home/services/recruit.png';
import taxes from '../assets/images/home/services/taxes.png';
import CustomerSatisfaction from '../assets/images/home/services/customer-satisfaction.png';
import globaltalent from '../assets/images/home/services/global-talent.png';
import hr_expects from '../assets/images/home/services/hr_expects.png';
import payroll from '../assets/images/home/services/payroll.png';
import discover from '../assets/images/home/services/discover.png';
import engage from '../assets/images/home/services/engage.png';
import grow from '../assets/images/home/services/grow.png';
import culture from '../assets/images/culture.png';
import english_Proficiency from '../assets/images/englishProficiency.png';
import economy from '../assets/images/economy.png';
import work_Ethics from '../assets/images/workEthics.png';
import manScale from '../assets/images/man-scale.png';
import hireRemoteStaff from '../assets/images/icons/hireRemoteStaff.png';
import workPartner from '../assets/images/icons/workPartner.png';
import premium from '../assets/images/icons/premium.png';
import cost from '../assets/images/icons/ItIcons/cost.png';
import efficiency from '../assets/images/icons/ItIcons/efficiency.png';
import flexibility from '../assets/images/icons/ItIcons/flexibility.png';
import focus from '../assets/images/icons/ItIcons/focus.png';
import scalability from '../assets/images/icons/ItIcons/scalable.png';
import timeToMarket from '../assets/images/icons/ItIcons/time-to-market.png';
import benefitsPackage from '../assets/images/benefits/benefits_package.png';
import Compliance from '../assets/images/benefits/compliance.png';
import Experts from '../assets/images/benefits/experts.png';
import payrollTaxes from '../assets/images/benefits/payroll_taxes.png';
import Registered from '../assets/images/benefits/registered.png';
import teamSimple from '../assets/images/benefits/team_simple.png';
import employmentTaxesCompliance from '../assets/images/benefits/employment_taxes_compliance.png';
import fairPricing from '../assets/images/benefits/fair_pricing.png';
import hireStaff from '../assets/images/benefits/hire_staff.png';
import hrSupport from '../assets/images/benefits/hr_support.png';
import multiCurrencyPayments from '../assets/images/benefits/multi_currency_payments.png';
import reliablePartner from '../assets/images/benefits/reliable_partner.png';
import saveUp from '../assets/images/benefits/save_up.png';
import superiorExpertise from '../assets/images/benefits/superior_expertise.png';
import pexelsNataliya from '../assets/images/pexels-nataliya.png';
import Why_Work_With_Us from '../assets/images/Why_Work_With_Us.png';
import Asset1 from '../assets/images/Asset_1.png';
import Asset2 from '../assets/images/Asset_2.png';
import Asset3 from '../assets/images/Asset_3.png';
import Asset4 from '../assets/images/Asset_4.png';
import Asset5 from '../assets/images/Asset_5.png';
import Asset6 from '../assets/images/Asset_6.png';
import Asset7 from '../assets/images/Asset_7.png';
import Asset8 from '../assets/images/Asset_8.png';
import Asset9 from '../assets/images/Asset_9.png';
import Asset10 from '../assets/images/Asset_10.png';
import Asset11 from '../assets/images/Asset_11.png';
import Asset12 from '../assets/images/Asset_12.png';
import Asset13 from '../assets/images/Asset_13.png';
import Asset14 from '../assets/images/Asset_14.png';
import Asset15 from '../assets/images/Asset_15.png';
import Asset16 from '../assets/images/Asset_16.png';
import Asset17 from '../assets/images/Asset_17.png';
import Asset18 from '../assets/images/Asset_18.png';
import Asset19 from '../assets/images/Asset_19.png';
import Asset20 from '../assets/images/Asset_20.png';
import Asset21 from '../assets/images/Asset_21.png';
import Asset22 from '../assets/images/Asset_22.png';
import Asset23 from '../assets/images/Asset_23.png';
import Asset24 from '../assets/images/Asset_24.png';
import officeFemale from '../assets/images/office_female.png';
import officeMale from '../assets/images/office_male.png';
import officeWorker from '../assets/images/office_worker.png';
import marketing from '../assets/images/marketing.png';
import RP_Staff from '../assets/images/RP_Staff.png';
import Work_from_home from '../assets/images/Work_from_home.jpg';
import challenges from '../assets/images/challenges.jpg';
import Quick_tips from '../assets/images/Quick_tips.png';
import team1 from '../assets/images/profiles/team1.png';
import team2 from '../assets/images/profiles/team2.png';
import team3 from '../assets/images/profiles/team3.png';
import team4 from '../assets/images/profiles/team4.png';
import team5 from '../assets/images/profiles/team5.png';
import team6 from '../assets/images/profiles/team6.png';
import team7 from '../assets/images/profiles/team7.png';
import team8 from '../assets/images/profiles/team8.png';
import team9 from '../assets/images/profiles/team9.png';
import team10 from '../assets/images/profiles/team10.png';
import team11 from '../assets/images/profiles/team11.png';
import team12 from '../assets/images/profiles/team12.png';
import team13 from '../assets/images/profiles/team13.png';
import team14 from '../assets/images/profiles/team14.png';
import team15 from '../assets/images/profiles/team15.png';
import Join_Our_Team from '../assets/images/Join_Our_Team.png';
import Benefits_top from '../assets/images/Benefits_top.png';
import thePhilippines from '../assets/images/thePhilippines.png';
import groupPicture from '../assets/images/groupPicture.png';
import join_The_RP from '../assets/images/join_The_RP.png';
import join_The_RP2 from '../assets/images/join_The_RP2.png';
import Engagement from '../assets/images/Engagement.png';
import Engagement2 from '../assets/images/Engagement_2.png';
import Antipolo from '../assets/images/ContactUs/Antipolo.jpg';
import Bacolod from '../assets/images/ContactUs/Bacolod.png';
import Batangas from '../assets/images/ContactUs/Batangas.jpg';
import Batanes from '../assets/images/ContactUs/Batanes.png';
import Bulacan from '../assets/images/ContactUs/Bulacan.png';
import Caloocan from '../assets/images/ContactUs/Caloocan.jpg';
import Cavite from '../assets/images/ContactUs/Cavite.jpg';
import Cebu from '../assets/images/ContactUs/Cebu.jpg';
import ContactUs from '../assets/images/ContactUs/ContactUs.png';
import Contact_Us from '../assets/images/ContactUs/Contact_Us.png';
import Davao from '../assets/images/ContactUs/Davao.jpg';
import GeneralSantos from '../assets/images/ContactUs/GeneralSantosCity.jpg';
import Laguna from '../assets/images/ContactUs/Laguna.jpg';
import Manila from '../assets/images/ContactUs/Manila.jpg';
import Novaliches from '../assets/images/ContactUs/Novaliches.jpg';
import Pampanga from '../assets/images/ContactUs/Pampanganew.png';
import Pangasinan from '../assets/images/ContactUs/PangasinanNew.jpg';
import Paranaque from '../assets/images/ContactUs/Paranaque.jpg';
import Pasig from '../assets/images/ContactUs/Pasig.jpeg';
import QuezonCity from '../assets/images/ContactUs/QuezonCity.jpg';
import Tagaytay from '../assets/images/ContactUs/Tagaytay_new.jpg';
import Taguig from '../assets/images/ContactUs/Taguignew.jpg';
import Zamboanga from '../assets/images/ContactUs/Zamboanganew.jpg';
import Baguio from '../assets/images/ContactUs/baguio-lions-head-new.jpg';
import Bicol from '../assets/images/ContactUs/bicol.jpg';
import CagayandeOro from '../assets/images/ContactUs/cagayan-de-oro.png';
import LasPinas from '../assets/images/ContactUs/laspinas.png';
import Makati from '../assets/images/ContactUs/makati.jpg';
import Pasay from '../assets/images/ContactUs/pasaynew.jpg';
import Ms_Debbie from '../assets/images/OurMission/Ms_Debbie.png';
import Ms_Grace_and_client from '../assets/images/OurMission/Ms_Grace_and_client.png';
import Ms_Jai from '../assets/images/OurMission/Ms_Jai.png';
import Office from '../assets/images/OurMission/Office.png';
import Our_Mission from '../assets/images/OurMission/Our_Mission.png';
import Profile_Gradient_Crop from '../assets/images/OurMission/Profile_Gradient_Crop.png';
import Profile_with_Gradient from '../assets/images/OurMission/Profile_with_Gradient-Crop.png';
import Profile_with_Gradient_Crop from '../assets/images/OurMission/Profile_with_Gradient_Crop.png';
import Website_Sample from '../assets/images/OurMission/Website_Sample.png';
import Work_freedom from '../assets/images/OurMission/Work_freedom.png';
import Works_freedom from '../assets/images/OurMission/Works_freedom.png';
import collage from '../assets/images/OurMission/collage.png';
import office_red_male from '../assets/images/OurMission/office_red_male.png';
import Aica from '../assets/images/OurMission/Aica.png';
import ourMissionTop from '../assets/images/OurMission/our_mission_top.png';
import Discover_icon from '../assets/images/discover.svg';
import Success_icon from '../assets/images/success.svg';
import emotional_wellbeing from '../assets/images/emotional_wellbeing.svg';
import financial_wellbeing from '../assets/images/financial_wellbeing.svg';
import physical_wellbeing from '../assets/images/physical_wellbeing.svg';
import social_wellbeing from '../assets/images/social_wellbeing.svg';
import Flag_Philippines from '../assets/images/Flag_Philippines.png';
import CustomerFocused from '../assets/images/home/services/Customer-Focused.png';
import GrowthCentric from '../assets/images/home/services/Growth-centric.png';
import OneTeam from '../assets/images/home/services/One-Team.png';
import WorkLifeBalance from '../assets/images/home/services/Work-Life-Balance.png';
import CareerDevelopment from '../assets/images/home/services/Career-Development.png';
import FinancialGrowth from '../assets/images/home/services/Financial-Growth.png';
import SupportSystem from '../assets/images/home/services/Support-System.png';
import RemoteFirstCulture from '../assets/images/home/services/Remote-First-Culture.png';
import PositiveCulture from '../assets/images/home/services/Positive-Culture.png';
import Homepage from '../assets/images/Homepage.png';
import HomepageModel1 from '../assets/images/Homepagemodel1.png';
import HomepageModel2 from '../assets/images/Homepagemodel2.png';
import Background_check from '../assets/images/HiringProcess/Background_check.png';
import Candidate_Screening from '../assets/images/HiringProcess/Candidate_Screening.png';
import Client_Interview from '../assets/images/HiringProcess/Client_Interview.png';
import Decision from '../assets/images/HiringProcess/Decision.png';
import Recruit_Position from '../assets/images/HiringProcess/Recruit_Position.png';
import Review_Applications from '../assets/images/HiringProcess/Review_Applications.png';
import Send_Offer from '../assets/images/HiringProcess/Send_Offer.png';
import Technical_Assessment from '../assets/images/HiringProcess/Technical_Assessment.png';
import rphLogo from '../assets/images/rph_logo.png';
import WomanFace from '../assets/images/woman_face.png';
import ManFace from '../assets/images/man_face.png';
import ContactPageImage from '../assets/images/careers_page.png';
import heroSuite from '../assets/images/ourServices/hero-suite.png';
import visaIcon from '../assets/images/ourServices/visa-icon.png';
import hiringIllustration from '../assets/images/ourServices/hiring_illustration.png';
import valuepropLogos from '../assets/images/ourServices/value-prop_logos.png';
import OfficeBasedStaff from '../assets/images/ourServices/Office-Based-Staff.png';
import RemoteStaff from '../assets/images/ourServices/Remote-Staff.png';
import ExecutiveSearch from '../assets/images/ourServices/ExecutiveSearch.png';
import HowItWorks from '../assets/images/howitworks.png';
import HireOfficeBased from '../assets/images/ourServices/HireOfficeBased.png';
import employersIcon from '../assets/images/icons/employers.png';
import aboutIcon from '../assets/images/icons/about.png';
import knowledgeIcon from '../assets/images/icons/knowledge.png';
import servicesIcon from '../assets/images/icons/services.png';
import aboutSelected from '../assets/images/FooterNav/about_selected.png';
import aboutUnselected from '../assets/images/FooterNav/about_unselected.png';
import employersSelected from '../assets/images/FooterNav/employers_selected.png';
import employersUnselected from '../assets/images/FooterNav/employers_unselected.png';
import knowledgeSelected from '../assets/images/FooterNav/knowledge_selected.png';
import knowledgeUnselected from '../assets/images/FooterNav/knowledge_unselected.png';
import talentsSelected from '../assets/images/FooterNav/talents_selected.png';
import talentsUnselected from '../assets/images/FooterNav/talents_unselected.png';
import homeSelected from '../assets/images/FooterNav/home_selected.png';
import homeUnselected from '../assets/images/FooterNav/home_unselected.png';


const Images = {
    HireOfficeBased,
    HowItWorks,
    ExecutiveSearch,
    OfficeBasedStaff,
    RemoteStaff,
    valuepropLogos,
    hiringIllustration,
    visaIcon,
    heroSuite,
    ContactPageImage,
    WomanFace,
    ManFace,
    rphLogo,
    Technical_Assessment,
    Send_Offer,
    Review_Applications,
    Recruit_Position,
    Decision,
    Client_Interview,
    Candidate_Screening,
    Background_check,
    HomepageModel1,
    HomepageModel2,
    Homepage,
    PositiveCulture,
    RemoteFirstCulture,
    SupportSystem,
    FinancialGrowth,
    CareerDevelopment,
    WorkLifeBalance,
    OneTeam,
    GrowthCentric,
    CustomerFocused,
    Flag_Philippines,
    emotional_wellbeing,
    financial_wellbeing,
    physical_wellbeing,
    social_wellbeing,
    Discover_icon,
    Success_icon,
    ourMissionTop,
    Aica,
    Ms_Debbie,
    Ms_Grace_and_client,
    Ms_Jai,
    Office,
    Our_Mission,
    Profile_Gradient_Crop,
    Profile_with_Gradient,
    Profile_with_Gradient_Crop,
    Website_Sample,
    Work_freedom,
    Works_freedom,
    collage,
    office_red_male,

    Antipolo,
    Bacolod,
    Batangas,
    Batanes,
    Bulacan,
    Caloocan,
    Cavite,
    Cebu,
    ContactUs,
    Contact_Us,
    Davao,
    GeneralSantos,
    Laguna,
    Manila,
    Novaliches,
    Pampanga,
    Pangasinan,
    Paranaque,
    Pasig,
    QuezonCity,
    Tagaytay,
    Taguig,
    Zamboanga,
    Baguio,
    Bicol,
    CagayandeOro,
    LasPinas,
    Makati,
    Pasay,
    thePhilippines,
    Engagement,
    Engagement2,
    join_The_RP2,
    join_The_RP,
    groupPicture,
    Join_Our_Team,
    Why_Work_With_Us,
    Benefits_top,
    team1,
    team2,
    team3,
    team4,
    team5,
    team6,
    team7,
    team8,
    team9,
    team10,
    team11,
    team12,
    team13,
    team14,
    team15,
    RP_Staff,
    Work_from_home,
    challenges,
    Quick_tips,
    officeWorker,
    marketing,
    officeMale,
    officeFemale,
    pexelsNataliya,
    employmentTaxesCompliance,
    fairPricing,
    hireStaff,
    hrSupport,
    multiCurrencyPayments,
    reliablePartner,
    superiorExpertise,
    saveUp,
    Asset1,
    Asset2,
    Asset3,
    Asset4,
    Asset5,
    Asset6,
    Asset7,
    Asset8,
    Asset9,
    Asset10,
    Asset11,
    Asset12,
    Asset13,
    Asset14,
    Asset15,
    Asset16,
    Asset17,
    Asset18,
    Asset19,
    Asset20,
    Asset21,
    Asset22,
    Asset23,
    Asset24,
    culture,
    Compliance,
    Experts,
    payrollTaxes,
    Registered,
    teamSimple,
    benefitsPackage,
    hireRemoteStaff,
    premium,
    workPartner,
    manScale,
    english_Proficiency,
    CustomerSatisfaction,
    economy,
    work_Ethics,
    globaltalent,
    grow,
    discover,
    engage,
    hr_expects,
    payroll,
    hr,
    comply,
    hire,
    recruit,
    pay,
    taxes,
    logoWhite,
    outsourcing_partner,
    filipino_talents,
    contact_img,
    our_solution_finance_content2,
    our_solution_finance_content1,
    superior_expertis,
    cost,
    efficiency,
    flexibility,
    focus,
    scalability,
    timeToMarket,
    woman_phone,
    people_office,
    people_monitor,
    people_work,
    people_work_2,
    admin_customer_support,
    sales,
    ask_anything,
    careers1,
    globalpedia_hero_us1,
    flag,
    be_partner_about_us,
    Hamburger,
    Hamburger2,
    cancel,
    au,
    ca,
    eu,
    sg,
    gb,
    bg_Platform,
    neurala,
    sonicWall,
    universityChicagoLogo,
    smileDirectClubLogo,
    seattleSeahawksLogo,
    gongLogo,
    driftLogo,
    teamWord,
    step1,
    step2,
    step3,
    bgSmile,
    linkedin,
    bannerImgTop,
    pangeoAwards,
    oursolutionHighlights,
    englishProficiency,
    workEthics,
    bgOpportunity,
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,
    logo8,
    logo9,
    logo10,
    logo11,
    logo12,
    logo13,
    logo14,
    logo15,
    logo16,
    logo17,
    logo18,
    logo19,
    logo20,
    logo21,
    logo22,
    logo23,
    logo24,
    logo25,
    logo26,
    logo27,
    logo28,
    logo29,
    logo30,
    logo31,
    logo32,
    logo33,
    logo34,
    logo35,
    logo36,
    logo37,
    logo38,
    logo39,
    logo40,
    logo41,
    java,
    php,
    wordpress,
    sql,
    ourSolution2,
    downArrow,
    downArrow1,
    employersIcon,
    aboutIcon,
    knowledgeIcon,
    servicesIcon,
    
    homeSelected,
    homeUnselected,
    aboutSelected,
    aboutUnselected,
    employersSelected,
    employersUnselected,
    knowledgeSelected,
    knowledgeUnselected,
    talentsSelected,
    talentsUnselected,
}
export default Images;
