import Header from "../../include/header";
import Footer from "../../include/footer";
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Images from "../../../../constant/images";
import Partners from "../../../Partners";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import PartnersSlider from "../../../PartnersSlider";
import CommonMeta from "../../include/CommonMeta";
import MobileHeader from "../../include/mobileHeader";
import ServicesSlider from "../../../servicesSlider";
import GetRequestProposalForm from "../../../getRequestProposalForm";
import MobileFooter from "../../include/mobileFooter";
function Hospitality() {

  return (
    <div>
      <CommonMeta
        title=' Hospitality'
        description='Remote Philippines Dashboard | Hospitality'
        keywords='Remote Philippines Dashboard | Hospitality'
        name='Remote Philippines'
        type='article' />
      {isDesktop || isTablet ? <Header /> : <MobileHeader />}
      <div className='global-banner-2 global-banner-pages bg-sea-1'>
        <Container className=''>
          <Row>
            <Col md={12}>
              <div className="request-a-proposal-header-text3">
                <h1 className="">Simplify outsourcing experts in the</h1>
                <h1 className="">Hospitality sector.</h1>
              </div>
            </Col>
            <Col md={12} lg={6}>
              <div className="request-a-proposal-header-text request-a-proposal-header-text2 it-pages">
                <div className="mt-4"><p className="">We provide affordable offshore staffing solutions to help effortlessly scale your business. Our goal is to help businesses successfully reduce cost, increase profit margins and grow sustainably.</p></div>
                <div className='mt-5'> <Link className='link-btn2' to='/request-proposal'>CONNECT WITH SALES</Link></div>
              </div>
            </Col>
            <Col md={12} lg={6}>
              <div className="global-banner-2__gradient-overlay global-banner-role-pages d-flex justify-content-end">
                <div className="gradient-bar gradient-primary-2 d-lg-block d-none"></div>
                <div className="gradient-bar gradient-primarys-1 d-lg-block d-none" ></div>
                {/* <div className="gradient-bar gradient-secondary-1 d-lg-block d-none" ></div>
                <div className="gradient-bar gradient-secondary-5 d-lg-block d-none" ></div>
                <div className="gradient-bar gradient-secondary-8"></div> */}
              </div>
              <div className="finance-accounting-image">
                <img  loading="lazy" src={Images.Asset24} className='img-fluid' alt='' />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="finance-and-accounting-wrapper">
        <Container>
          <Row className="g-0">
            <Col md={12} lg={6}>
              <div className="top-heading">
                <h1>Streamline offshore expansion with a staffing partner who puts your needs first.</h1>
              </div>
            </Col>
            <Col md={12} lg={6}></Col>
          </Row>
          <Row className="g-0 finance-and-accounting-inner">
            <Col md={12} lg={6}>
              <div className="people-coords">
                {/* <div className="border-section1"></div> */}
                {/* <span className="number">34.6037°S 58.3816°W</span> */}
                <div className="image-section">
                  <img  loading="lazy" src={Images.Asset23} className='img-fluid' alt='' />

                </div>
              </div>
              <div className="location-section">
                {/* <div className="location-dot"><i className="fa-solid fa-location-dot"></i></div>
                <div className="location-name">Buenos Aires</div> */}
                {/* <div className="name"> Sarah M.<span> Project Manager</span></div> */}
              </div>
            </Col>
            <Col md={12} lg={6}>
              <div className="people-coords people-coords2">
                {/* <div className="border-section3"></div> */}
                <div className="image-section">
                  {/* <span className="number">51.5072°N 0.1276°W</span> */}
                  <img  loading="lazy" src={Images.Asset22} className='img-fluid' alt='' />
                </div>
                <div className="location-section location-section2">
                  {/* <div className="location-dot"><i className="fa-solid fa-location-dot"></i></div>
                  <div className="location-name">London</div> */}
                  {/* <div className="name"> Peter P.<span> Sales Representative</span></div> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        {/* <div className="border-section2"></div>
        <div className="border-section4"></div> */}
      </div>
      <div className="our-finance-solution">
        <Container>
          <Row>
            <Col md={12} lg={7}></Col>
            <Col md={12} lg={5}>
              <div className="our-finance-solution-inner">
                <h4>Outsource with confidence.</h4>
                <p>Here are 6 key drivers to outsource your Hospitality team.</p>
              </div>
              <div className="our-finance-solution-inner-list">
                <ul>
                  <li><a href='#cost'><img  loading="lazy" src={Images.cost} className='img-fluid' alt='' />Cost <i className="fa-solid fa-arrow-right"></i></a></li>
                  <li><a href='#focus'><img  loading="lazy" src={Images.focus} className='img-fluid' alt='' />Focus <i className="fa-solid fa-arrow-right"></i></a></li>
                  <li><a href='#flexibility'><img  loading="lazy" src={Images.flexibility} className='img-fluid' alt='' />Flexibility <i className="fa-solid fa-arrow-right"></i></a></li>
                  <li><a href='#efficiency'><img  loading="lazy" src={Images.efficiency} className='img-fluid' alt='' />Efficiency <i className="fa-solid fa-arrow-right"></i></a></li>
                  <li><a href='#scalability'><img  loading="lazy" src={Images.scalability} className='img-fluid' alt='' />Scalability <i className="fa-solid fa-arrow-right"></i></a></li>
                  <li><a href='#time-to-market'><img  loading="lazy" src={Images.timeToMarket} className='img-fluid' alt='' />Time-to-market <i className="fa-solid fa-arrow-right"></i></a></li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="work-your-business-wrapper">
        <Container>
          <Row>
            <Col md={12} lg={6}>
              <h5>You can rely on us to make outsourcing work for your business.</h5>
              <p>Remote Philippines is a specialist in skilled remote staff placement and outsourcing solutions. We enable businesses to grow through effective talent-search services delivering expertise and low-cost operations. It is our goal to connect skilled Filipinos to the world's best employers.</p>
              <div className='card-btn'> <Link className='link-btn' to='/request-proposal'>REQUEST A PROPOSAL </Link></div>
            </Col>
            <Col md={12} lg={6}>
              <div className="work-your-business gradient-bar-wrapper">
                <div className="gradient-bar gradient-primary-2"></div>
                {/* <div className="gradient-bar gradient-primary-3"></div>
                <div className="gradient-bar gradient-primary-5"></div>
                <div className="gradient-bar gradient-secondary-2"></div>
                <div className="gradient-bar gradient-secondary-13"></div> */}
                <div className="gradient-bar gradient-primarys-1">
                  <div className="gradient-secondary-5-img">
                    <img  loading="lazy" src={Images.Asset20} className='img-fluid' alt='' />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="finance-and-accounting-services">
        <Container>
          <Row className="finance-and-accounting-services-inner">
            <Col md={12}><div id="cost"></div></Col>
            <Col md={12} lg={6}>
              <div className="people-office">
                {/* <div className="people-office-inner gradient-secondary-5"></div> */}
                <img  loading="lazy" src={Images.Asset18} className='img-fluid' alt='' />
              </div>
            </Col>
            <Col md={12} lg={5} className="offset-md-1">
              <h4>Save up to 70% on staffing costs!</h4>
              <p>For most firms, one of the largest running expenditures is labor. Many firms struggle to strike a balance between the expense of staffing and the potential revenue they may create as a result of hiring more people and needing more infrastructure, such as office space, furniture, and IT equipment.</p>
              <p>Cost reduction is the number one reason why companies outsource their business functions. Outsourcing enables businesses of all sizes to relieve these financial demands. Through outsourcing, you can save up to 70% on workforce costs through Remote Philippines cost-effective staffing solutions.</p>

            </Col>
          </Row>
          <Row className="finance-and-accounting-services-inner col-reverse">
            <Col md={12}><div id="focus"></div></Col>
            <Col md={12} lg={6}>
              <h4>Increase your focus on value-add functions.</h4>
              <p className="col-xl-9">By outsourcing your business operations, you’ll have more time and energy to work on developing your brand, spending money on research and development, and eventually offering greater value services.</p>
              <p className="col-xl-9">Through outsourcing, more and more businesses are able to devote more time and resources to serving the demands of their customers.</p>
            </Col>
            <Col md={12} lg={6}>
              <div className="people-office">
                {/* <div className="people-office-inner gradient-secondary-10"></div> */}
                <img  loading="lazy" src={Images.Asset19} className='img-fluid' alt='' />
              </div>
            </Col>
          </Row>
          <Row className="finance-and-accounting-services-inner">
            <Col md={12}><div id="flexibility"></div></Col>
            <Col md={12} lg={6}>
              <div className="people-office">
                {/* <div className="people-office-inner gradient-primary-1"></div> */}
                <img  loading="lazy" src={Images.Asset16} className='img-fluid' alt='' />
              </div>
            </Col>
            <Col md={12} lg={5} className="offset-md-1">
              <h4>Take advantage of greater flexibility.</h4>
              <p>By outsourcing, you can avoid paying the fixed costs of maintaining an internal workforce, such as wages, insurance, benefits, management fees, office space, and more. The financial flexibility to use less expensive, variable cost models like pay-for-service is made possible by eliminating traditional overhead.</p>
              <p>The main goal of a business should be to have as much flexibility and control as possible so that it can pursue various possibilities as it learns more or as its situation changes.</p>
            </Col>
          </Row>
          <Row className="finance-and-accounting-services-inner col-reverse">
            <Col md={12}><div id="efficiency"></div></Col>
            <Col md={12} lg={6}>
              <h4>Maximize efficiency and productivity.</h4>
              <p className="col-xl-9">In many ways, outsourcing boosts productivity, both directly and indirectly. Simply defined, it enables workers to concentrate on their strengths. Long-term outsourcing also improves worker productivity and job satisfaction.</p>
              <p className="col-xl-9">The methods of outsourcing have been around for a while and can handle any problems that may arise for a company at any time. In fact, it is comparable to unlocking the chores that you can manage and giving the rest to a firm with greater management capabilities to handle.</p>
            </Col>
            <Col md={12} lg={6}>
              <div className="people-office">
                {/* <div className="people-office-inner gradient-secondary-6"></div> */}
                <img  loading="lazy" src={Images.Asset17} className='img-fluid' alt='' />
              </div>
            </Col>
          </Row>
          <Row className="finance-and-accounting-services-inner">
            <Col md={12}><div id="scalability"></div></Col>
            <Col md={12} lg={6}>
              <div className="people-office Asset15">
                {/* <div className="people-office-inner gradient-secondary-11"></div> */}
                <img  loading="lazy" src={Images.Asset15} className='img-fluid' alt='' />
              </div>
            </Col>
            <Col md={12} lg={5} className="offset-md-1">
              <h4>Scale teams up and down as priorities change.</h4>
              <p>The ability to outsource on a "as-needed" basis is one of the biggest benefits of outsourcing. Outsourcing permits companies to outsource only the parts of projects that could be expensive to manage internally. To better serve clients and consumers, add additional staff or services during known high-traffic or peak activity times. </p>
              <p>With a qualified workforce that can handle the increased amount of business, outsourcing gives firms the opportunity to quickly scale up. In a couple of days or weeks, a store or an e-commerce business may add up to 100 call center agents, depending on the call center's size and the demands of the company. Scaling down to a smaller team of agents after the hectic season is gone is similarly simple. Remote Philippines is skilled at offering this interim support on workforce scalability when it is necessary at any given time.</p>
            </Col>
          </Row>
          <Row className="finance-and-accounting-services-inner col-reverse">
            <Col md={12}><div id="time-to-market"></div></Col>
            <Col md={12} lg={6}>
              <h4>Reduce your time-to-market.</h4>
              <p className="col-xl-9">With offshore teams, software companies may continuously improve their products, cutting down on TTM. For instance, once a U.S. software company closes for the day, offshore developers in Asia will continue working on development.</p>
              <p className="col-xl-9">When the Asian crew is sleeping, the American company will concentrate on development. Software firms may perform workflows more quickly and minimize the time to market by many months thanks to this nearly round-the-clock support.</p>
            </Col>
            <Col md={12} lg={6}>
              <div className="people-office">
                {/* <div className="people-office-inner gradient-secondary-6"></div> */}
                <img  loading="lazy" src={Images.Asset14} className='img-fluid' alt='' />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="trusted-global-partner">
        <Container>
          <Row>
            <Col md={12}>
              <div className="trusted-global-partner-box">
                <div className="headings">A trusted global partner in providing premium <br /> talent - <span className="gradient-text">ready to join your team.</span></div>
                <p className="text-center">Here are a few of the cutting-edge tools in which our team members excel. </p>
                {isMobile ? <PartnersSlider /> : <Partners />}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="outsourcing-any-role-section">
        <ServicesSlider />
      </div>
      <div className='get-hire-form'>
        <Row className="g-0">
          <Col>
            <div className='request-contact-form'>
              <div className='bg-right-wrapper'>
                <div className="bg-right"></div>
                <div className="bg-right2"></div>
              </div>
              <Container className='position-relative'>
                <Row>
                  <Col md="12">
                    <div className="form-heading">
                      <h2 className=''>Build amazing teams, hire world-class talent.</h2>
                    </div>
                  </Col>
                  <Col sm={12} md={12} lg={5}>
                    <div className="form-heading">
                      <p className=''>If you’re ready to build your team, grow or have questions about our services, just complete the form and one of our experts will be in touch shortly.</p>
                    </div>
                    <div className="form-image-wrapper">
                      <img  loading="lazy" src={Images.Asset9} alt='' className='img-fluid'></img>
                    </div>
                  </Col>
                  <Col sm={12} md={12} lg={7}>
                    <GetRequestProposalForm />
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </div>
      {isMobile ? <MobileFooter /> : <Footer />}
    </div>
  );
}

export default Hospitality;
