import React, { useRef, useLayoutEffect, useState } from 'react'
import { Accordion, Col, Container, Dropdown, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Images from '../../../constant/images';
import Logo from '../../Logo';
import Hamburger from '../../hamburger';
function MobileHeader() {

    const [visible, setVisible] = useState(false);
    const stickyHeader = useRef()
    const [sticky, setSticky] = useState(false);
    useLayoutEffect(() => {
        const mainHeader = document.getElementById('mainHeader')
        let fixedTop = stickyHeader.current.offsetTop
        const fixedHeader = () => {
            if (window.pageYOffset > fixedTop) {
                mainHeader.classList.add('fixedTop')
                setSticky(true);
            } else {
                mainHeader.classList.remove('fixedTop')
                setSticky(false);
            }
        }
        window.addEventListener('scroll', fixedHeader)
    }, [])
    return (
        <>
            <div className="header">
                <div className='top-header'>
                    <Container>
                        <Row>
                            <Col><p>Learn all that your business can accomplish with Remote Philippines. <Link className="sales-btn" to="/">Connect with Sales</Link> </p></Col>
                        </Row>
                    </Container>
                </div>
                <div className='main-header'>
                    <div className='middle-header'>
                        <Container fluid>
                            <Row>
                                <Col>
                                    <div className='mobile-section-phone-no'>
                                        <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic"> <i className="fa-solid fa-phone"></i><span> +61-3-9111-2490</span> </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="tel:+61391112490"><i className="fa-solid fa-phone"></i>  +61-3-9111-2490</Dropdown.Item>
                                                <Dropdown.Item href="tel:+19294470422"><i className="fa-solid fa-phone"></i> +1-929-447-0422</Dropdown.Item>
                                                <Dropdown.Item href="tel:+19294470422"><i className="fa-solid fa-phone"></i> +44-420-4577-0764</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </Col>
                                {/* <Col>
                                    <div className='mobile-section-phone-no mobile-section-lang'>
                                        <Dropdown className="text-end">
                                            <Dropdown.Toggle id="dropdown-basic"><span> English</span> </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="/">عَرَبِيّ</Dropdown.Item>
                                                <Dropdown.Item href="/">Bahasa Indonesia</Dropdown.Item>
                                                <Dropdown.Item href="/">Deutsch</Dropdown.Item>
                                                <Dropdown.Item href="/">Español</Dropdown.Item>
                                                <Dropdown.Item href="/">Français</Dropdown.Item>
                                                <Dropdown.Item href="/">हिंदी</Dropdown.Item>
                                                <Dropdown.Item href="/">עִברִית</Dropdown.Item>
                                                <Dropdown.Item href="/">Italiano</Dropdown.Item>
                                                <Dropdown.Item href="/">日本語</Dropdown.Item>
                                                <Dropdown.Item href="/">한국어</Dropdown.Item>
                                                <Dropdown.Item href="/">Nederlands</Dropdown.Item>
                                                <Dropdown.Item href="/">Português</Dropdown.Item>
                                                <Dropdown.Item href="/">Polski</Dropdown.Item>
                                                <Dropdown.Item href="/">Svenska</Dropdown.Item>
                                                <Dropdown.Item href="/">ภาษาไทย</Dropdown.Item>
                                                <Dropdown.Item href="/">中文（简）</Dropdown.Item>
                                                <Dropdown.Item href="/">中文（粤）</Dropdown.Item>
                                                <Dropdown.Item href="/">中文（繁）</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </Col> */}
                            </Row>
                        </Container>
                    </div>
                    <div className="mainHeader navbars" id="mainHeader" ref={stickyHeader}>
                        <div className='bottom-header'>
                            <Container fluid className='navbar-inner'>
                                <Row className='align-items-center'>
                                    <Col xs={6} md={4}>
                                        <div className='logo-section'>
                                            <Link to='/'> <Logo sticky={sticky} /></Link>
                                        </div>
                                    </Col>
                                    <Col xs={6} md={8}>
                                        <div>
                                            <div className='hamburger-wrapper-section'>
                                                <button onClick={() => { setVisible(!visible) }}>
                                                    {visible ? <img  loading="lazy" width={'20px'} src={Images.cancel} alt='Cancel' /> : <Hamburger sticky={sticky} />}
                                                </button>
                                            </div>
                                            {visible && <div className='mobile-nav'>
                                                <div className='mobile-nav-inner'>
                                                    <Row className="align-items-center">
                                                        <Col xs={6} md={4}>
                                                            <div className='logo-section'>
                                                                <Link to='/'> <Logo sticky={!sticky} /></Link>
                                                            </div>
                                                        </Col>
                                                        <Col xs={6} md={8}>
                                                            <div className='hamburger-wrapper-section'>
                                                                <button onClick={() => { setVisible(!visible) }}>
                                                                    {visible ? <img  loading="lazy" width={'20px'} src={Images.cancel} alt='Cancel' /> : <Hamburger sticky={!sticky} />}
                                                                </button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <Col md={12}>
                                                    <div className='request-a-proposal-btn mobile-login'><Link className="request-a-proposal book-a-meeting" target='_blank' to="https://calendly.com/remotephilippines/"> Book a meeting </Link></div>
                                                </Col>
                                                <Accordion>
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header>About</Accordion.Header>
                                                        <Accordion.Body>
                                                            <Row>
                                                                <div className="col-12">
                                                                    <ul className="sub-menu-lists">
                                                                        <li><Link to='/our-mission'>Our Mission</Link></li>
                                                                        <li><Link to='/our-people'>Our People</Link></li>
                                                                        <li><Link to='/work-with-us'>Why Work with Us</Link></li>
                                                                        <li><Link to='/our-services'>Our Services</Link></li>
                                                                        <li><Link to='/benefits'>Benefits</Link></li>
                                                                        <li><Link to='/the-philippines'>The Philippines</Link></li>
                                                                        <li><Link to='/engagement-process'>Engagement Process</Link></li>
                                                                        <li><Link to='/community'>Community</Link></li>
                                                                        <li><Link to='/legal?title=terms-of-service'>Legal</Link></li>
                                                                        <li><Link to='/contact-us'>Contact Us</Link></li>
                                                                    </ul>
                                                                </div>
                                                            </Row>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="1">
                                                        <Accordion.Header>Employers</Accordion.Header>
                                                        <Accordion.Body>
                                                            <Row>
                                                                <div className="col-12">
                                                                    <div className="sub-menu-header"><Link to='/'> SECTOR</Link> </div>
                                                                    <ul className="sub-menu-lists">
                                                                        <li><Link to='/information-technology'> Information Technology</Link></li>
                                                                        <li><Link to='/real-estate'> Real Estate</Link></li>
                                                                        <li><Link to='/hospitality'> Hospitality</Link></li>
                                                                        <li><Link to='/legal-services'> Legal Services</Link></li>
                                                                        <li><Link to='/data-management'> Data Management</Link></li>
                                                                        <li><Link to='/telecommunications'> Telecommunications</Link></li>
                                                                        <li><Link to='/healthcare'> Healthcare</Link></li>
                                                                        <li><Link to='/transportation'> Transportation</Link></li>
                                                                        <li><Link to='/utilities-and-energy'> Utilities and Energy </Link></li>
                                                                        <li><Link to='/travel'> Travel</Link></li>
                                                                        <li><Link to='/e-commerce'> e-Commerce</Link></li>
                                                                        <li><Link to='/education'> Education</Link></li>
                                                                        <li><Link to='/engineering'> Engineering </Link></li>
                                                                        <li><Link to='/financial-services'> Financial Services</Link></li>
                                                                        <li><Link to='/logistics'>Logistics </Link></li>
                                                                    </ul>
                                                                    <div className="sub-menu-header"> <Link to='/'>ROLE</Link></div>
                                                                    <ul className="sub-menu-lists">
                                                                        <li><Link to='/finance-and-accounting'>Finance & Accounting </Link></li>
                                                                        <li><Link to='/customer-service'>Customer Service </Link></li>
                                                                        <li><Link to='/lead-gen-and-sales'>Lead Gen & Sales </Link></li>
                                                                        <li><Link to='/digital-marketing'>Digital Marketing </Link></li>
                                                                        <li><Link to='/human-resources'>Human Resources </Link></li>
                                                                        <li><Link to='/design-and-graphics'>Design & Graphics </Link></li>
                                                                        <li><Link to='/software-development'>Software Development </Link></li>
                                                                        <li><Link to='/virtual-assistance'>Virtual Assistance </Link></li>
                                                                        <li><Link to='/engineer'>Engineering </Link></li>
                                                                        <li><Link to='/medical'>Medical </Link></li>
                                                                        <li><Link to='/data-science'>Data Science </Link></li>
                                                                        <li><Link to='/technical-support'>Technical Support </Link></li>
                                                                        <li><Link to='/project-management'>Project Management </Link></li>
                                                                        <li><Link to='/c-executives'>C-Executives </Link></li>
                                                                    </ul>
                                                                    <div className="sub-menu-header"><Link to='/'>COMPANY</Link></div>
                                                                    <ul className="sub-menu-lists">
                                                                        <li><Link to='/startup'>Startups </Link></li>
                                                                        <li><Link to='/small-and-medium-sized-businesses'>Small & Medium Sized Businesses </Link></li>
                                                                        <li><Link to='/enterprise'>Enterprise </Link></li>
                                                                        <li><Link to='/high-growth'>High-Growth </Link></li>
                                                                        <li><Link to='/cost-cutting'>Cost-Cutting </Link></li>
                                                                        <li><Link to='/agency'>Agency </Link></li>
                                                                        <li><Link to='/shared-services'>Shared Services </Link></li>
                                                                        <li><Link to='/technology'>Technology </Link></li>
                                                                        <li><Link to='/bootstrapped'>Bootstrapped </Link></li>
                                                                    </ul>
                                                                </div>
                                                            </Row>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="2">
                                                        <Accordion.Header>Talents</Accordion.Header>
                                                        <Accordion.Body>
                                                            <Row>
                                                                <div className="col-12">
                                                                    <ul className="sub-menu-lists">
                                                                        <li><Link to='/careers'>We’re Hiring </Link></li>
                                                                        <li><Link to='/hiring-process'>Hiring Process </Link></li>
                                                                        {/* <li><Link to='/gallery'>Gallery </Link></li> */}
                                                                    </ul>
                                                                </div>
                                                            </Row>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="3">
                                                        <Accordion.Header>Knowledge</Accordion.Header>
                                                        <Accordion.Body>
                                                            <Row>
                                                                <div className="col-12">
                                                                    <ul className="sub-menu-lists">
                                                                        {/* <li><Link to='/'>Webinars</Link></li>
                                                                        <li><Link to='/'>Templates</Link></li>
                                                                        <li><Link to='/'>Financial Services</Link></li>
                                                                        <li><Link to='/'>E-Course</Link></li>
                                                                        <li><Link to='/'>E-Book</Link></li> */}
                                                                        <li><a href='https://www.remotephilippines.com/blog/'>Blog</a></li>
                                                                        <li><Link to='/faq'>FAQ</Link></li>
                                                                    </ul>
                                                                </div>
                                                            </Row>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    {/* <div className="pricing-link"><Link to='/price-guide'>Price Guide</Link></div> */}
                                                </Accordion>
                                                <Col md={12}>
                                                    <div className='request-a-proposal-btn'><Link className="request-a-proposal" to="/request-proposal"> Request a Proposal</Link></div>
                                                </Col>
                                            </div>}
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default MobileHeader;
