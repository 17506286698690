import Images from "../constant/images";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function PartnersSlider() {

    var settings = {
        dots: false,
        arrows: true,
        rows:2,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 1000,
        responsive: [
            {
              breakpoint: 1200,
              settings: {
                rows:2,
              },
            },
            {
              breakpoint: 1008,
              settings: {
                rows:4,
              },
            },
            {
              breakpoint: 400,
              settings: {
                rows:4,
              },
            },
          ],
    };

    return (
        <div className="mobile-logo-section">
            <Slider {...settings}>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo1} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo2} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo3} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo4} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo5} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo6} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo7} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo8} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo9} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo10} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo11} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo12} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo13} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo14} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo15} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo16} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo17} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo18} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo19} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo20} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo21} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo22} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo23} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo24} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo25} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo26} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo27} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo28} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo29} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo30} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo31} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo32} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo33} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo34} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo35} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo36} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo37} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo38} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo39} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo40} className='img-fluid' alt='' /></div></div>
                <div><div className="logo-image"> <img  loading="lazy" src={Images.logo41} className='img-fluid' alt='' /></div></div>
            </Slider>
        </div>
    );
}

export default PartnersSlider;