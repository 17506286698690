import { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Col, Container } from "react-bootstrap";

function PriceGuideTableMobile() {
    const [costType, setCostType] = useState('AUD');

    var PriceGuideTable = {
        dots: false,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        autoplay: true,
        slidesToScroll: 1,
        speed: 1000,
    };

    return (
        <Container>
            <div className="position-relative pricing-table-mobile-wrapper">
                <Slider {...PriceGuideTable}>
                    <div className="pricing-table-header">
                        <Col md={12} className="px-1">
                            <div className="pricing-table-cell position-sticky">
                                <h3>Junior</h3>
                                <div className="pricing-table-btn">
                                    <button className={costType === "AUD" ? "CostSaving-btn CostSaving-btn-left active" : "CostSaving-btn CostSaving-btn-left"} onClick={() => { setCostType('AUD') }}> AUD</button>
                                    <button className={costType === "USD" ? "CostSaving-btn CostSaving-btn-right active" : "CostSaving-btn CostSaving-btn-right"} onClick={() => { setCostType('USD') }}> USD</button>
                                </div>
                            </div>
                            <div className="pricing-table-row">
                                <h3>Administration Support</h3>
                                <div className="pricing-table-row">
                                    <p>Virtual Assistant</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">8 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">6 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Customer Service</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">8 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">6 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Data Entry Specialist</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">8 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">6 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Recruitment Specialist</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">8 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">6 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Technical Recruiter</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">9 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">7 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Administrative Assistant</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">8 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">6 USD</div>
                                    )}
                                </div>
                            </div>
                            <div className="pricing-table-row">
                                <h3>Information Technology</h3>
                                <div className="pricing-table-row">
                                    <p>Software Developer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">9 USD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">9 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Mobile Developer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">12 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">9 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>DevOps Engineer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">12 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">9 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Full Stack Developer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">12 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">9 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Web Developer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">12 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">9 USD</div>
                                    )}
                                </div>
                            </div>
                            <div className="pricing-table-row">
                                <h3>Sales & Marketing</h3>
                                <div className="pricing-table-row">
                                    <p>Content Writer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">8 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">6 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Lead Gen Specialist</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">8 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">6 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Telemarketer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">9 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">7 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Social Media Manager</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">8 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">6 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Digital Marketing Specialist</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">9 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">7 USD</div>
                                    )}
                                </div>
                            </div>
                            <div className="pricing-table-row">
                                <h3>Finance & Accounting</h3>
                                <div className="pricing-table-row">
                                    <p>Accountant</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">9 AUD </div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">7 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Bookkeeper</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">9 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">7 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Accounts Payable</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">8 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">6 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Account Receivables</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">8 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">6 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Accounts Coordinator</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">8 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">6 USD</div>
                                    )}
                                </div>
                            </div>
                            <div className="pricing-table-row">
                                <h3>Design & Media</h3>
                                <div className="pricing-table-row">
                                    <p>Graphics Designer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">9 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">7 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Web Designer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">9 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">7 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Video Editor</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">9 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">7 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>UI/UX Designer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">12 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">9 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Animator</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">9 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">7 USD</div>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </div>
                    <div className="pricing-table-header">
                        <Col md={12} className="px-1">
                            <div className="pricing-table-cell position-sticky">
                                <h3>Intermediate</h3>
                                <div className="pricing-table-btn">
                                    <button className={costType === "AUD" ? "CostSaving-btn CostSaving-btn-left active" : "CostSaving-btn CostSaving-btn-left"} onClick={() => { setCostType('AUD') }}> AUD</button>
                                    <button className={costType === "USD" ? "CostSaving-btn CostSaving-btn-right active" : "CostSaving-btn CostSaving-btn-right"} onClick={() => { setCostType('USD') }}> USD</button>
                                </div>
                            </div>
                            <div className="pricing-table-row">
                                <h3>Administration Support</h3>
                                <div className="pricing-table-row">
                                    <p>Virtual Assistant</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">11 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">8 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Customer Service</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">11 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">8 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Data Entry Specialist</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">11 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">8 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Recruitment Specialist</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">11 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">8 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Technical Recruiter</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">12AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">9 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Administrative Assistant</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">11 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">8 USD</div>
                                    )}
                                </div>
                            </div>
                            <div className="pricing-table-row">
                                <h3>Information Technology</h3>
                                <div className="pricing-table-row">
                                    <p>Software Developer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">21 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">15 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Mobile Developer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">21 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">15 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>DevOps Engineer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">21 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">15 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Full Stack Developer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">21 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">15 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Web Developer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">21 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">15 USD</div>
                                    )}
                                </div>
                            </div>
                            <div className="pricing-table-row">
                                <h3>Sales & Marketing</h3>
                                <div className="pricing-table-row">
                                    <p>Content Writer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">11 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">8 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Lead Gen Specialist</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">11 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">8 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Telemarketer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">12 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">9 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Social Media Manager</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">11 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">8 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Digital Marketing Specialist</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">12 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">9 USD</div>
                                    )}
                                </div>
                            </div>
                            <div className="pricing-table-row">
                                <h3>Finance & Accounting</h3>
                                <div className="pricing-table-row">
                                    <p>Accountant</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">12 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">9 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Bookkeeper</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">12 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">9 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Accounts Payable</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">11 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">8 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Account Receivables</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">11 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">8 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Accounts Coordinator</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">11 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">8 USD</div>
                                    )}
                                </div>
                            </div>
                            <div className="pricing-table-row">
                                <h3>Design & Media</h3>
                                <div className="pricing-table-row">
                                    <p>Graphics Designer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">12 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">9 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Web Designer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">12 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">9 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Video Editor</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">12 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">9 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>UI/UX Designer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">21 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">15 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Animator</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">12 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">9 USD</div>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </div>

                    <div className="pricing-table-header">
                        <Col md={12} className="px-1">
                            <div className="pricing-table-cell position-sticky">
                                <h3>Expert</h3>
                                <div className="pricing-table-btn">
                                    <button className={costType === "AUD" ? "CostSaving-btn CostSaving-btn-left active" : "CostSaving-btn CostSaving-btn-left"} onClick={() => { setCostType('AUD') }}> AUD</button>
                                    <button className={costType === "USD" ? "CostSaving-btn CostSaving-btn-right active" : "CostSaving-btn CostSaving-btn-right"} onClick={() => { setCostType('USD') }}> USD</button>
                                </div>
                            </div>
                            <div className="pricing-table-row">
                                <h3>Administration Support</h3>
                                <div className="pricing-table-row">
                                    <p>Virtual Assistant</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">14 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">10 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Customer Service</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">14 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">10 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Data Entry Specialist</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">14 AUD </div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">10 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Recruitment Specialist</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">14 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">10 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Technical Recruiter</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">15 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">11 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Administrative Assistant</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">14 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">10 USD</div>
                                    )}
                                </div>
                            </div>
                            <div className="pricing-table-row">
                                <h3>Information Technology</h3>
                                <div className="pricing-table-row">
                                    <p>Software Developer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">28 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">20 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Mobile Developer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">28 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">20 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>DevOps Engineer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">28 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">20 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Full Stack Developer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">28 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">20 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Web Developer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">28 AUD </div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">20 USD</div>
                                    )}
                                </div>
                            </div>
                            <div className="pricing-table-row">
                                <h3>Sales & Marketing</h3>
                                <div className="pricing-table-row">
                                    <p>Content Writer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">14 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">10 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Lead Gen Specialist</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">14 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">10 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Telemarketer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">15 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col"> 11 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Social Media Manager</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">14 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">10 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Digital Marketing Specialist</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">15 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">11 USD</div>
                                    )}
                                </div>
                            </div>
                            <div className="pricing-table-row">
                                <h3>Finance & Accounting</h3>
                                <div className="pricing-table-row">
                                    <p>Accountant</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">15 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">11 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Bookkeeper</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">15 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">11 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Accounts Payable</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">14 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">10 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Account Receivables</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">14 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">10 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Accounts Coordinator</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">14 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">10 USD</div>
                                    )}
                                </div>
                            </div>
                            <div className="pricing-table-row">
                                <h3>Design & Media</h3>
                                <div className="pricing-table-row">
                                    <p>Graphics Designer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">15 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">11 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Web Designer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">15 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">11 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Video Editor</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">15 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">11 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>UI/UX Designer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">28 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">20 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Animator</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">15 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col"> 11 USD</div>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </div>

                    <div className="pricing-table-header">
                        <Col md={12} className="px-1">
                            <div className="pricing-table-cell position-sticky">
                                <h3>Cost Savings</h3>
                                <div className="pricing-table-btn">
                                    <button className={costType === "AUD" ? "CostSaving-btn CostSaving-btn-left active" : "CostSaving-btn CostSaving-btn-left"} onClick={() => { setCostType('AUD') }}> AUD</button>
                                    <button className={costType === "USD" ? "CostSaving-btn CostSaving-btn-right active" : "CostSaving-btn CostSaving-btn-right"} onClick={() => { setCostType('USD') }}> USD</button>
                                </div>
                            </div>
                            <div className="pricing-table-row">
                                <h3>Administration Support</h3>
                                <div className="pricing-table-row">
                                    <p>Virtual Assistant</p>

                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">14.57 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">20.96 USD</div>
                                    )}

                                </div>
                                <div className="pricing-table-row">
                                    <p>Customer Service</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">12.7 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">10.93 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Data Entry Specialist</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">23.9 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">12.09 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Recruitment Specialist</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">25.23 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">15.54 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Technical Recruiter</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">49.96 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">22.52 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Administrative Assistant</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">20.04 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">12.42 USD</div>
                                    )}
                                </div>
                            </div>
                            <div className="pricing-table-row">
                                <h3>Information Technology</h3>
                                <div className="pricing-table-row">
                                    <p>Software Developer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">41.68 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">40.24 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Mobile Developer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">40.84 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">39.03 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>DevOps Engineer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">100 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">77.64 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Full Stack Developer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">49.64 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">42.7 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Web Developer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">36.91 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">24.46 USD</div>
                                    )}
                                </div>
                            </div>
                            <div className="pricing-table-row">
                                <h3>Sales & Marketing</h3>
                                <div className="pricing-table-row">
                                    <p>Content Writer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">22.11 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">13.55 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Lead Gen Specialist</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">33.16 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">11.7 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Telemarketer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">15.94 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">18.87 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Social Media Manager</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">28.5 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">15.39 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Digital Marketing Specialist</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">23.85 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">13.94 USD</div>
                                    )}
                                </div>
                            </div>
                            <div className="pricing-table-row">
                                <h3>Finance & Accounting</h3>
                                <div className="pricing-table-row">
                                    <p>Accountant</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">30.99 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">20.54 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Bookkeeper</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">30.29 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">14.12 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Accounts Payable</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">35.45 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">14.2 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Account Receivables</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">20.62 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">14.4 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Accounts Coordinator</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">45.49 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">21.98 USD</div>
                                    )}
                                </div>
                            </div>
                            <div className="pricing-table-row">
                                <h3>Design & Media</h3>
                                <div className="pricing-table-row">
                                    <p>Graphics Designer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">22.67 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">13.57 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Web Designer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">33.73 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">17.88 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Video Editor</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">24.21 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">14.6 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>UI/UX Designer</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">76.27 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">62.36 USD</div>
                                    )}
                                </div>
                                <div className="pricing-table-row">
                                    <p>Animator</p>
                                    {costType === 'AUD' && (
                                        <div className="pricing-table-cell-col">34.32 AUD</div>
                                    )}
                                    {costType === 'USD' && (
                                        <div className="pricing-table-cell-col">14.38 USD</div>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </div>
                </Slider>
            </div>
        </Container >
    );
}

export default PriceGuideTableMobile;