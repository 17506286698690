import { useLocation } from "react-router-dom";
import Images from "../constant/images";
const Hamburger = ({ sticky }) => {
    const location = useLocation();
    switch (location.pathname) {
        case '/benefits':
        case '/agency':
        case '/enterprise':
        case '/cost-cutting':
        case '/bootstrapped':
        case '/high-growth':
        case '/shared-services':
        case '/small-and-medium-sized-businesses':
        case '/startup':
        case '/technology':
        case '/our-people':
        case '/work-with-us':
        case '/the-philippines':
        case '/community':
        case '/engagement-process':
        case '/faq':
            return (
                <>
                    {sticky ? <img  loading="lazy" width={'20px'} src={Images.Hamburger} alt='Cancel' /> : <img  loading="lazy" src={Images.Hamburger} alt='Hamburger' />}
                </>
            )

        default:
            return (
                <>
                    {sticky ? <img  loading="lazy" width={'20px'} src={Images.Hamburger} alt='Cancel' /> : <img  loading="lazy" src={Images.Hamburger2} alt='Hamburger' />}
                </>
            )
    }
}

export default Hamburger;