import React, { useEffect, useState } from 'react';
import Header from "../include/header";
import Footer from "../include/footer";
import { Col, Container, Row } from 'react-bootstrap';
import Images from '../../../constant/images';
import CommonMeta from '../include/CommonMeta';
import MobileHeader from '../include/mobileHeader';
import { isDesktop, isMobile, isTablet } from 'react-device-detect';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MobileFooter from '../include/mobileFooter';
import GetRequestProposalForm from '../../getRequestProposalForm';

function Careers() {
  
  const YOUTUBE_PLAYLIST_ITEMS_API = 'https://www.googleapis.com/youtube/v3/playlistItems';
  const [youTubedata, setyouTubedata] = useState([]);
  const getYoutubePlayList = async () => {
    const res = await fetch(`${YOUTUBE_PLAYLIST_ITEMS_API}?part=snippet&playlistId=PLeTyYUgffKJDYYnDmfuvK2JRIMa3phAW3&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`);
    const data = await res.json();
    setyouTubedata(data.items)
  }

  useEffect(() => {
    getYoutubePlayList();
  }, [])



  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    autoplay: false,
    slidesToScroll: 1,
    speed: 1000,
    centerMode: true,
    centerPadding: '150px',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScrol1: 1,
        },
      },
      {
        breakpoint: 1008,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: '0px',
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: '0px',
        },
      },
    ],
  };

  return (
    <div>
      <CommonMeta
        title='Careers'
        description='Remote Philippines Dashboard | Careers'
        keywords='Remote Philippines Dashboard | Careers'
        name='Remote Philippines'
        type='article' />
      {isDesktop || isTablet ? <Header /> : <MobileHeader />}
      <div className='home-page global-banner-2 bg-sea-1'>
        <>
          <Row className='g-0'>
            <Col sm={12} md={12} lg={5}>
              <div className="request-a-proposal-header-text careers">
                <h1 className="">Work With Us</h1>
                <p className="">Join us in our mission to provide equal employment opportunities for all Filipinos. There is an enormous number of amazing talents in the Philippines from Luzon, Visayas to Mindanao who have the right skills and qualifications to be matched for global remote work opportunities. Businesses and smart Entrepreneurs have realized the advantages of hiring Filipino professionals to work on critical projects in various industries. Remote Philippines is founded to empower our generation of workers to attain skill development and be brought  closer to an endless world of opportunities.</p>
                <div className='our-solution-details-btn'> <a className='link-btn' target='_blank' href='https://docs.google.com/forms/d/e/1FAIpQLSdsQCLNBYHXTm6QAnrQg7byRJK2FIGXO3ET-IN5tQEuNXTlVw/viewform?fbclid=IwAR3iZPYYPTNeiFpNf1SQuAUC1-05L25BLZXPpGxslMz5xkgB4QFNnP5wWZw'>APPLY NOW</a></div>
              </div>
            </Col>
            <Col sm={12} md={12} lg={7}>
            <div className='home_page_top_wrapper careers_page'>
                {/* <div className="home-wrapp global-banner-2__gradient-overlay careers-page-overlay d-flex justify-content-end">
                  
                </div> */}
                <div className="global-banner-2__gradient-overlay d-flex justify-content-end">
                  {/* <div className="gradient-bar gradient-primary-2 d-lg-block d-none"></div>
                  <div className="gradient-bar gradient-primary-3 d-lg-block d-none" >
                    <div className='home-gradient-bar-image'>
                      <img  loading="lazy" src={Images.bg_Banner1} alt='' />
                    </div>
                  </div> */}
                   {/* <div className="gradient-bar gradient-primary-2 d-lg-block d-none" ></div> */}
                  {/* <div className="gradient-bar gradient-primary-5 d-lg-block d-none" ></div> */}
                  <div className="gradient-bar d-lg-block d-none careers_page_img1" >
                    <div className='career-gradient-bar-image'>
                      <img  loading="lazy" src={Images.ManFace} alt='' className='img-fluid'/>
                    </div>
                  </div>
                   {/* <div className="gradient-bar gradient-primarys-1 d-lg-block d-none" ></div> */}
                 {/* <div className="gradient-bar gradient-secondary-12 d-lg-block d-none" ></div> */}
                  <div className="gradient-bar d-lg-block d-none careers_page_img2" >
                    <div className='career-gradient-bar-image'>
                      <img  loading="lazy" src={Images.WomanFace} alt='' className='img-fluid' />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      </div>
      <div className='our-culture-section'>
        <Container>
          <Row className=''>
            <Col sm={12} md={12} lg={6}>
              <div className="our-culture-section-text">
                <h1 className="">Our culture</h1>
                <p className="">We are one team who work together towards challenging and ambitious goals at the same time delivering the best for our clients.</p>
                <p className="">Remote Philippines is helping businesses find premium Filipino talents to supercharge their business on a global level. Together, we develop and achieve success.</p>
                <h4>We seek people who share these values:</h4>
              </div>
              {/* <div className='our-culture-card-wrapper'>
                <div className='culture-card-box'>
                  <div className='culture-card-box-inner'>
                    <div className='icon'>
                      <img  loading="lazy" src={Images.CustomerFocused} alt='' className='img-fluid' />
                    </div>
                    <div className='card-details'>
                      <h5>Customer-Focused</h5>
                      <p>Our pride and success come from our customer’s success. We work diligently to consistently produce positive outcomes at all times.</p>
                    </div>
                  </div>
                </div>
                <div className='culture-card-box'>
                  <div className='culture-card-box-inner'>
                    <div className='icon'>
                      <img  loading="lazy" src={Images.GrowthCentric} alt='' className='img-fluid' />
                    </div>
                    <div className='card-details'>
                      <h5>Growth-centric</h5>
                      <p>We always aim for growth. A challenge and a new target excite us, and we prioritize continuous learning, improvement, and innovation. We celebrate failures — as efforts — as well as wins.</p>
                    </div>
                  </div>
                </div>
                <div className='culture-card-box'>
                  <div className='culture-card-box-inner'>
                    <div className='icon'>
                      <img  loading="lazy" src={Images.OneTeam} alt='' className='img-fluid' />
                    </div>
                    <div className='card-details'>
                      <h5>One-Team</h5>
                      <p>We work as a single, cohesive group to accomplish a common objective. We strive to strengthen one another and treat one another with respect and trust.</p>
                    </div>
                  </div>
                </div>
              </div> */}
            </Col>
            <Col sm={12} md={12} lg={6}>
              {/* <div className='our-culture-page_wrapper'>
                <div className="global-banner-2__gradient-overlay d-flex justify-content-end">
                  <div className="gradient-bar gradient-primary-2 d-lg-block d-none"></div>
                  <div className="gradient-bar gradient-primary-1 d-lg-block d-none"></div>
                  <div className="gradient-bar gradient-primary-3 d-lg-block d-none" >
                    <div className='culture-gradient-bar-image'>
                      <img  loading="lazy" src={Images.bg_Culture} alt='' />
                    </div>
                  </div>
                  <div className="gradient-bar gradient-secondary-2 d-lg-block d-none" ></div>
                  <div className="gradient-bar gradient-secondary-5 d-lg-block d-none" ></div>
                </div>
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>

<div className='our-culture-card-wrapper-section'>
  <Container>
    <Row>
    <Col sm={12} md={12} lg={6}>
      <div className='our-culture-card-wrapper'>
                <div className='culture-card-box'>
                  <div className='culture-card-box-inner'>
                    <div className='icon'>
                      <img  loading="lazy" src={Images.CustomerFocused} alt='' className='img-fluid' />
                    </div>
                    <div className='card-details'>
                      <h5>Customer-Focused</h5>
                      <p>Our pride and success come from our customer’s success. We work diligently to consistently produce positive outcomes at all times.</p>
                    </div>
                  </div>
                </div>
                <div className='culture-card-box'>
                  <div className='culture-card-box-inner'>
                    <div className='icon'>
                      <img  loading="lazy" src={Images.GrowthCentric} alt='' className='img-fluid' />
                    </div>
                    <div className='card-details'>
                      <h5>Growth-centric</h5>
                      <p>We always aim for growth. A challenge and a new target excite us, and we prioritize continuous learning, improvement, and innovation. We celebrate failures — as efforts — as well as wins.</p>
                    </div>
                  </div>
                </div>
                <div className='culture-card-box'>
                  <div className='culture-card-box-inner'>
                    <div className='icon'>
                      <img  loading="lazy" src={Images.OneTeam} alt='' className='img-fluid' />
                    </div>
                    <div className='card-details'>
                      <h5>One-Team</h5>
                      <p>We work as a single, cohesive group to accomplish a common objective. We strive to strengthen one another and treat one another with respect and trust.</p>
                    </div>
                  </div>
                </div>
              </div>
      </Col>
    </Row>
  </Container>
</div>
      <div className="outsourcing-any-role-section youtube-video hear-our-team">
        <Container>
          <Row>
            <Col md={12}>
              <div className="global-banner-2__gradient-overlay d-flex justify-content-end">
                <div className="gradient-bar gradient-primary-2 d-lg-block" ></div>
                <div className="gradient-bar gradient-primarys-1 d-lg-block"></div>
              </div>
            </Col>
            <Col md={12}>
              <div className="customer-slider-section outsourcing-any-role-slider">
                <div className='slider-heading'>
                  <h4>Hear from our team.</h4>
                  <h2>Join the future of work and experience the ultimate work-life balance by applying at Remote Philippines.</h2>
                </div>
                <Slider {...settings}>

                  {
                    youTubedata.map((item, i) => {
                      const { id, snippet = {} } = item;
                      const { title, thumbnails = {}, resourceId } = snippet;
                      const { standard = {} } = thumbnails;
                      return (
                        <div key={i}>
                          <div className="customer-box">
                            <div className="customer-image yuotube-list">
                              <iframe width={"100%"} height={"440px"} src={`https://www.youtube.com/embed/${resourceId.videoId}`} title={title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                            </div>
                          </div>
                        </div>
                      );
                    })
                  }

                  {/* <div>
                    <div className="customer-box">
                      <div className="customer-image">
                      
                        <iframe width={"100%"} height={"440px"} src="https://www.youtube.com/embed/SeX1oTCv6uk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="customer-box">
                      <div className="customer-image">
                        <iframe width={"100%"} height={"440px"} src="https://www.youtube.com/embed/uiBZKiujHpg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="customer-box">
                      <div className="customer-image">
                        <iframe width={"100%"} height={"440px"} src="https://www.youtube.com/embed/zD2SyPREWKE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                      </div>
                    </div>
                  </div> */}
                </Slider>
              </div>
            </Col>
          </Row>
        </Container>
      </div>


      {/* <div className='our-team-wrapper'>
        <Container>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <h4>Hear from <span className='text'>our team.</span></h4>
            </Col>
            <Col sm={12} md={12} lg={4}>
              <div className='our-team-card-box card-magenta'>
                <div className='card-box-header'>
                  <div className='team-image'>
                    <img  loading="lazy" src={Images.valentina} alt='' className='img-fluid' />
                  </div>
                  <div className='emp-details'>
                    <h5>Valentina Pedroso</h5>
                    <h6>Strategic HT Specialist</h6>
                  </div>
                </div>
                <p>Valentina manages all HR operations for customers in Spain and Portugal. She makes sure to innovate and create processes to help the HR team to work through their strengths and deliver an excellent service.</p>
              </div>
            </Col>
            <Col sm={12} md={12} lg={4}>
              <div className='our-team-card-box card-purple'>
                <div className='card-box-header'>
                  <div className='team-image'>
                    <img  loading="lazy" src={Images.valentina} alt='' className='img-fluid' />
                  </div>
                  <div className='emp-details'>
                    <h5>Josh Kim</h5>
                    <h6>Partner Manager</h6>
                  </div>
                </div>
                <p>osh’s role is focused on partner engagement. This involves driving business revenue through established relationships with current partners, as well as developing new partnerships for the company.</p>
              </div>
            </Col>
            <Col sm={12} md={12} lg={4}>
              <div className='our-team-card-box card-orange'>
                <div className='card-box-header'>
                  <div className='team-image'>
                    <img  loading="lazy" src={Images.valentina} alt='' className='img-fluid' />
                  </div>
                  <div className='emp-details'>
                    <h5>Grace Keane</h5>
                    <h6>Associate Software Engineer</h6>
                  </div>
                </div>
                <p>Grace develops both front- and back-end features, takes part in team meetings, plans projects, and gives demos on features she has implemented. This ensures that tasks are finished in a timely manner and that they adhere to the best programming practices.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}
      <div className='join-our-team'>
        <Row className='g-0'>
          <div className='general-join-gallery'>
            <div className='gallery-item'>
              <img  loading="lazy" src={Images.collage} alt='' className='img-fluid' />
            </div>
            <div className='gallery-item'>
              <img  loading="lazy" src={Images.Office} alt='' className='img-fluid' />
            </div>
            <div className='gallery-item'>
              <img  loading="lazy" src={Images.Profile_with_Gradient_Crop} alt='' className='img-fluid' />
            </div>
            <div className='gallery-item'>
              <img  loading="lazy" src={Images.Work_freedom} alt='' className='img-fluid' />
            </div>
            <div className='gallery-item'>
              <img  loading="lazy" src={Images.Ms_Jai} alt='' className='img-fluid' />
            </div>
            <div className='gallery-item'>
              <img  loading="lazy" src={Images.Ms_Grace_and_client} alt='' className='img-fluid' />
            </div>
            <div className='gallery-item px-5 pt-lg-0 px-xl-7 px-xxl-8 d-flex'>
              <div className='gallery-content my-5 mx-auto align-self-center overflow-hidden text-center text-sm-start'>
                <h3>Join our</h3>
                <img  loading="lazy" src={Images.teamWord} alt='' className='img-fluid' />
                <p>The key to our success depends on our people. We empower a work-from-home lifestyle that fully engages a remote workforce, promotes trust, teamwork, and professional advancement to realize their potential, wherever they are, while honoring personal and professional boundaries.</p>
                <div className='mt-4'><a className='link-btn' target='_blank' href='https://docs.google.com/forms/d/e/1FAIpQLSdsQCLNBYHXTm6QAnrQg7byRJK2FIGXO3ET-IN5tQEuNXTlVw/viewform?fbclid=IwAR3iZPYYPTNeiFpNf1SQuAUC1-05L25BLZXPpGxslMz5xkgB4QFNnP5wWZw'>APPLY NOW</a></div>
              </div>
            </div>
            <div className='gallery-item'>
              <img  loading="lazy" src={Images.Profile_Gradient_Crop} alt='' className='img-fluid' />
            </div>
            <div className='gallery-item'>
              <img  loading="lazy" src={Images.Profile_with_Gradient} alt='' className='img-fluid' />
            </div>
            <div className='gallery-item'>
              <img  loading="lazy" src={Images.Ms_Debbie} alt='' className='img-fluid' />
            </div>
            <div className='gallery-item'>
              <img  loading="lazy" src={Images.Aica} alt='' className='img-fluid' />
            </div>
          </div>
        </Row>
      </div>
      <div className='join-remote-philippines'>
        <Container>
          <Row>
            <Col sm={12} md={12} lg={10}>
              <h2 className='heading'>Why join Remote Philippines?</h2>
              <p>We are creative, collaborative and innovative individuals who aspire to improve and generate an impact on our customers and their business through our offshore staffing solutions. </p>
              <p>Supporting the requirements of our employees comes first. We pay close attention to our employee’s feedback and work hard to maintain our consistency and position ourselves competitively in the workforce marketplace.</p>
              <p>A healthy workplace is important. A positive workplace environment promotes employee relationships, enhances personal wellbeing, and ultimately boosts productivity.</p>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={3}>
              <div className='join-remote-philippines-inner-box'>
                <div className='join-remote-card-box'>
                  <img  loading="lazy" width={'83px'} src={Images.WorkLifeBalance} alt='' className='img-fluid' />
                  <h4 className='mt-3'>Work-Life Balance</h4>
                </div>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className='join-remote-philippines-inner-box'>
                <div className='join-remote-card-box'>
                  <img  loading="lazy" width={'83px'} src={Images.CareerDevelopment} alt='' className='img-fluid' />
                  <h4 className='mt-3'>Career Development</h4>
                </div>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className='join-remote-philippines-inner-box'>
                <div className='join-remote-card-box'>
                  <img  loading="lazy" width={'83px'} src={Images.FinancialGrowth} alt='' className='img-fluid' />
                  <h4 className='mt-3'>Financial Growth</h4>
                </div>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className='join-remote-philippines-inner-box'>
                <div className='join-remote-card-box'>
                  <img  loading="lazy" width={'83px'} src={Images.SupportSystem} alt='' className='img-fluid' />
                  <h4 className='mt-3'>Support System</h4>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <h3 className='headings'>Other Reasons to Join Remote Philippines:</h3>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className='join-remote-philippines-box'>
                <div className='icon'>
                  <img  loading="lazy" width={'83px'} src={Images.RemoteFirstCulture} alt='' className='img-fluid' />
                </div>
                <div className='philippines-box-detail'>
                  <h4>Remote-First Culture</h4>
                  <p>Allows for more flexibility, autonomy and inclusivity whilst generating higher job satisfaction and productivity supported by a nurturing environment. </p>
                </div>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className='join-remote-philippines-box join-remote-philippines-box2'>
                <div className='icon'>
                  <img  loading="lazy" width={'83px'} src={Images.PositiveCulture} alt='' className='img-fluid' />
                </div>
                <div className='philippines-box-detail'>
                  <h4>Positive Culture</h4>
                  <p>Fosters teamwork, productivity, and satisfaction among our employees and reinforces trust in the company who always has the employee’s best interest at heart.</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='hiring-process'>
        <Container>
          <Row>
            <Col md={12}>
              <h3>Our hiring process</h3>
              <p>Ready to apply to become part of our dream team? Here are the steps to follow.</p>
            </Col>
            <Col sm={12} md={12} lg={4}>
              <div className='hiring-process-box step1 mx-auto'>
                <div className='step-icon text-center'>
                  <img  loading="lazy" src={Images.step1} alt='' className='img-fluid' />
                </div>
                <p>Choose the position you're interested in from our list of job openings, and if the job description seems like your ideal role, click "Apply Now" to send your contact information, a cover letter, and a resume.</p>
              </div>
            </Col>
            <Col sm={12} md={12} lg={4}>
              <div className='hiring-process-box step2 mx-auto'>
                <div className='step-icon text-center'>
                  <img  loading="lazy" src={Images.step2} alt='' className='img-fluid' />
                </div>
                <p>A member from our talent acquisition team will invite you to an initial screening if your experience matches the requirements of the position. We appreciate it when candidates ask questions during these discussions.</p>
              </div>
            </Col>
            <Col sm={12} md={12} lg={4}>
              <div className='hiring-process-box step3 mx-auto'>
                <div className='step-icon text-center'>
                  <img  loading="lazy" src={Images.step3} alt='' className='img-fluid' />
                </div>
                <p>Our interviews involve a two-way dialogue. We want you to get to know us as well as enquire about your background, passions, and what motivates you to perform your best at work.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <div className='bgSmile'>
        <Row className='g-0'>
          <Col>
            <img  loading="lazy" src={Images.bgSmile} alt='' className='img-fluid' />
          </Col>
        </Row>
      </div> */}
      <div className='get-started-form'>
        <Container>
          <Row>
            <Col className="col-12 col-lg-10 offset-lg-1">
              <div className='request-contact-form'>
                <h2 className='text-center'>Look no further. Get started with your outsourcing journey today!</h2>
                <p className='text-center'>Book a free session with one of Remote Philippines’s expert sales consultants. We'll get in touch with you to arrange a call, respond to any enquiries you might have, and begin onboarding your staff.</p>
                <GetRequestProposalForm />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {isMobile ? <MobileFooter /> : <Footer />}
    </div>
  );
}

export default Careers;
