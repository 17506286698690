import { useState } from "react";
import { Col, Container } from "react-bootstrap";

function PriceGuideTable() {
  const [costType, setCostType] = useState('AUD');

  return (
    <Container>
      <div className="position-relative">
        <div className="pricing-table-header position-sticky">
          <div className="d-flex">
            <Col md={4}>

            </Col>
            <Col className="px-1">
              <div className="pricing-table-cell">
                <h3>Junior</h3>
              </div>
            </Col>
            <Col className="px-1">
              <div className="pricing-table-cell">
                <h3>Intermediate</h3>
              </div>
            </Col>
            <Col className="px-1">
              <div className="pricing-table-cell">
                <h3>Expert</h3>
              </div>
            </Col>
            <Col className="px-1">
              <div className="pricing-table-cell">
                <h3>Cost Savings</h3>
              </div>
            </Col>
          </div>

        </div>
        <Col md={12}>
          <div className="pricing-table-row d-flex">
            <Col md={4}>
              <div className="pricing-table-btn">
                <button className={costType === "AUD" ? "CostSaving-btn CostSaving-btn-left active" : "CostSaving-btn CostSaving-btn-left"} onClick={() => { setCostType('AUD') }}> AUD</button>
                <button className={costType === "USD" ? "CostSaving-btn CostSaving-btn-right active" : "CostSaving-btn CostSaving-btn-right"} onClick={() => { setCostType('USD') }}> USD</button>
              </div>
              <h3>Administration Support</h3>
            </Col>
            <Col className="px-1">
              <div className="pricing-table-cell-col"></div>
            </Col>
            <Col className="px-1">
              <div className="pricing-table-cell-col"></div>
            </Col>
            <Col className="px-1">
              <div className="pricing-table-cell-col"></div>
            </Col>
            <Col className="px-1">
              <div className="pricing-table-cell-col"></div>
            </Col>
          </div>
          <div className="pricing-table-row d-flex">
            <Col md={4}>
              <p>Virtual Assistant</p>
            </Col>
            {costType === 'AUD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">8 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">11 AUD </div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">14 AUD </div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">14.57 AUD</div>
                </Col>
              </>
            )}

            {costType === 'USD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">6 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">8 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col"> 10 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">20.96 USD</div>
                </Col>
              </>
            )}

          </div>
          <div className="pricing-table-row d-flex">
            <Col md={4}>
              <p>Customer Service</p>
            </Col>
            {costType === 'AUD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">8 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">11 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">14 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">12.7 AUD</div>
                </Col>
              </>
            )}
            {costType === 'USD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">6 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">8 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col"> 10 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">10.93 USD</div>
                </Col>
              </>
            )}

          </div>
          <div className="pricing-table-row d-flex">
            <Col md={4}>
              <p>Data Entry Specialist</p>
            </Col>

            {costType === 'AUD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">8 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">11 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">14 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">23.9 AUD</div>
                </Col>
              </>
            )}
            {costType === 'USD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">6 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">8 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">10 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">12.09 USD</div>
                </Col>
              </>
            )}

          </div>
          <div className="pricing-table-row d-flex">
            <Col md={4}>
              <p>Recruitment Specialist</p>
            </Col>

            {costType === 'AUD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">8 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">11 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">14 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">25.23 AUD</div>
                </Col>
              </>
            )}
            {costType === 'USD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">6 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">8 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">10 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">15.54 USD</div>
                </Col>
              </>
            )}

          </div>
          <div className="pricing-table-row d-flex">
            <Col md={4}>
              <p>Technical Recruiter</p>
            </Col>

            {costType === 'AUD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">9 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">12 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">15 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">49.96 AUD</div>
                </Col>
              </>
            )}
            {costType === 'USD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">7 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">9 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">11 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">22.52 USD</div>
                </Col>
              </>
            )}

          </div>
          <div className="pricing-table-row d-flex">
            <Col md={4}>
              <p>Administrative Assistant</p>
            </Col>

            {costType === 'AUD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">8 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">11 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">14 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">20.04 AUD</div>
                </Col>
              </>
            )}
            {costType === 'USD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">6 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">8 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">10 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">12.42 USD</div>
                </Col>
              </>
            )}

          </div>
        </Col>
        <Col md={12}>
          <div className="pricing-table-row d-flex">
            <Col md={4}>
              <h3 className="mt-4">Information Technology</h3>
            </Col>
            <Col className="px-1">
              <div className="pricing-table-cell-col"></div>
            </Col>
            <Col className="px-1">
              <div className="pricing-table-cell-col"></div>
            </Col>
            <Col className="px-1">
              <div className="pricing-table-cell-col"></div>
            </Col>
            <Col className="px-1">
              <div className="pricing-table-cell-col"></div>
            </Col>
          </div>
          <div className="pricing-table-row d-flex">
            <Col md={4}>
              <p>Software Developer</p>
            </Col>

            {costType === 'AUD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">12 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">21 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">28 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">41.68 AUD</div>
                </Col>
              </>
            )}
            {costType === 'USD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">9 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">15 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">20 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">40.24 USD</div>
                </Col>
              </>
            )}

          </div>
          <div className="pricing-table-row d-flex">
            <Col md={4}>
              <p>Mobile Developer</p>
            </Col>
            {costType === 'AUD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">12 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">21 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">28 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">40.84 AUD</div>
                </Col>
              </>
            )}
            {costType === 'USD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">9 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">15 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">20 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">39.03 USD</div>
                </Col>
              </>
            )}

          </div>
          <div className="pricing-table-row d-flex">
            <Col md={4}>
              <p>DevOps Engineer</p>
            </Col>
            {costType === 'AUD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">12 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">21 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">28 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">100 AUD</div>
                </Col>
              </>
            )}
            {costType === 'USD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">9 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">15 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">20 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">77.64 USD</div>
                </Col>
              </>
            )}

          </div>
          <div className="pricing-table-row d-flex">
            <Col md={4}>
              <p>Full Stack Developer</p>
            </Col>
            {costType === 'AUD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">12 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">21 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">28 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">49.64 AUD</div>
                </Col>
              </>
            )}
            {costType === 'USD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">9 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">15 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">20 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">42.7 USD</div>
                </Col>
              </>
            )}

          </div>
          <div className="pricing-table-row d-flex">
            <Col md={4}>
              <p>Web Developer</p>
            </Col>

            {costType === 'AUD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">12 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">21 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">28 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">36.91 AUD</div>
                </Col>
              </>
            )}
            {costType === 'USD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">9 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">15 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">20 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">24.46 USD</div>
                </Col>
              </>
            )}

          </div>
        </Col>
        <Col md={12}>
          <div className="pricing-table-row d-flex">
            <Col md={4}>
              <h3 className="mt-4">Sales & Marketing</h3>
            </Col>
            <Col className="px-1">
              <div className="pricing-table-cell-col"></div>
            </Col>
            <Col className="px-1">
              <div className="pricing-table-cell-col"></div>
            </Col>
            <Col className="px-1">
              <div className="pricing-table-cell-col"></div>
            </Col>
            <Col className="px-1">
              <div className="pricing-table-cell-col"></div>
            </Col>
          </div>
          <div className="pricing-table-row d-flex">
            <Col md={4}>
              <p>Content Writer</p>
            </Col>

            {costType === 'AUD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">8 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">11 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">14 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">22.11 AUD</div>
                </Col>
              </>
            )}
            {costType === 'USD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">6 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">8 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">10 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">13.55 USD</div>
                </Col>
              </>
            )}

          </div>
          <div className="pricing-table-row d-flex">
            <Col md={4}>
              <p>Lead Gen Specialist</p>
            </Col>

            {costType === 'AUD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">8 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">11 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">14 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">33.16 AUD</div>
                </Col>
              </>
            )}
            {costType === 'USD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">6 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">8 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">10 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">11.7 USD</div>
                </Col>
              </>
            )}

          </div>
          <div className="pricing-table-row d-flex">
            <Col md={4}>
              <p>Telemarketer</p>
            </Col>

            {costType === 'AUD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">9 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">12 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">15 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">15.94 AUD</div>
                </Col>
              </>
            )}
            {costType === 'USD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">7 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">9 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">11 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">18.87 USD</div>
                </Col>
              </>
            )}

          </div>
          <div className="pricing-table-row d-flex">
            <Col md={4}>
              <p>Social Media Manager</p>
            </Col>
            {costType === 'AUD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">8 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">11 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">14 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">28.5 AUD</div>
                </Col>
              </>
            )}
            {costType === 'USD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">6 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">8 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">10 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">15.39 USD</div>
                </Col>
              </>
            )}

          </div>
          <div className="pricing-table-row d-flex">
            <Col md={4}>
              <p>Digital Marketing Specialist</p>
            </Col>

            {costType === 'AUD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">9 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">12 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">15 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">23.85 AUD</div>
                </Col>
              </>
            )}
            {costType === 'USD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">7 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">9 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">11 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">13.94 USD</div>
                </Col>
              </>
            )}
          </div>
        </Col>
        <Col md={12}>
          <div className="pricing-table-row d-flex">
            <Col md={4}>
              <h3 className="mt-4">Finance & Accounting</h3>
            </Col>
            <Col className="px-1">
              <div className="pricing-table-cell-col"></div>
            </Col>
            <Col className="px-1">
              <div className="pricing-table-cell-col"></div>
            </Col>
            <Col className="px-1">
              <div className="pricing-table-cell-col"></div>
            </Col>
            <Col className="px-1">
              <div className="pricing-table-cell-col"></div>
            </Col>
          </div>
          <div className="pricing-table-row d-flex">
            <Col md={4}>
              <p>Accountant</p>
            </Col>
            {costType === 'AUD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">9 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">12 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">15 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">30.99 AUD</div>
                </Col>
              </>
            )}
            {costType === 'USD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">7 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">9 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">11 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">20.54 USD</div>
                </Col>
              </>
            )}

          </div>
          <div className="pricing-table-row d-flex">
            <Col md={4}>
              <p>Bookkeeper</p>
            </Col>

            {costType === 'AUD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">9 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">12 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">15 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">30.29 AUD</div>
                </Col>
              </>
            )}
            {costType === 'USD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">7 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">9 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">11 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">14.12 USD</div>
                </Col>
              </>
            )}

          </div>
          <div className="pricing-table-row d-flex">
            <Col md={4}>
              <p>Accounts Payable</p>
            </Col>

            {costType === 'AUD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">8 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">11 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">14 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">35.45 AUD</div>
                </Col>
              </>
            )}
            {costType === 'USD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">6 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">8 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">10 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">14.2 USD</div>
                </Col>
              </>
            )}

          </div>
          <div className="pricing-table-row d-flex">
            <Col md={4}>
              <p>Account Receivables</p>
            </Col>
            {costType === 'AUD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">8 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">11 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">14 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">20.62 AUD</div>
                </Col>
              </>
            )}
            {costType === 'USD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">6 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">8 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">10 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">14.4 USD</div>
                </Col>
              </>
            )}

          </div>
          <div className="pricing-table-row d-flex">
            <Col md={4}>
              <p>Accounts Coordinator</p>
            </Col>

            {costType === 'AUD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">8 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">11 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">14 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">45.49 AUD</div>
                </Col>
              </>
            )}
            {costType === 'USD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">6 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">8 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">10 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">21.98 USD</div>
                </Col>
              </>
            )}

          </div>
        </Col>
        <Col md={12}>
          <div className="pricing-table-row d-flex">
            <Col md={4}>
              <h3 className="mt-4">Design & Media</h3>
            </Col>
            <Col className="px-1">
              <div className="pricing-table-cell-col"></div>
            </Col>
            <Col className="px-1">
              <div className="pricing-table-cell-col"></div>
            </Col>
            <Col className="px-1">
              <div className="pricing-table-cell-col"></div>
            </Col>
            <Col className="px-1">
              <div className="pricing-table-cell-col"></div>
            </Col>
          </div>
          <div className="pricing-table-row d-flex">
            <Col md={4}>
              <p>Graphics Designer</p>
            </Col>

            {costType === 'AUD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">9 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">12 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">15 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">22.67 AUD</div>
                </Col>
              </>
            )}
            {costType === 'USD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">7 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">9 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col"> 11 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">13.57 USD</div>
                </Col>
              </>
            )}

          </div>
          <div className="pricing-table-row d-flex">
            <Col md={4}>
              <p>Web Designer</p>
            </Col>

            {costType === 'AUD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">9 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">12 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">15 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">33.73 AUD</div>
                </Col>
              </>
            )}
            {costType === 'USD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">7 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">9 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">11 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">17.88 USD</div>
                </Col>
              </>
            )}

          </div>
          <div className="pricing-table-row d-flex">
            <Col md={4}>
              <p>Video Editor</p>
            </Col>

            {costType === 'AUD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">9 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">12 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">15 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">24.21 AUD</div>
                </Col>
              </>
            )}
            {costType === 'USD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">7 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">9 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">11 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">14.6 USD</div>
                </Col>
              </>
            )}

          </div>
          <div className="pricing-table-row d-flex">
            <Col md={4}>
              <p>UI/UX Designer</p>
            </Col>

            {costType === 'AUD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">12 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">21 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">28 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">76.27 AUD</div>
                </Col>
              </>
            )}
            {costType === 'USD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">9 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">15 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">20 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">62.36 USD</div>
                </Col>
              </>
            )}

          </div>
          <div className="pricing-table-row d-flex">
            <Col md={4}>
              <p>Animator</p>
            </Col>

            {costType === 'AUD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">9 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">12 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">15 AUD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">34.32 AUD</div>
                </Col>
              </>
            )}
            {costType === 'USD' && (
              <>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">7 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">9 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">11 USD</div>
                </Col>
                <Col className="px-1">
                  <div className="pricing-table-cell-col">14.38 USD</div>
                </Col>
              </>
            )}
          </div>
        </Col>
      </div>
    </Container >
  );
}

export default PriceGuideTable;