import Header from "../include/header";
import Footer from "../include/footer";
import { Col, Container, Dropdown, Pagination, Row } from 'react-bootstrap';
import { isDesktop, isMobile, isTablet } from 'react-device-detect';
import CommonMeta from '../include/CommonMeta';
import MobileHeader from '../include/mobileHeader';
import GetRequestProposalForm from '../../getRequestProposalForm';
import MobileFooter from "../include/mobileFooter";
import { Link } from "react-router-dom";
import Images from "../../../constant/images";
import galleryData from '../../../json/gallery.json'
import { useState } from "react";

function Gallery() {

  const [galleryItems, setGalleryItems] = useState(galleryData);
  const [active, setActive] = useState("All");

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentItems = galleryItems.slice(firstIndex, lastIndex);


  const galleryFilterItem = (categItem) => {
    const updateItems = galleryData.filter((galleryDat) => {
      return galleryDat.category === categItem;
    });

    setGalleryItems(updateItems);
    setActive(categItem);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const totalPages = Math.ceil(galleryItems.length / itemsPerPage);

  return (
    <>
      <CommonMeta
        title='Gallery'
        description='Remote Philippines Dashboard | Gallery'
        keywords='Remote Philippines Dashboard | Gallery'
        name='Remote Philippines'
        type='article' />

      <div className='header2-wrapper'>
        {isDesktop || isTablet ? <Header /> : <MobileHeader />}
      </div>
      <div className='gallery-wrapper-section'>
        <Container>
          <Row className="gallery-inner-section">
            <Col md={12} lg={12}>
              <div className="offset-lg-6">
                <h1 className="page-heading">Gallery</h1>
              </div>
            </Col>
            <Col md={12} lg={6}>
              <div className='image-section'>
                <img loading="lazy" src={Images.Asset3} className='img-fluid' alt='' />
              </div>
            </Col>
            <Col md={12} lg={6}>
              <div className="heading">
                <h2><Link to=''> G-P Again Named Industry Leader by NelsonHall </Link></h2>
                <div className="link-btns">
                  <ul>
                    <li><Link to=''>General,</Link></li>
                    <li><Link to=''>Global Business Strategy,</Link></li>
                    <li><Link to=''>Global Employment Platform</Link></li>
                  </ul>
                </div>
                <div className='mt-4'><Link className='link-btn2' to='/request-proposal'>  READ MORE </Link> </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="filter-by-gallery-section">
        <Container>
          <Row>
            <Col md={6} lg={6}>
              <div className="filter-by-category">
                <div className="label">
                  <p>Filter by:</p>
                </div>
                <div className="filter-by-dropdown">
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic"> {active} </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item className={active === "All" ? "active" : ""} onClick={() => { setGalleryItems(galleryData); setActive("All"); }} href="">All</Dropdown.Item>
                      <Dropdown.Item className={active === "Gallery" ? "active" : ""} onClick={() => galleryFilterItem("Gallery")} href="">Gallery</Dropdown.Item>
                      <Dropdown.Item className={active === "Video" ? "active" : ""} onClick={() => galleryFilterItem("Video")} href="">Video</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </Col>
            <Col md={6} lg={6}>
              <div className="filter-by-category order-by">
                <div className="label">
                  <p>Order by:</p>
                </div>
                <div className="filter-by-dropdown">
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic"> Newest </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="">Newest</Dropdown.Item>
                      <Dropdown.Item href="">Oldest</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="gallery-section">
        <Container>
          <Row>
            {
              currentItems.map((getGalleryDat, i) => (
                <Col key={i} md={6} lg={4}>
                  <div className="gallery-card-box">
                    <div className="card-image">
                      <img loading="lazy" src={Images.Asset4} className='img-fluid' alt='' />
                    </div>
                    <div className="gallery-card-body">
                      <div className="date"><i className="fa-regular fa-calendar-days"> </i> {getGalleryDat.date}</div>
                      <div className="title">{getGalleryDat.title}<Link to=''> </Link></div>
                      <div className=""><Link className='links' to='/request-proposal'>  READ MORE <i className="fa-solid fa-arrow-right-long"></i></Link></div>
                    </div>
                  </div>
                </Col>
              ))}
            <div className="pagination">
             
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              >
                {currentPage > 1 && (
                  <>
                    <Pagination.Item onClick={() => handlePageChange(1)}> <i className="fa-solid fa-angles-left"></i> FIRST </Pagination.Item>
                    <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}> <i className="fa-solid fa-angle-left"></i> Previous</Pagination.Item>
                  </>
                )}

                {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
                  <Pagination.Item
                    key={page}
                    active={currentPage === page}
                    onClick={() => handlePageChange(page)}
                  >
                    {page}
                  </Pagination.Item>
                ))}
                {currentPage < totalPages && (
                  <>
                    <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>NEXT <i className="fa-solid fa-angle-right"></i></Pagination.Item>
                    <Pagination.Item onClick={() => handlePageChange(totalPages)}>Last <i className="fa-solid fa-angles-right"></i></Pagination.Item>
                  </>
                )}
              </Pagination>
            </div>
            
          </Row>
        </Container>
      </div>
      <div className='get-started-form'>
        <Container>
          <Row>
            <Col className="col-12 col-lg-10 offset-lg-1">
              <div className='request-contact-form'>
                <h2 className='text-center'>Look no further. Get started with your outsourcing journey today!</h2>
                <p className='text-center'>Book a free session with one of Remote Philippines’s expert sales consultants. We'll get in touch with you to arrange a call, respond to any enquiries you might have, and begin onboarding your staff.</p>
                <GetRequestProposalForm />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {isMobile ? <MobileFooter /> : <Footer />}
    </ >
  );
}

export default Gallery;
