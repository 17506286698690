import Header from "../include/header";
import Footer from "../include/footer";
import { Col, Container, Row } from 'react-bootstrap';
import Images from "../../../constant/images";
import CommonMeta from "../include/CommonMeta";
import MobileHeader from "../include/mobileHeader";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import GetRequestProposalForm from "../../getRequestProposalForm";
import MobileFooter from "../include/mobileFooter";


function WorkWithUs() {
  return (
    <div>
      <CommonMeta
        title='Work With Us'
        description='Remote Philippines Dashboard | Work With Us'
        keywords='Remote Philippines Dashboard | Work With Us'
        name='Remote Philippines'
        type='article' />
      <div className='header2-wrapper'>
      {isDesktop || isTablet ? <Header /> : <MobileHeader />}
      </div>
      <div className='our-solution-finance-hero agency-wrapper'>
        <Container>
          <Row className='our-solution-inner-section'>
            <Col md={6}>
              <div className='image-section'>
                <img  loading="lazy" src={Images.Why_Work_With_Us} className='img-fluid' alt='' />
              </div>
            </Col>
            <Col md={6}>
              <div className='our-solution-details'>
                <h4>ABOUT US</h4>
                <h3>Why Work With Us</h3>
                <p>We take pride in our people, knowledge, and expertise which are of the highest caliber. In combination with our unified drive and passion to be top choice for small and medium-sized enterprises around the world looking for offshore employment solutions, we are unmatched.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="about-work-with-us">
        <Container>
          <Row>
            <Col md={12}>
              <div className="about-work-with-inner">
                <h3>We are a local entity in the Philippines directly working with you and your candidate.</h3>
                <ul>
                  <li>We have a local presence and are accessible at any time you need us to assist you or your staff.</li>
                  <li>We take on all compliance and administration responsibility of your employee being the Employer on record in the Philippines.</li>
                  <li>You don’t deal with any third-party entity or middle-man to engage us; you deal directly with us.</li>
                </ul>
              </div>
              <div className="about-work-with-inner">
                <h3>We have specialized expertise on labor laws, compliance and benefits.</h3>
                <ul>
                  <li>Recruitment is our passion and nobody does first-class hiring like we do.</li>
                  <li>We have a huge talent network to tap in for immediate placement so you can hire in days rather than months.</li>
                  <li>Meet talents that have high skill levels, cultural fit, and character qualities that are crucial for your company.</li>
                </ul>
              </div>
              <div className="about-work-with-inner">
                <h3>We have full view and knowledge of local talents, competency and market.</h3>
                <ul>
                  <li>Provide competitive benefits packages.</li>
                  <li>Proactively seek and attract the top 3% talent in the market.</li>
                  <li>Reliable support team with expertise ranging from client services, payroll and finance, human resource management, recruitment and legal and office administration.</li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='get-started-form'>
        <Container>
          <Row>
            <Col className="col-12 col-lg-10 offset-lg-1">
              <div className='request-contact-form'>
                <h2 className='text-center'>Look no further. Get started with your outsourcing journey today!</h2>
                <p className='text-center'>Book a free session with one of Remote Philippines’s expert sales consultants. We'll get in touch with you to arrange a call, respond to any enquiries you might have, and begin onboarding your staff.</p>
                <GetRequestProposalForm />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {isMobile ? <MobileFooter /> : <Footer />}
    </div>
  );
}

export default WorkWithUs;
