import React, { useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './components/pages/home';
import RequestProposal from './components/pages/requestProposal';
import ContactUs from './components/pages/contactUs';
import Benefits from './components/pages/about/benefits';
import Agency from './components/pages/employers/company/agency';
import Bootstrapped from './components/pages/employers/company/bootstrapped';
import CostCutting from './components/pages/employers/company/costCutting';
import Enterprise from './components/pages/employers/company/enterprise';
import HighGrowth from './components/pages/employers/company/highGrowth';
import SharedServices from './components/pages/employers/company/sharedServices';
import SmallMediumSizedBusinesses from './components/pages/employers/company/smallMediumSizedBusinesses ';
import Startup from './components/pages/employers/company/startup';
import Technology from './components/pages/employers/company/technology';
import FinanceAndAccounting from './components/pages/employers/role/financeAndAccounting';
import InformationTechnology from './components/pages/employers/sector/informationTechnology';
import Careers from './components/pages/talents/careers';
import OurPeople from './components/pages/about/ourPeople';
import OurMission from './components/pages/about/ourMission';
import WorkWithUs from './components/pages/about/workWithUs';
import OurServices from './components/pages/about/ourServices';
import ThePhilippines from './components/pages/about/thePhilippines';
import Community from './components/pages/about/community';
import EngagementProcess from './components/pages/about/engagementProcess';
import HiringProcess from './components/pages/talents/hiringProcess';
import { HelmetProvider } from 'react-helmet-async';
import RealEstate from './components/pages/employers/sector/realEstate';
import FinancialServices from './components/pages/employers/sector/financialServices';
import Hospitality from './components/pages/employers/sector/hospitality';
import LegalServices from './components/pages/employers/sector/legalServices';
import DataManagement from './components/pages/employers/sector/dataManagement';
import Telecommunications from './components/pages/employers/sector/telecommunications';
import Healthcare from './components/pages/employers/sector/healthcare';
import Transportation from './components/pages/employers/sector/transportation';
import UtilitiesAndEnergy from './components/pages/employers/sector/utilitiesAndEnergy';
import Travel from './components/pages/employers/sector/travel';
import Ecommerce from './components/pages/employers/sector/eCommerce';
import Education from './components/pages/employers/sector/education';
import Engineering from './components/pages/employers/sector/engineering';
import Logistics from './components/pages/employers/sector/logistics';
import CustomerService from './components/pages/employers/role/customerService';
import LeadGenAndSales from './components/pages/employers/role/leadGenAndSales';
import DigitalMarketing from './components/pages/employers/role/digitalMarketing';
import HumanResources from './components/pages/employers/role/humanResources';
import DesignAndGraphics from './components/pages/employers/role/designAndGraphics';
import SoftwareDevelopment from './components/pages/employers/role/softwareDevelopment';
import VirtualAssistance from './components/pages/employers/role/virtualAssistance';
import Engineerings from './components/pages/employers/role/engineering';
import Medical from './components/pages/employers/role/medical';
import DataScience from './components/pages/employers/role/dataScience';
import TechnicalSupport from './components/pages/employers/role/technicalSupport';
import ProjectManagement from './components/pages/employers/role/projectManagement';
import Cexecutives from './components/pages/employers/role/cExecutives';
import PriceGuide from './components/pages/priceGuide';
import Legal from './components/pages/about/legal';
import Gallery from './components/pages/talents/gallery';
import Faq from './components/pages/knowledge/faq';


function App() {
  
  const location = useLocation();
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [location]);


  return (
    <div className="App">
      <HelmetProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/request-proposal" element={<RequestProposal />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/benefits" element={<Benefits />} />
          <Route path="/agency" element={<Agency />} />
          <Route path="/bootstrapped" element={<Bootstrapped />} />
          <Route path="/cost-cutting" element={<CostCutting />} />
          <Route path="/enterprise" element={<Enterprise />} />
          <Route path="/high-growth" element={<HighGrowth />} />
          <Route path="/shared-services" element={<SharedServices />} />
          <Route path="/small-and-medium-sized-businesses" element={<SmallMediumSizedBusinesses />} />
          <Route path="/startup" element={<Startup />} />
          <Route path="/technology" element={<Technology />} />
          <Route path="/finance-and-accounting" element={<FinanceAndAccounting />} />
          <Route path="/information-technology" element={<InformationTechnology />} />
          <Route path="/hiring-process" element={<HiringProcess />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/our-people" element={<OurPeople />} />
          <Route path="/our-mission" element={<OurMission />} />
          <Route path="/work-with-us" element={<WorkWithUs />} />
          <Route path="/the-philippines" element={<ThePhilippines />} />
          <Route path="/community" element={<Community />} />
          <Route path="/engagement-process" element={<EngagementProcess />} />
          <Route path="/real-estate" element={<RealEstate />} />
          <Route path="/financial-services" element={<FinancialServices />} />
          <Route path="/hospitality" element={<Hospitality />} />
          <Route path="/legal-services" element={<LegalServices />} />
          <Route path="/data-management" element={<DataManagement />} />
          <Route path="/telecommunications" element={<Telecommunications />} />
          <Route path="/healthcare" element={<Healthcare />} />
          <Route path="/transportation" element={<Transportation />} />
          <Route path="/utilities-and-energy" element={<UtilitiesAndEnergy />} />
          <Route path="/travel" element={<Travel />} />
          <Route path="/e-commerce" element={<Ecommerce />} />
          <Route path="/education" element={<Education />} />
          <Route path="/engineering" element={<Engineering />} />
          <Route path="/logistics" element={<Logistics />} />
          <Route path="/customer-service" element={<CustomerService />} />
          <Route path="/lead-gen-and-sales" element={<LeadGenAndSales />} />
          <Route path="/digital-marketing" element={<DigitalMarketing />} />
          <Route path="/human-resources" element={<HumanResources />} />
          <Route path="/design-and-graphics" element={<DesignAndGraphics />} />
          <Route path="/software-development" element={<SoftwareDevelopment />} />
          <Route path="/virtual-assistance" element={<VirtualAssistance />} />
          <Route path="/engineer" element={<Engineerings />} />
          <Route path="/medical" element={<Medical />} />
          <Route path="/data-science" element={<DataScience />} />
          <Route path="/technical-support" element={<TechnicalSupport />} />
          <Route path="/project-management" element={<ProjectManagement />} />
          <Route path="/c-executives" element={<Cexecutives />} />
          <Route path="/our-services" element={<OurServices />} />
          <Route path="/price-guide" element={<PriceGuide />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/faq" element={<Faq />} />
        </Routes>
      </HelmetProvider>
    </div>
  );
}

export default App;
