import React, { } from 'react'
import { Col, Row } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import Images from '../../../constant/images';

function MobileBottomFooter() {
    const location = useLocation();
    const { pathname } = location;

    return (
        <>
            <div className='footer-bottom-nav'>
                <Row className='g-0'>
                    <Col>
                        <ul>
                            
                            {/* <li><Link to='/'> <div className='icon'><i className="fa-solid fa-eject"></i></div> Home</Link></li> */}
                            <li>
                                <Link className={pathname === "/" ? "active" : ""} to='/'>
                                    <div className='icon'>
                                        {pathname === "/" ? <img loading="lazy" src={Images.homeSelected} alt='' className='img-fluid' /> : <img loading="lazy" src={Images.homeUnselected} alt='' className='img-fluid' />}
                                    </div> Home
                                </Link>
                            </li>
                            <li>
                                <Link className={pathname === "/our-mission" ? "active" : "our-mission"} to='/our-mission'>
                                    <div className='icon'>
                                        {pathname === "/our-mission" ? <img loading="lazy" src={Images.aboutSelected} alt='' className='img-fluid' /> : <img loading="lazy" src={Images.aboutUnselected} alt='' className='img-fluid' />}
                                    </div> About
                                </Link>
                            </li>

                            <li>
                                <Link className={pathname === "/information-technology" ? "active" : "information-technology"} to='/information-technology'>
                                    <div className='icon'>
                                        {pathname === "/information-technology" ? <img loading="lazy" src={Images.employersSelected} alt='' className='img-fluid' /> : <img loading="lazy" src={Images.employersUnselected} alt='' className='img-fluid' />}
                                    </div> Employers
                                </Link>
                            </li>
                            <li>
                                <Link className={pathname === "/careers" ? "active" : "careers"} to='/careers'>
                                    <div className='icon'>
                                        {pathname === "/careers" ? <img loading="lazy" src={Images.talentsSelected} alt='' className='img-fluid' /> : <img loading="lazy" src={Images.talentsUnselected} alt='' className='img-fluid' />}
                                    </div> Talents
                                </Link>
                            </li>
                            <li>
                                <Link className={pathname === "https://www.remotephilippines.com/blog/" ? "active" : "https://www.remotephilippines.com/blog/"} to='https://www.remotephilippines.com/blog/'>
                                    <div className='icon'>
                                        {pathname === "https://www.remotephilippines.com/blog/" ? <img loading="lazy" src={Images.knowledgeSelected} alt='' className='img-fluid' /> : <img loading="lazy" src={Images.knowledgeUnselected} alt='' className='img-fluid' />}
                                    </div> Knowledge
                                </Link>
                            </li>

                            {/* <li><Link to='/information-technology'><div className='icon'><img loading="lazy" src={Images.employersIcon} alt='' className='img-fluid' /></div> Employers</Link></li> */}
                            {/* <li><Link to='/careers'><div className='icon'><img loading="lazy" src={Images.servicesIcon} alt='' className='img-fluid' /></div> Talents</Link></li> */}
                            {/* <li><Link to='https://www.remotephilippines.com/blog/'><div className='icon'><img loading="lazy" src={Images.knowledgeIcon} alt='' className='img-fluid' /></div> Knowledge</Link></li> */}
                        </ul>
                    </Col>
                </Row>
            </div>
        </>
    );
}
export default MobileBottomFooter;
