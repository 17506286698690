import Header from "../include/header";
import Footer from "../include/footer";
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Images from "../../../constant/images";
import CommonMeta from "../include/CommonMeta";
import MobileHeader from "../include/mobileHeader";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import GetRequestProposalForm from "../../getRequestProposalForm";
import MobileFooter from "../include/mobileFooter";


function ThePhilippines() {
  return (
    <div>
      <CommonMeta
        title='The Philippines'
        description='Remote Philippines Dashboard | The Philippines'
        keywords='Remote Philippines Dashboard | The Philippines'
        name='Remote Philippines'
        type='article' />
      <div className='header2-wrapper'>
        {isDesktop || isTablet ? <Header /> : <MobileHeader />}
      </div>
      <div className='our-solution-finance-hero agency-wrapper'>
        <Container>
          <Row className='our-solution-inner-section align-items-center'>
            <Col md={6}>
              <div className='image-section'>
                <img  loading="lazy" src={Images.thePhilippines} className='img-fluid' alt='' />
              </div>
            </Col>
            <Col md={6}>
              <div className='our-solution-details'>
                <h4>PEARL OF THE ORIENT SEAS</h4>
                <h3>The Philippines</h3>
                <p>The Philippines is the top outsourcing location in the world thanks to its world-class educational system, affordable cost of living, and English-speaking labor pool. Businesses that outsource their company operations to the Philippines experience significant cost reductions and efficiency gains.</p>
                <div className='mt-5'> <Link className='link-btn' to='/request-proposal'>REQUEST A PROPOSAL</Link></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="about-the-philippines">
        <Container>
          <Row>
            <Col md={12}>
              <div>
              <div className="about-the-philippines-inner mb-5">
                <div className="icon">
                <img  loading="lazy" width={'70px'} src={Images.Discover_icon} className='img-fluid' alt='' />
                </div>
                <div className="text">
                  <h2>Discovering the Philippines</h2>
                  <p>Companies all around the world are constantly considering outsourcing their administrative and core procedures to BPO providers in the Philippines. Outsourcing is economical for the company and contributes to a rise in global and local staff productivity rates.</p>
                  <p>The growth of the sector contributed to the prosperity of cities like Davao, Makati, Pampanga, and Cebu. Although many organizations regard working with Filipinos as more advantageous, the need for professionals is only growing.</p>
                </div>
              </div>
              <div className="about-the-philippines-inner">
                <div className="icon">
                <img  loading="lazy" width={'70px'} src={Images.Success_icon} className='img-fluid' alt='' />
                </div>
                <div className="text">
                  <h2>Success of BPO in the Philippines</h2>
                  <p>Businesses and organizations now have the chance to scale their institutions in ways that weren't feasible a few decades ago because of open global trade and developing technology.</p>
                  <p>Today, companies can offshore and outsource work to other countries without compromising the caliber of the product or the integrity of the brand.</p>
                  <p>The Philippines-based offshoring and outsourcing staff is an extension of your company. Grow and nurture them, much like your local staff. Invest time and effort in developing and nurturing your outsourcing team for success.</p>
                </div>
              </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="growing-your-team">
        <Container>
          <Row>
            <Col md={12}>
              <div className="heading">
                <h3>Hiring and growing your team</h3>
                <p>Your new hire is committed to your firm, follows your instructions, and embraces the culture and values of your organization. The same considerations should go into onboarding, training, and induction as if you were hiring a new team member locally for your company. You are in charge of assigning daily tasks and responsibilities, observing productivity, giving employees performance comments, and rewarding and recognizing them.</p>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6}>
              <div className="image-section">
                <img  loading="lazy" src={Images.groupPicture} className='img-fluid w-100' alt='' />
              </div>
            </Col>
            <Col md={6}>
              <div className="heading ms-lg-5">
                <h3 className="text-start mt-4">Employee engagement and retention</h3>
                <p>It's crucial to keep good employees, especially after making the effort to train them for your company. While we can provide a pleasant working environment and other characteristics that make it a fantastic place to work, you have more control over the factors that affect engagement and retention.</p>
                <div className='mt-5'> <Link className='link-btn' to='/request-proposal'>GET IN TOUCH WITH US</Link></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="hiring-filipinos">
        <Container>
          <Row>
            <Col md={12}>
              <div className="heading">
                <h3 className="">Advantages of hiring Filipinos</h3>
                <p>What makes Filipinos world-class</p>
              </div>
            </Col>
            <Col md={6}>
              <div className="card-box mb-5">
                <img  loading="lazy" src={Images.english_Proficiency} className='img-fluid' alt='' />
                <h4>English proficiency</h4>
                <p>One of the largest English-speaking countries on the globe is the Philippines. 92 percent of Filipinos are able to communicate in English, and most have a clear and neutral accent. One of the nation's admirable characteristics is its fluency in the language, making it the top nation that businesses look to for call center staff.</p>
              </div>
            </Col>
            <Col md={6}>
              <div className="card-box mb-5">
                <img  loading="lazy" src={Images.work_Ethics} className='img-fluid' alt='' />
                <h4>Work ethics</h4>
                <p>Filipinos are renowned for being tenacious and devoted to their work, driven by the desire to achieve their career goals and support their families. One of their appealing qualities that many employers love about them is their respect, loyalty, and determination.</p>
              </div>
            </Col>
            <Col md={6}>
              <div className="card-box">
                <img  loading="lazy" src={Images.culture} className='img-fluid' alt='' />
                <h4>Culture</h4>
                <p>A significant aspect of the success of offshore work  is cultural diversity. The Philippines, which have a strong Western influence from their long history as a U.S. colony, are highly compatible with Western cultures. As a result, the Philippines becomes a center of excellence in the region, and many of the cultural barriers that are present with other Asian nations are removed.</p>
              </div>
            </Col>
            <Col md={6}>
              <div className="card-box">
                <img  loading="lazy" src={Images.economy} className='img-fluid' alt='' />
                <h4>Economy</h4>
                <p>The Philippines' economy is growing at the fastest rate in Asia. With the Philippine Government's efforts to draw in foreign investment, significant advancements have been made in the quality and dependability of the nation's infrastructure and business support capabilities. For example, the Clark Freeport Zone offers government-sponsored tax cuts, incentives for import and export trade, and easy access to the government.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='get-started-form'>
        <Container>
          <Row>
            <Col className="col-12 col-lg-10 offset-lg-1">
              <div className='request-contact-form'>
                <h2 className='text-center'>Look no further. Get started with your outsourcing journey today!</h2>
                <p className='text-center'>Book a free session with one of Remote Philippines’s expert sales consultants. We'll get in touch with you to arrange a call, respond to any enquiries you might have, and begin onboarding your staff.</p>
                <GetRequestProposalForm />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {isMobile ? <MobileFooter /> : <Footer />}
    </div>
  );
}

export default ThePhilippines;
