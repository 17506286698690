import { useLocation } from "react-router-dom";
import Images from "../constant/images";
const Logo = ({ sticky }) => {
    const location = useLocation();
    switch (location.pathname) {
        case '/benefits':
        case '/agency':
        case '/enterprise':
        case '/cost-cutting':
        case '/bootstrapped':
        case '/high-growth':
        case '/shared-services':
        case '/small-and-medium-sized-businesses':
        case '/startup':
        case '/technology':
        case '/our-people':
        case '/work-with-us':
        case '/the-philippines':
        case '/community':
        case '/engagement-process':
        case '/gallery':
        case '/faq':
            return (
                <>
                    {sticky ? <img  loading="lazy" width={'180px'} src={Images.rphLogo} alt='Remote philippines logo' className="img-fluid" /> : <img  loading="lazy" width={'180px'} src={Images.rphLogo} alt='Remote philippines logo' className="img-fluid" />}
                </>
            )

        default:
            return (
                <>
                    {sticky ? <img  loading="lazy" width={'180px'} src={Images.rphLogo} alt='Remote philippines logo' className="img-fluid" /> : <img  loading="lazy" width={'180px'} src={Images.logoWhite} alt='Remote philippines logo' className="img-fluid" />}
                </>
            )
    }
}

export default Logo;