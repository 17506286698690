import Images from "../constant/images";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Col, Container, Row } from "react-bootstrap";

function BlogSlider() {

    var blog = {
        dots: false,
        arrows: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 1000,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScrol1: 1,
    
            },
          },
          {
            breakpoint: 1008,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
    
            },
          },
          {
            breakpoint: 400,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
    
            },
          },
        ],
      };

    return (
        <Container>
            <Row>
                <Col md={12}>
                    <div className='bolg-heading'>
                        <h5>CATCH UP ON ALL OUR LATEST NEWS</h5>
                        <h2> Explore <span>remote teams</span> in the Philippines. Experience global growth.</h2>
                    </div>
                </Col>
                <Col md={12}>
                    <Slider {...blog}>
                        <div>
                            <div className="blog-box">
                                <div className="blog-image">
                                    <img  loading="lazy" src={Images.RP_Staff} className='img-fluid' alt='' />
                                </div>
                                <div className="blog-details">
                                    <a href='https://www.remotephilippines.com/blog/9-advantages-of-hiring-a-remote-staff-in-the-philippines/'> 9 Advantages of hiring a Remote Staff in the Philippines</a>
                                    <div className="read-more-link"><a href='https://www.remotephilippines.com/blog/9-advantages-of-hiring-a-remote-staff-in-the-philippines/'>  READ MORE <i className="fa-solid fa-arrow-right"></i></a></div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="blog-box">
                                <div className="blog-image">
                                    <img  loading="lazy" src={Images.Work_from_home} className='img-fluid' alt='' />
                                </div>
                                <div className="blog-details">
                                    <a href='https://www.remotephilippines.com/blog/online-work-from-home-works-take-the-spotlight-as-ph-battles-covid-19/'> Online, work-from-home works take the spotlight as PH battles COVID-19</a>
                                    <div className="read-more-link"><a href='https://www.remotephilippines.com/blog/online-work-from-home-works-take-the-spotlight-as-ph-battles-covid-19/'>  READ MORE <i className="fa-solid fa-arrow-right"></i></a></div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="blog-box">
                                <div className="blog-image">
                                    <img  loading="lazy" src={Images.challenges} className='img-fluid' alt='' />
                                </div>
                                <div className="blog-details">
                                    <a href='https://www.remotephilippines.com/blog/challenges-of-working-from-home-and-how-to-overcome-them/'> Challenges of Working from Home and How to Overcome Them</a>
                                    <div className="read-more-link"><a href='https://www.remotephilippines.com/blog/challenges-of-working-from-home-and-how-to-overcome-them/'>  READ MORE <i className="fa-solid fa-arrow-right"></i></a></div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="blog-box">
                                <div className="blog-image">
                                    <img  loading="lazy" src={Images.Quick_tips} className='img-fluid' alt='' />
                                </div>
                                <div className="blog-details">
                                    <a href='https://www.remotephilippines.com/blog/quick-tips-for-new-freelancers/'> Quick Tips for New Freelancers</a>
                                    <div className="read-more-link"><a href='https://www.remotephilippines.com/blog/quick-tips-for-new-freelancers/'>  READ MORE <i className="fa-solid fa-arrow-right"></i></a></div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </Col>
            </Row>
        </Container>
    );
}

export default BlogSlider;