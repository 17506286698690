import axios from 'axios';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import toast, { Toaster } from 'react-hot-toast';

const GetRequestProposalForm = () => {
    const [validationErrors, setValidationErrors] = useState([]);
    const recaptchaRef = React.useRef();
    const onSubmitWithReCAPTCHA = async () => {
        const token = await recaptchaRef.current.executeAsync();
    }
    const [formData, setFormData] = useState({});
    const handleChange = (event) => {
        const { name, value } = event.target;
        delete validationErrors[name];
        setFormData(values => ({ ...values, [name]: value }))
    }

    const hasError = (name) => {
        if (name in validationErrors) {
            return true;
        }
        return false;
    }
    const validationError = (name) => {
        if (hasError(name)) {
            return (<div id="validationServer03Feedback" className="invalid-feedback">
                {validationErrors[name][0]}
            </div>)

        }
        return null;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const base_url = process.env.REACT_APP_BASE_URL;
            const response = await axios.post(`${base_url}/send-mail`, formData);
            setValidationErrors([]);
            setFormData({});
            toast.success('Proposal sent successfully');

        } catch (error) {
            if (error.response.status === 422)
                setValidationErrors(error.response.data.errors);
            toast.error(error.response.data.message);
        }
    }

    const companyStaff = ['1-2 staff', '3-10 staff', '11-20 staff', '21-50 staff', '50-100 staff', '100+ staff'];
    const outsourceStaff = ['1-2 staff', '3-10 staff', '11-20 staff', '21-50 staff', '50-100 staff', '100+ staff'];

    return (

        <Form onSubmit={handleSubmit}>
            <Row>
                <Form.Group as={Col} md="6" className='mb-4'>
                    <Form.Label>First Name*</Form.Label>
                    <Form.Control className={hasError('first_name') ? 'is-invalid' : ''} type="text" name="first_name" value={formData.first_name || ""} onChange={handleChange} />
                    {validationError('first_name')}
                </Form.Group>
                <Form.Group as={Col} md="6" className='mb-4'>
                    <Form.Label>Last Name*</Form.Label>
                    <Form.Control type="text" className={hasError('last_name') ? 'is-invalid' : ''} name="last_name" value={formData.last_name || ""} onChange={handleChange} />
                    {validationError('last_name')}
                </Form.Group>
            </Row>
            <Row className="mb-4">
                <Form.Group as={Col} md="12" >
                    <Form.Label>Business Email*</Form.Label>
                    <Form.Control type="email" className={hasError('business_email') ? 'is-invalid' : ''} name="business_email" value={formData.business_email || ""} onChange={handleChange} />
                    {validationError('business_email')}
                </Form.Group>
            </Row>
            <Row className="mb-4">
                <Form.Group as={Col} md="12" >
                    <Form.Label>Contact Number*</Form.Label>
                    <Form.Control type="number" className={hasError('contact_number') ? 'is-invalid' : ''} maxLength={10} name="contact_number" value={formData.contact_number || ""} onChange={handleChange} />
                    {validationError('contact_number')}
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md="6" className="mb-4">
                    <Form.Label>Company Name*</Form.Label>
                    <Form.Control type="text" className={hasError('company_name') ? 'is-invalid' : ''} name="company_name" value={formData.company_name || ""} onChange={handleChange} />
                    {validationError('company_name')}
                </Form.Group>
                <Form.Group as={Col} md="6" className="mb-4">
                    <Form.Label>Job Function*</Form.Label>
                    <Form.Select aria-label="Default select example" className={hasError('job_function') ? 'is-invalid' : ''} name='job_function' value={formData.job_function || ""} onChange={handleChange}>
                        <option >Please Select</option>
                        <option value="Administrative">Administrative</option>
                        <option value="Compliance">Compliance</option>
                        <option value="Finance">Finance</option>
                        <option value="Human Resources">Human Resources</option>
                        <option value="IT/Technology">IT/Technology</option>
                        <option value="Legal">Legal</option>
                        <option value="Management">Management</option>
                        <option value="Marketing">Marketing</option>
                        <option value="Operations">Operations</option>
                        <option value="Other">Other</option>
                        <option value="Sales">Sales</option>
                    </Form.Select>
                    {validationError('job_function')}
                </Form.Group>
            </Row>
            <Row className="mb-4">
                <Form.Group as={Col} md="12" >
                    <Form.Label>How many staff do you need to outsource?</Form.Label>
                    <div className="selector">
                        {
                            outsourceStaff.map((val, i) => {
                                return (
                                    <div key={`outsourceStaff-${i}`} className="selecotr-item">
                                        <input className="selector-item_radio" type="radio" id={`radio2-${i}`} name="staff_need" value={val} checked={val === formData.staff_need ? true : false} onChange={handleChange} />
                                        <label htmlFor={`radio2-${i}`} className="selector-item_label">{val}</label>
                                    </div>
                                );
                            })
                        }
                    </div>
                    {validationError('staff_need')}
                </Form.Group>
            </Row>
            <Row className="mb-4">
                <Form.Group as={Col} md="12" >
                    <Form.Label>What sector is your business in?*</Form.Label>
                    <Form.Select aria-label="Default select example" className={hasError('business_sector') ? 'is-invalid' : ''} name='business_sector' value={formData.business_sector || ""} onChange={handleChange}>
                        <option >Please Select</option>
                        <option value="Information Technology"> Information Technology</option>
                        <option value="Real Estate"> Real Estate</option>
                        <option value="Financial Services"> Financial Services</option>
                        <option value="Hospitality"> Hospitality</option>
                        <option value="Legal Services"> Legal Services</option>
                        <option value="Data Management"> Data Management</option>
                        <option value="Telecommunications"> Telecommunications</option>
                        <option value="Healthcare"> Healthcare</option>
                        <option value="Transportation"> Transportation</option>
                        <option value="Utilities and Energy"> Utilities and Energy</option>
                        <option value="Travel"> Travel</option>
                        <option value="e-Commerce"> e-Commerce</option>
                        <option value="Education"> Education</option>
                        <option value="Engineering"> Engineering </option>
                    </Form.Select>
                    {validationError('business_sector')}
                </Form.Group>
            </Row>
            <Row className="mb-4">
                <Form.Group as={Col} md="12">
                    <Form.Label>What roles are you looking to outsource?</Form.Label>
                    <Form.Control type="text" className={hasError('role_of_resourse') ? 'is-invalid' : ''} name="role_of_resourse" value={formData.role_of_resourse || ""} onChange={handleChange} />
                    {validationError('role_of_resourse')}
                </Form.Group>
            </Row>
            <Row className="mb-4">
                <Form.Group as={Col} md="12" >
                    <Form.Label>What's one question you want to be answered?</Form.Label>
                    <Form.Control type="text" className={hasError('question_to_be_answered') ? 'is-invalid' : ''} name="question_to_be_answered" value={formData.question_to_be_answered || ""} onChange={handleChange} />
                    {validationError('question_to_be_answered')}
                </Form.Group>
            </Row>
            <Row className="mb-4">
                <Form.Group as={Col} md="12" >
                    <Form.Label>How big is your company?</Form.Label>
                    <div className="selector">
                        {
                            companyStaff.map((val, i) => {
                                return (
                                    <div key={`companyStaff-${i}`} className={hasError('role_of_resourse') ? 'selecotr-item is-invalid' : 'selecotr-item'}>
                                        <input className="selector-item_radio" type="radio" id={`radio1-${i}`} name="company_staff_strength" value={val} checked={val === formData.company_staff_strength ? true : false} onChange={handleChange} />
                                        <label htmlFor={`radio1-${i}`} className="selector-item_label">{val}</label>
                                    </div>
                                );
                            })
                        }
                    </div>
                    {validationError('company_staff_strength')}
                </Form.Group>
            </Row>
            <Row className="mb-4">
                <Form.Group as={Col} md="12" >
                    <Form.Label>Anything else you'd like discuss?</Form.Label>
                    <Form.Control className={hasError('anything_else') ? 'is-invalid' : ''} as="textarea" rows={4} name="anything_else" value={formData.anything_else || ""} onChange={handleChange} />
                    {validationError('anything_else')}
                </Form.Group>
            </Row>
            <Row className="mb-4">
                <Form.Group as={Col} md="12" >
                    <Form.Check id="default" label={`We're on a mission to provide businesses like yours occasional news, tips, tricks and industry leading knowledge to grow your business through outsourcing. Don't miss a post. Sign up for our newsletter.`} />
                </Form.Group>
            </Row>
            <Row className="mb-4" >
                <Form.Group as={Col} id="Recapta" md="12" >
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6Ley6GMlAAAAAMPWCUJyR5zQTPdJeUskZ2bk9HIj"
                    />
                </Form.Group>
            </Row>
            <div className='d-flex justify-content-end'>
                <Button className='submit-btn' type="submit">Get Started</Button>
            </div>
            <Toaster />
        </Form>
    );
}

export default GetRequestProposalForm;