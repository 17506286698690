import { useEffect, useState } from "react";
import Header from "./include/header";
import Footer from "./include/footer";
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Images from '../../constant/images';
import CommonMeta from './include/CommonMeta';
import MobileHeader from "./include/mobileHeader";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import GetRequestProposalForm from "../getRequestProposalForm";
import MobileFooter from "./include/mobileFooter";
import cityData from '../../json/cities.json'
function ContactUs() {


  const [cities, setCities] = useState([]);

  useEffect(() => {
    cityData.sort((a, b) => a.title.localeCompare(b.title))
    setCities(cityData)
  }, [])

  return (
    <div id="go-top">
      <CommonMeta
        title='Contact us'
        description='Remote Philippines Dashboard | Contact us'
        keywords='Remote Philippines Dashboard | Contact us'
        name='Remote Philippines'
        type='article' />
      {isDesktop || isTablet ? <Header /> : <MobileHeader />}
      <div className='global-banner-2 bg-sea-1'>
        <Container>
          <Row>
            <Col sm={12} md={12} lg={5}>
              <div className="request-a-proposal-header-text">
                <h1 className="">Ready to see what we can do for you?</h1>
                <p className="">Outsource the right way, the first time. Start your outsourcing journey with a partner who will help  you succeed.</p>
              </div>
            </Col>
            <Col sm={12} md={12} lg={7}>
              <div className="global-banner-2__gradient-overlay d-flex justify-content-end">
                <div className="gradient-bar gradient-primary-2 d-lg-block d-none"></div>
                <div className="gradient-bar gradient-primarys-1 d-lg-block d-none" ></div>
                {/* <div className="gradient-bar gradient-secondary-1 d-lg-block d-none" ></div>
                <div className="gradient-bar gradient-secondary-5 d-lg-block d-none" ></div>
                <div className="gradient-bar gradient-secondary-8"></div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='contact-us-form-section'>
        <Container>
          <Row>
            <Col sm={12} md={12} lg={5}></Col>
            <Col sm={12} md={12} lg={7}>
              <div className='request-contact-form'>
                <h2>Unlock a new world of talent with outsourcing.</h2>
                <p>One of our outsourcing experts will get in touch with you right away.</p>
                <GetRequestProposalForm />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='something-particular'>
        <Container>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <div className='text-heading-section'>
                {/* <div className="gradient-bar gradient-primary-2"></div> */}
                <h1>Looking for something in particular?</h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12} lg={4}>
              <div className='cards-wrapper'>
                <div className='card-image'>
                  <img loading="lazy" src={Images.sales} alt='Remote philippines logo' />
                </div>
                <h2>Sales</h2>
                <p>Do you want to know if Remote Philippines is right for your company? Contact our sales team for a discovery call and find out more how we can help you with your staffing needs.</p>
                <div className='card-btn'> <Link className='link-btn' to='https://calendly.com/remotephilippines/'>CONTACT US <i className="fa-solid fa-arrow-up"></i></Link></div>
              </div>
            </Col>
            <Col sm={12} md={12} lg={4}>
              <div className='cards-wrapper'>
                <div className='card-image'>
                  <img loading="lazy" src={Images.careers1} alt='Remote philippines logo' />
                </div>
                <h2>Careers</h2>
                <p>Begin your career with Remote Philippines and discover a world of opportunities! We are a workplace that encourages everyone's success by providing a supportive and a values-driven culture.</p>
                <div className='card-btn'> <a target="_blank" className='link-btn' href='https://docs.google.com/forms/d/e/1FAIpQLSdsQCLNBYHXTm6QAnrQg7byRJK2FIGXO3ET-IN5tQEuNXTlVw/viewform?fbclid=IwAR3iZPYYPTNeiFpNf1SQuAUC1-05L25BLZXPpGxslMz5xkgB4QFNnP5wWZw'>CONTACT US <i className="fa-solid fa-arrow-up"></i></a></div>
              </div>
            </Col>
            <Col sm={12} md={12} lg={4}>
              <div className='cards-wrapper'>
                <div className='card-image'>
                  <img loading="lazy" src={Images.ask_anything} alt='' />
                </div>
                <h2>Ask Us Anything</h2>
                <p>We are always thrilled to speak with incredible people and fantastic organizations. Send us your inquiry via mail and let’s talk further. Our team of experts are eager to help you achieve your goals.</p>
                <div className='card-btn'> <Link className='link-btn' to='mailto:corp-sales@remotephilippines.com '>CONTACT US <i className="fa-solid fa-arrow-up"></i></Link></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='our-workforce-footprint'>
        <Container>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <div className='text-heading-section'>
                {/* <div className="gradient-bar gradient-primary-2"></div> */}
                <h1>Our Workforce Footprint</h1>
                <p>We are a remote-first business with team members located all over the Philippines. This demonstrates the high-caliber Filipino employees that can be found throughout the nation's islands. These are the locations where you may discover our employees enjoying the balance of life and remote work arrangement.</p>
              </div>
            </Col>
          </Row>
          <Row>
            {
              cities.map((item, i) => {
                return (
                  <Col sm={12} md={12} lg={4}>
                    <div className='our-workforce-footprint-card'>
                      <div className='card-image'>
                        <img loading="lazy" src={Images[item.title.replaceAll(" ", "")]} alt='' className='img-fluid' />
                      </div>
                      <div className='card-label'>
                        <h4 className="city-titles">{item.title}</h4>
                        <div className='flag'><img loading="lazy" src={Images.flag} alt='' className='img-fluid' /></div>
                      </div>
                      <div className='footprint-card-btn'> <Link className='link-btn' to='/request-proposal'>CONTACT US</Link></div>
                    </div>
                  </Col>
                )
              })
            }
          </Row>
        </Container>
      </div>
      <div className='about-us-wrapper'>
        <Container>
          <Row className="align-items-center">
            <Col sm={12} md={12} lg={6}>
              <h2>About Us</h2>
              <p>Remote Philippines enables growing businesses to realize their full potential by allowing them to build highly-skilled Filipino teams fast. We help find, hire, onboard and pay team members quickly and compliantly through our streamlined in-house processes. We empower Filipino talents by connecting them with global opportunities and contribute to the future of remote work.</p>
              <h3>BUILD AMAZING FILIPINO TEAMS</h3>
              <div className='mob-numbers'>
                <h5>We Work Remotely</h5>
                <ul>
                  <li><Link to='tel:+61 3 9111 2490'> <i className="fa-solid fa-phone"></i> +61 3 9111 2490 (AU)</Link></li>
                  <li><Link to='tel:+1 929 447 0422'> <i className="fa-solid fa-phone"></i> +1 929 447 0422 (US)</Link></li>
                  <li><Link to='tel:+44  204 577 0764'> <i className="fa-solid fa-phone"></i> +44  204 577 0764 (UK)</Link></li>
                </ul>
                <div><Link className='link-btn' to='/request-proposal'>CONTACT US <i className="fa-solid fa-arrow-up"></i></Link></div>
              </div>
            </Col>
            <Col sm={12} md={12} lg={6}>
              <div className="global-banner-2__gradient-overlay global-banner-role-pages d-flex justify-content-end">
                <div className="gradient-bar gradient-primary-2 d-lg-block" ></div>
                <div className="gradient-bar gradient-primarys-1 d-lg-block"></div>
              </div>
              <div className="about-us-image-section">
                <div className='mt-2'><img loading="lazy" src={Images.ContactUs} alt='' className='img-fluid' /></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {isMobile ? <MobileFooter /> : <Footer />}
    </div >
  );
}

export default ContactUs;
