import Images from "../constant/images";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";

function ClientTestimonials() {

    const YOUTUBE_PLAYLIST_ITEMS_API = 'https://www.googleapis.com/youtube/v3/playlistItems';
    const [youTubedata, setyouTubedata] = useState([]);
    const getYoutubePlayList = async () => {
        try {
            const res = await fetch(`${YOUTUBE_PLAYLIST_ITEMS_API}?part=snippet&playlistId=PLeTyYUgffKJDNecHvA_gfwou6SX0lOtqX&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`);
            const data = await res.json();
            setyouTubedata(data?.items || [])
        } catch (error) {

        }
    }

    useEffect(() => {
        getYoutubePlayList();
    }, [])

    var settings = {
        dots: false,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        autoplay: false,
        slidesToScroll: 1,
        speed: 1000,
        centerMode: true,
        centerPadding: '150px',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScrol1: 1,
                },
            },
            {
                breakpoint: 1008,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    centerPadding: '0px',
                },
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    centerPadding: '0px',
                },
            },
        ],
    };

    return (
        <div className="customer-slider-section outsourcing-any-role-slider">

            {youTubedata.length > 0 && (
                <Slider {...settings}>
                    {
                        youTubedata.map((item, i) => {
                            const { id, snippet = {} } = item;
                            const { title, thumbnails = {}, resourceId } = snippet;
                            const { standard = {} } = thumbnails;
                            return (
                                <div key={i}>
                                    <div className="customer-box">
                                        <div className="customer-image yuotube-list">
                                            <iframe width={"100%"} height={"440px"} src={`https://www.youtube.com/embed/${resourceId.videoId}`} title={title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </Slider>
            )}
        </div>
    );
}

export default ClientTestimonials;