import Header from "./include/header";
import Footer from "./include/footer";
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import Images from '../../constant/images';
import { Link } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Partners from '../Partners';
import MobileHeader from "./include/mobileHeader";
import PartnersSlider from "../PartnersSlider";
import { isDesktop, isMobile, isTablet } from 'react-device-detect';
import CommonMeta from "./include/CommonMeta";
import BlogSlider from "../blogSlider";
import GetRequestProposalForm from "../getRequestProposalForm";
import StaffTestimonials from "../staffTestimonials";
import HomeVideo from "../homeVideo";
import MobileFooter from "./include/mobileFooter";
import { useLayoutEffect, useState } from "react";
import ClientTestimonials from "../clientTestimonials";

function Home() {
  const [customStyle, setCustomStyle] = useState({
    transform: 'scale(0.85, 0.85)'
  })

  useLayoutEffect(() => {
    const minHeight = 200;
    const maxHeight = 1300;
    const fixedHeader = () => {
      let scalePerPixcel = .0007;

      if (window.pageYOffset < minHeight) {
        setCustomStyle({
          transform: 'scale(0.75, 0.75)',
          transition: "transform 300ms ease-in-out",
        });
      }
      if (window.pageYOffset >= minHeight && window.pageYOffset <= maxHeight) {
        let scale = .75 + (scalePerPixcel * window.pageYOffset);
        if (scale > 1)
          scale = 1

        setCustomStyle({
          transform: `translate3d(0px, 0px, 0px) scale(${scale})`,
          transition: "transform 300ms ease-in-out",
        })
      }
    }
    window.addEventListener('scroll', fixedHeader)
  }, [])


  return (
    <div>

      <CommonMeta
        title='Home'
        description='Remote Philippines Dashboard | Candidate'
        keywords='Remote Philippines Dashboard | Candidate'
        name='Remote Philippines'
        type='article' />

      {isDesktop || isTablet ? <Header /> : <MobileHeader />}
      <div className='home-page global-banner-2 bg-sea-1'>
        <div className=''>
          <Row className='g-0'>
            <Col md={12} lg={5}>
              <div className="request-a-proposal-header-text">
                <h1 className="">Delivering exceptional
                  <TypeAnimation
                    sequence={[
                      'outsourcing', 3000,
                      'remote teams', 3000,
                      'offshore solutions', 3000,
                      'virtual workforce', 3000,
                      'recruitment solutions', 3000,
                    ]}
                    wrapper="div"
                    className="TypeAnimation"
                    cursor={true}
                    repeat={Infinity}
                  />
                </h1>
                <p className="">World-class talent recruitment, onboarding, payroll, benefits, taxes,  and compliance for companies of all sizes.</p>
                <div className='our-solution-details-btn mt-4'>
                  <Link className='link-btn' to='/request-proposal'>GET STARTED</Link>
                  <a className='link-btn watch-video' href="#watch-video">WATCH VIDEO</a>
                </div>
              </div>
            </Col>
            <Col md={12} lg={7}>
              <div className='home_page_top_wrapper home_page'>
                <div className="home-wrapp global-banner-2__gradient-overlay d-flex justify-content-end">
                  <div className="gradient-bar gradient-primarys-1 d-lg-block d-none"></div>
                </div>
                {/* <div className="global-banner-2__gradient-overlay d-flex justify-content-end">
                  <div className="gradient-bar gradient-primary-2 d-lg-block d-none"></div>
                  <div className="gradient-bar gradient-primary-3 d-lg-block d-none" >
                    <div className='home-gradient-bar-image'>
                      <img  loading="lazy" src={Images.bg_Banner1} alt='' />
                    </div>
                  </div>
                  <div className="gradient-bar gradient-primary-4 d-lg-block d-none" ></div>
                  <div className="gradient-bar gradient-primary-5 d-lg-block d-none" ></div>
                  <div className="gradient-bar gradient-secondary-1 d-lg-block d-none" >
                    <div className='home-gradient-bar-image'>
                      <img  loading="lazy" src={Images.bg_Banner2} alt='' />
                    </div>
                  </div>
                  <div className="gradient-bar gradient-secondary-5 d-lg-block d-none" ></div>
                  <div className="gradient-bar gradient-secondary-12 d-lg-block d-none" ></div>
                  <div className="gradient-bar gradient-secondary-5 gradient-secondary_5 d-lg-block d-none" >
                    <div className='home-gradient-bar-image'>
                      <img  loading="lazy" src={Images.bg_Banner3} alt='' />
                    </div>
                  </div>
                </div> */}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div id="watch-video" style={customStyle} className='visme-video-hero-wrapper'>
        <Container>
          <Row>
            <Col>
              <HomeVideo />
            </Col>
          </Row>
        </Container>

      </div>
      <div className='our-technology'>
        <div className="global-banner-2__gradient-overlay d-flex justify-content-end">
          <div className="gradient-bar gradient-primary-2 d-lg-block d-none" ></div>
          <div className="gradient-bar gradient-primarys-1 d-lg-block d-none"></div>
        </div>
        <Container>
          <Row>
            <Col md={12}>
              <div className='heading'>
                <h4>EVERYTHING YOU NEED</h4>
                <h3>Make growing easy with our complete package <br />of outsourcing services.</h3>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className='our-technology-wrapper-box'>
                <div className='our-technology-inner-box'>
                  <div className='icon'>
                    <img loading="lazy" src={Images.recruit} alt='' className='img-fluid' />
                    <div className="heading"><h5>Recruit</h5></div>
                  </div>
                  <div className='heading-text'>
                    <p>Remote Philippines will assist you in locating the top talent, either on full-time or part-time engagements, at the right cost.</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className='our-technology-wrapper-box'>
                <div className='our-technology-inner-box'>
                  <div className='icon'>
                    <img loading="lazy" src={Images.hire} alt='' className='img-fluid' />
                    <div className="heading"><h5>Hire</h5></div>
                  </div>
                  <div className='heading-text'>
                    <p>Once we've found you the perfect talent, we'll complete the onboarding and have your new staff working for you right away.</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className='our-technology-wrapper-box'>
                <div className='our-technology-inner-box'>
                  <div className='icon'>
                    <img loading="lazy" src={Images.pay} alt='' className='img-fluid' />
                    <div className="heading"><h5>Pay</h5></div>
                  </div>
                  <div className='heading-text'>
                    <p>While you maintain complete control over your workforce by managing their daily tasks, we pay your staff their periodic wages & benefits on time.</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className='our-technology-wrapper-box'>
                <div className='our-technology-inner-box'>
                  <div className='icon'>
                    <img loading="lazy" src={Images.hr} alt='' className='img-fluid' />
                    <div className="heading"><h5>HR</h5></div>
                  </div>
                  <div className='heading-text'>
                    <p>Our local HR team will ensure that our people adhere to corporate compliance and feel engaged with the organization and their work. </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className='our-technology-wrapper-box'>
                <div className='our-technology-inner-box'>
                  <div className='icon'>
                    <img loading="lazy" src={Images.taxes} alt='' className='img-fluid' />
                    <div className="heading"><h5>Tax</h5></div>
                  </div>
                  <div className='heading-text'>
                    <p>We'll ensure that Remote Philippines and your hires stay in compliance with laws and taxes by declaring income, filing a return, and paying the tax due in a timely manner.</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className='our-technology-wrapper-box'>
                <div className='our-technology-inner-box'>
                  <div className='icon'>
                    <img loading="lazy" src={Images.comply} alt='' className='img-fluid' />
                    <div className="heading"><h5>Comply</h5></div>
                  </div>
                  <div className='heading-text'>
                    <p>Our team of local corporate specialists stays up to date on labor and payroll rules to ensure we are ever compliant with our labor engagements and practices.</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='transform-your-business'>
        <Container>
          <Row className="transform-your-business-top">
            <Col sm={12} md={12} lg={6}>
              <h2>Transform your business with Remote Philippines</h2>
              <p>Unlock the power of remote teams today.</p>
            </Col>
            <Col sm={4} md={6} lg={6}>
              {/* <div className='country-dropdown text-end md-me-5'>
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic"> <img  loading="lazy" width={'45px'} src={Images.au} alt='' className='img-fluid' /> <span className="name"> AUD</span> </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#"><img  loading="lazy" width={'45px'} src={Images.au} alt='' className='img-fluid' /> <span className="name"> AUD</span></Dropdown.Item>
                    <Dropdown.Item href="#"><img  loading="lazy" width={'45px'} src={Images.ca} alt='' className='img-fluid' /> <span className="name"> CAD</span></Dropdown.Item>
                    <Dropdown.Item href="#"><img  loading="lazy" width={'45px'} src={Images.eu} alt='' className='img-fluid' /> <span className="name"> SGD</span></Dropdown.Item>
                    <Dropdown.Item href="#"><img  loading="lazy" width={'45px'} src={Images.sg} alt='' className='img-fluid' /> <span className="name"> EUR</span></Dropdown.Item>
                    <Dropdown.Item href="#"><img  loading="lazy" width={'45px'} src={Images.gb} alt='' className='img-fluid' /> <span className="name"> GBP</span></Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div> */}
            </Col>
          </Row>
          <Row className='align-items-center'>
            <Col md={6} lg={4} >
              <div className='transform-your-business-services'>
                <div className='heading-section'>
                  <img loading="lazy" width={'83px'} src={Images.discover} alt='' className='img-fluid' />
                  <h4>DISCOVER</h4>
                </div>
                <div className='details'>
                  <p>We take the time to understand your company's objectives, and we will use our expertise and experience to guide you towards the best solution for you.</p>
                  <div className='our-solution-details-btn text-center'> <Link className='link-btn' to='https://calendly.com/remotephilippines'>FIND OUT MORE</Link></div>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className='transform-your-business-services order2'>
                <div className='heading-section'>
                  <img loading="lazy" width={'83px'} src={Images.engage} alt='' className='img-fluid' />
                  <h4>ENGAGE</h4>
                </div>
                <div className='details'>
                  <p>Sign up and tell us about your open positions. At Remote Philippines, we help drive success for your business by building teams faster - in days, not weeks or months.</p>
                  <div className='our-solution-details-btn text-center'> <Link className='link-btn' to='https://calendly.com/remotephilippines'>FIND OUT MORE</Link></div>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className='transform-your-business-services'>
                <div className='heading-section'>
                  <img loading="lazy" width={'83px'} src={Images.grow} alt='' className='img-fluid' />
                  <h4>GROW</h4>
                </div>
                <div className='details'>
                  <p>You can rely on Remote Philippines’ team, knowledge, and resources to make sure you get the most out of your outsourcing experience.</p>
                  <div className='our-solution-details-btn text-center'> <Link className='link-btn' to='https://calendly.com/remotephilippines'>FIND OUT MORE</Link></div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='trusted-awesome-clients-wrapper'>
        <div className='trusted-awesome-clients'>
          <Container>
            <Row>
              <Col sm={12} md={12} lg={6}>
                <h4>TRUSTED BY AWESOME CLIENTS</h4>
                <h5>With Remote Philippines, customer satisfaction comes <span className="greadint-text"> first.</span></h5>
                <p>Listen to what our customers have to say about their positive outsourcing experience with us and learn how you can use outsourcing to grow your business.</p>
                <Link className='link-btn mt-4' to='/request-proposal'>SEE HOW WE HELP</Link>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className='testimonials-video-slider'>
        <Container>
          <Row>
            <Col md={12}>
              <ClientTestimonials />
            </Col>
          </Row>
        </Container>
      </div>
      <div className='comprehensive-outsourcing-services'>
        <Container >
          <Row>
            <Col md={9} className="align-self-center">
              <div className='heading-section'>
                <h2>Our comprehensive outsourcing services is your <span className="text"> end-to-end </span> solution.</h2>
                <p>We offer a full range of services, from finding the ideal applicant to ensuring we build honest, loyal and great teams which are essential to our client’s success.</p>
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid className="comprehensive-container p-0 mx-auto">
          <Row className="g-0 col-reverse-wrapper mb-5 align-items-center">
            <Col md={12} lg={6}>
              <div className='outsourcing-services outsourcing-services1'>
                <div className="gradient-primary-2"></div>
                <h5>PREMIUM TALENT</h5>
                <h3 className="me-md-5 col-xxl-7 col-xl-8 col-lg-11">We find the right people.</h3>
                <p className="me-md-5 col-xxl-9 col-lg-10">Finding the right people is a major difficulty for expanding businesses in the current economy. Thus, it's critical now more than ever for businesses to recognize the potential of a remote employment strategy. We will recruit the staff that will become a valuable extension of your business. As your outsourcing partner on the ground, we will provide you an end-to-end suite of services from HR, payroll, compliance, administration and support services to make outsourcing work for you over the long term.</p>
                <Link className='link-btn' target='_blank' to="https://calendly.com/remotephilippines/">Book a Discovery Call</Link>
              </div>
            </Col>
            <Col md={12} lg={6}>
              <div className="outsourcing-services-img"> <img loading="lazy" src={Images.HomepageModel1} alt='' className='img-fluid' /></div>
            </Col>
          </Row>
          <Row className="g-0 mt-5 align-items-center">
            <Col md={12} lg={6}>
              <div className="outsourcing-services-img"> <img loading="lazy" src={Images.HomepageModel2} alt='' className='img-fluid' /></div>
            </Col>
            <Col md={12} lg={6}>
              <div className='outsourcing-services outsourcing-services2 ms-xl-6 ms-lg-4 ps-xl-5'>
                <div className="gradient-primary-2"></div>
                <h5 className="ms-md-5">ABSOLUTE CARE</h5>
                <h3 className="ms-md-5">We are fully invested in achieving your goals.</h3>
                <p className="ms-md-5">We take great satisfaction in giving businesses a definite edge over the competition. We are aware of the challenges finding top personnel and rising labor costs to provide to our clients. To support our clients' local teams in whatever capacity they need, we connect them with competent, professional and trust-worthy Filipino workers.</p>
                <p className="ms-md-5">The processes of discovery, recruiting, employment, monitoring, and quality assurance receive thorough attention at Remote Philippines. Thanks to our fully capable and committed team of specialists, we can help our clients build value for their business through outsourcing.</p>
                <Link className='link-btn ms-md-5' target='_blank' to="https://calendly.com/remotephilippines/">Book a Discovery Call</Link>
              </div>
            </Col>
          </Row>
        </Container>

      </div>
      <div className='our-tab-technology-wrapper'>
        <Row className='g-0'>
          <Col>
            <Tabs defaultActiveKey="ourExpertise" transition={false} id="noanim-tab-example" className="">
              <Tab eventKey="ourExpertise" title="Our Expertise">
                <Container>
                  <Row>
                    <Col>
                      <div className='tab-details'>
                        <h3>Build anything with experts who know how to work smart with new technologies and emerging tools.</h3>
                        <p>There are countless technology solutions to everyday problems and issues in the digital age. You have a plethora of options at your disposal to complete your work more quickly, simply, and effectively. The correct technologies can guarantee easy communication, easy cooperation, and easy integration of the work from multiple team members.</p>
                        <div className='logo mt-5'>
                          <div className="headings">A trusted global partner in providing premium <br /> talent - <span className="gradient-text">ready to join your team.</span></div>
                          <p className="text-center">Here are a few of the cutting-edge tools in which our team members excel. </p>
                          {isMobile ? <PartnersSlider /> : <Partners />}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Tab>
              <Tab eventKey="technology " title="Technology ">
                <Container>
                  <Row>
                    <Col>
                      <div className='tab-details'>
                        <h3>Here are some of the tools our experts</h3>
                        <h5>use to work smarter</h5>
                        <p>There are countless technology solutions to everyday problems and issues in the digital age. This also applies to business. You have a plethora of options at your disposal to complete your work more quickly, simply, and effectively. The correct technologies can guarantee easy communication, easy cooperation, and easy integration of the work from multiple team members.</p>
                        <div className='logo mt-5'>
                          <div className="headings">A trusted global partner in providing premium <br /> talent - <span className="gradient-text">ready to join your team.</span></div>
                          <p className="text-center">Here are a few of the cutting-edge tools in which our team members excel. </p>
                          {isMobile ? <PartnersSlider /> : <Partners />}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Tab>
              <Tab eventKey="virtualAssistants" title="Virtual Assistants">
                <Container>
                  <Row>
                    <Col>
                      <div className='tab-details'>
                        <h3>Here are some of the tools our experts</h3>
                        <h5>use to work smarter</h5>
                        <p>There are countless technology solutions to everyday problems and issues in the digital age. This also applies to business. You have a plethora of options at your disposal to complete your work more quickly, simply, and effectively. The correct technologies can guarantee easy communication, easy cooperation, and easy integration of the work from multiple team members.</p>
                        <div className='logo mt-5'>
                          <div className="headings">A trusted global partner in providing premium <br /> talent - <span className="gradient-text">ready to join your team.</span></div>
                          <p className="text-center">Here are a few of the cutting-edge tools in which our team members excel. </p>
                          {isMobile ? <PartnersSlider /> : <Partners />}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Tab>
              <Tab eventKey="sales" title="Sales">
                <Container>
                  <Row>
                    <Col>
                      <div className='tab-details'>
                        <h3>Here are some of the tools our experts</h3>
                        <h5>use to work smarter</h5>
                        <p>There are countless technology solutions to everyday problems and issues in the digital age. This also applies to business. You have a plethora of options at your disposal to complete your work more quickly, simply, and effectively. The correct technologies can guarantee easy communication, easy cooperation, and easy integration of the work from multiple team members.</p>
                        <div className='logo mt-5'>
                          <div className="headings">A trusted global partner in providing premium <br /> talent - <span className="gradient-text">ready to join your team.</span></div>
                          <p className="text-center">Here are a few of the cutting-edge tools in which our team members excel. </p>
                          {isMobile ? <PartnersSlider /> : <Partners />}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Tab>
              <Tab eventKey="customerServices" title="Customer Services">
                <Container>
                  <Row>
                    <Col>
                      <div className='tab-details'>
                        <h3>Here are some of the tools our experts</h3>
                        <h5>use to work smarter</h5>
                        <p>There are countless technology solutions to everyday problems and issues in the digital age. This also applies to business. You have a plethora of options at your disposal to complete your work more quickly, simply, and effectively. The correct technologies can guarantee easy communication, easy cooperation, and easy integration of the work from multiple team members.</p>
                        <div className='logo mt-5'>
                          <div className="headings">A trusted global partner in providing premium <br /> talent - <span className="gradient-text">ready to join your team.</span></div>
                          <p className="text-center">Here are a few of the cutting-edge tools in which our team members excel. </p>
                          {isMobile ? <PartnersSlider /> : <Partners />}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Tab>
              <Tab eventKey="Accounting" title="Accounting">
                <Container>
                  <Row>
                    <Col>
                      <div className='tab-details'>
                        <h3>Here are some of the tools our experts</h3>
                        <h5>use to work smarter</h5>
                        <p>There are countless technology solutions to everyday problems and issues in the digital age. This also applies to business. You have a plethora of options at your disposal to complete your work more quickly, simply, and effectively. The correct technologies can guarantee easy communication, easy cooperation, and easy integration of the work from multiple team members.</p>
                        <div className='logo mt-5'>
                          <div className="headings">A trusted global partner in providing premium <br /> talent - <span className="gradient-text">ready to join your team.</span></div>
                          <p className="text-center">Here are a few of the cutting-edge tools in which our team members excel. </p>
                          {isMobile ? <PartnersSlider /> : <Partners />}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </div>
      <div className='home-page bgSmile'>
        <Row className='g-0'>
          <Col>
            <img loading="lazy" src={Images.bgSmile} alt='' className='img-fluid' />
          </Col>
        </Row>
        <div className='bgSmile-inner'>
          <Container>
            <Row>
              <Col md={7}>
                {/* <div className='grow-my-team'>
                  <div className='grow-my-team-inner'>
                    <h2>Grow my team in:</h2>
                    <div className='select-country'>
                      <Form.Select aria-label="Default select example">
                        <option>Select a Country</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </Form.Select>
                    </div>
                  </div>
                </div> */}
                <div className='desc'>
                  <h5>INCREDIBLE VALUE</h5>
                  <h2 className="col-xl-9">We are outsourcing</h2>
                  <h2 className="col-xl-9"><span> any role, any sector.</span></h2>
                  <p className="col-xl-10">Ensure amazing value with Remote Philippines. Increase revenue while cutting costs—the average total cost of employment in the Philippines is typically 70% lower than those in Western countries. Discover the ideal outsourcing solution for your business and easily realize all of its potential. Our wide variety of offshore positions can help you simplify outsourcing and streamline your business processes. You can rely on our team of talent specialists to recruit and hire highly skilled staff for any role, any sector.</p>
                  <div className='mt-5'> <Link className='link-btn' to='/our-services'>VIEW OUR SERVICES</Link></div>
                </div>
              </Col>
              <Col md={5}>
                <Row className="justify-content-end g-0">
                  <div className='country-card-box'>
                    <div className='country-name'>
                      <img loading="lazy" src={Images.Flag_Philippines} alt='' className='img-fluid' />
                      <h4>Philippines</h4>
                    </div>
                    <p>Remote Philippines enables growing businesses to realize their full potential by allowing them to build highly-skilled Filipino teams fast. We help find, hire, onboard and pay team members quickly and compliantly through our streamlined in-house processes. We empower Filipino talents by connecting them with global opportunities and contribute to the future of remote work.</p>
                    <h5>Delivering exceptional outsourcing</h5>
                    <ul>
                      <li><Link to='/our-mission'>Our Mission <i className="fa-solid fa-arrow-right"></i></Link></li>
                      <li><Link to='/our-people'>Our People <i className="fa-solid fa-arrow-right"></i></Link></li>
                      <li><Link to='/benefits'>Benefits <i className="fa-solid fa-arrow-right"></i></Link></li>
                      <li><Link to='/community'>Community <i className="fa-solid fa-arrow-right"></i> </Link></li>
                      <li><Link className='link-btn' to='/faq'>KNOW MORE</Link></li>
                    </ul>
                  </div>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="outsourcing-any-role-section youtube-video">
        <Container>
          <Row>
            <Col md={12}>
              <div className="global-banner-2__gradient-overlay d-flex justify-content-end">
                <div className="gradient-bar gradient-primary-2 d-lg-block" ></div>
                <div className="gradient-bar gradient-primarys-1 d-lg-block"></div>
                {/* <div className="gradient-bar gradient-secondary-1 d-lg-block" ></div>
                <div className="gradient-bar gradient-secondary-5 d-lg-block" ></div>
                <div className="gradient-bar gradient-secondary-6 d-lg-block"></div> */}
              </div>
            </Col>
            <Col md={12}>
              <StaffTestimonials />
            </Col>
          </Row>
        </Container>
      </div>
      <div className='home-page-blog-slider'>
        <BlogSlider />
      </div>
      <div className='home-request-propsal'>
        <Container>
          <Row>
            <Col md={12} lg={6}>
              <Row>
                <Col md={6}>
                  <div className='home-request-propsal-card'>
                    <div className="number gradient-text">USD25bn</div>
                    <div className='text'>sector revenue</div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className='home-request-propsal-card'>
                    <div className="number gradient-text">48M</div>
                    <div className='text'>Filipino workforce</div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className='home-request-propsal-card'>
                    <div className="number gradient-text">59%</div>
                    <div className='text'>firms who outsource</div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className='home-request-propsal-card'>
                    <div className="number gradient-text ">70%</div>
                    <div className='text'>cost savings</div>
                  </div>
                </Col>
                <Col md={12}>
                  <div className='mt-5 text-center'><Link className='link-btn' to='/request-proposal'>  REQUEST A PROPOSAL </Link> </div>
                </Col>
              </Row>
            </Col>
            <Col md={6}></Col>
          </Row>
        </Container>
      </div>
      <div className='get-hire-form'>
        <Row className="g-0">
          <Col>
            <div className='request-contact-form'>
              <div className='bg-right-wrapper'>
                <div className="bg-right"></div>
                <div className="bg-right2"></div>
              </div>
              <Container className='position-relative'>
                <Row>
                  <Col md="12">
                    <div className="form-heading">
                      <h2 className=''>Build amazing teams, hire world-class talent.</h2>
                    </div>
                  </Col>
                  <Col sm={12} md={12} lg={5}>
                    <div className="form-heading">
                      <p className=''>If you’re ready to build your team, grow or have questions about our services, just complete the form and one of our experts will be in touch shortly.</p>
                    </div>
                    <div className="form-image-wrapper">
                      <img loading="lazy" src={Images.Asset9} alt='' className='img-fluid'></img>
                    </div>
                  </Col>
                  <Col sm={12} md={12} lg={7}>
                    <GetRequestProposalForm />
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </div>
      {isMobile ? <MobileFooter /> : <Footer />}

    </div >
  );
}

export default Home;
