import React, { } from 'react'
import { Accordion, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Images from '../../../constant/images'
import ScrollToTop from "react-scroll-to-top";
import MobileBottomFooter from './mobileBottomFooter';

function MobileFooter() {

    return (
        <>
            <div className="footer-wrapper">
                <Container>
                    <Row>
                        <Col md={12} lg={6}>
                            <div className='footer-logo-section'>
                                <Link to='/'>  <img loading="lazy" width={'150px'} src={Images.logoWhite} alt='Remote philippines logo' className="img-fluid" /></Link>
                            </div>
                        </Col>
                        <Col md={12} lg={6}>
                            <div className="social-media-link">
                                <ul>
                                    <li> <Link target={"_blank"} to='https://www.linkedin.com/company/remotephilippines/'><i className="social-icon fa-brands fa-linkedin-in"></i></Link></li>
                                    <li> <Link target={"_blank"} to='https://twitter.com/Remote_phil'><i className="social-icon fa-brands fa-twitter"></i></Link></li>
                                    <li> <Link target={"_blank"} to='https://www.youtube.com/@remotephilippines'><i className="social-icon fa-brands fa-youtube"></i></Link></li>
                                    <li> <Link target={"_blank"} to='https://www.facebook.com/remotephilippines'><i className="social-icon fa-brands fa-facebook-f"></i></Link></li>
                                    <li> <Link target={"_blank"} to='https://www.instagram.com/remotephilippines/'><i className="social-icon fa-brands fa-instagram"></i></Link></li>
                                </ul>
                                <div className="zoom"></div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} lg={3}>
                            <div className="get-intouch">
                                <h2>Get in touch with us</h2>
                                <ul>
                                    <li><Link to='tel:+61391112490'><i className="fa-solid fa-phone"></i> +61-3-9111-2490</Link></li>
                                    <li><Link to='tel:+19294470422'><i className="fa-solid fa-phone"></i> +1-929-447-0422</Link></li>
                                    <li><Link to='tel:+4442045770764'><i className="fa-solid fa-phone"></i> +44-420-4577-0764</Link></li>
                                    <li><Link to='mailto:hello@remotephilippines.com'><i className="fa-solid fa-envelope"></i> hello@remotephilippines.com</Link></li>
                                    <div className="footer-book-a-meeting mt-3">
                                        <Link target='_blank' to="https://calendly.com/remotephilippines/"> Book a meeting</Link>
                                    </div>
                                </ul>
                            </div>
                        </Col>
                        {/* <Col md={12} lg={2}>
                            <Row className="g-0">
                                <Col md={12}>
                                    <div className="footer_about_us">
                                        <h2>About Us</h2>
                                        <ul>
                                            <li><Link to='/our-mission'>Our Mission</Link></li>
                                            <li><Link to='/our-people'>Our People</Link></li>
                                            <li><Link to='/work-with-us'>Why Work with Us</Link></li>
                                            <li><Link to='/'>Our Services</Link></li>
                                            <li><Link to='/benefits'>Benefits</Link></li>
                                            <li><Link to='/the-philippines'>The Philippines</Link></li>
                                            <li><Link to='/engagement-process'>Engagement Process</Link></li>
                                            <li><Link to='/community'>Community</Link></li>
                                            <li><Link to='/'>Legal</Link></li>
                                            <li><Link to='/contact-us'>Contact Us</Link></li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="knowledge">
                                        <h2>Knowledge</h2>
                                        <ul>
                                            <li><Link to='/'>Webinars</Link></li>
                                            <li><Link to='/'>Templates</Link></li>
                                            <li><Link to='/'>E-Course</Link></li>
                                            <li><Link to='/'>E-Book</Link></li>
                                            <li><a href='https://www.remotephilippines.com/blog/'>Blog</a></li>
                                            <li><Link to='/'>FAQ</Link></li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Col> */}

                        {/* <Col md={7}>
                            <div className="our-solution employers-section">
                                <h2>Employers</h2>
                                <ul>
                                    <div className="employers-section-inner">
                                        <Row className="g-0">
                                            <Col md={12} lg={4}>
                                                <h3>Sector</h3>
                                                <ul>
                                                    <li><Link to='/information-technology'> Information Technology</Link></li>
                                                    <li><Link to='/real-estate'> Real Estate</Link></li>
                                                    <li><Link to='/hospitality'> Hospitality</Link></li>
                                                    <li><Link to='/legal-services'> Legal Services</Link></li>
                                                    <li><Link to='/data-management'> Data Management</Link></li>
                                                    <li><Link to='/telecommunications'> Telecommunications</Link></li>
                                                    <li><Link to='/healthcare'> Healthcare</Link></li>
                                                    <li><Link to='/transportation'> Transportation</Link></li>
                                                    <li><Link to='/utilities-and-energy'> Utilities and Energy </Link></li>
                                                    <li><Link to='/travel'> Travel</Link></li>
                                                    <li><Link to='/e-commerce'> e-Commerce</Link></li>
                                                    <li><Link to='/education'> Education</Link></li>
                                                    <li><Link to='/engineering'> Engineering </Link></li>
                                                    <li><Link to='/financial-services'>Financial Services </Link></li>
                                                    <li><Link to='/logistics'>Logistics </Link></li>
                                                </ul>

                                            </Col>
                                            <Col md={12} lg={4}>
                                                <h3>Role</h3>
                                                <ul>
                                                    <li><Link to='/finance-and-accounting'>Finance & Accounting </Link></li>
                                                    <li><Link to='/customer-service'>Customer Service </Link></li>
                                                    <li><Link to='/lead-gen-and-sales'>Lead Gen & Sales </Link></li>
                                                    <li><Link to='/digital-marketing'>Digital Marketing </Link></li>
                                                    <li><Link to='/human-resources'>Human Resources </Link></li>
                                                    <li><Link to='/design-and-graphics'>Design & Graphics </Link></li>
                                                    <li><Link to='/software-development'>Software Development </Link></li>
                                                    <li><Link to='/virtual-assistance'>Virtual Assistance </Link></li>
                                                    <li><Link to='/engineer'>Engineering </Link></li>
                                                    <li><Link to='/medical'>Medical </Link></li>
                                                    <li><Link to='/data-science'>Data Science </Link></li>
                                                    <li><Link to='/technical-support'>Technical Support </Link></li>
                                                    <li><Link to='/project-management'>Project Management </Link></li>
                                                    <li><Link to='/c-executives'>C-Executives </Link></li>
                                                </ul>
                                            </Col>
                                            <Col md={12} lg={4}>
                                                <Row className="g-0">
                                                    <Col md={12}>
                                                        <h3>Company</h3>
                                                        <ul>
                                                            <li><Link to='/startup'>Startups </Link></li>
                                                            <li><Link to='/small-and-medium-sized-businesses'>Small & Medium Sized Businesses </Link></li>
                                                            <li><Link to='/enterprise'>Enterprise </Link></li>
                                                            <li><Link to='/high-growth'>High-Growth </Link></li>
                                                            <li><Link to='/cost-cutting'>Cost-Cutting </Link></li>
                                                            <li><Link to='/agency'>Agency </Link></li>
                                                            <li><Link to='/shared-services'>Shared Services </Link></li>
                                                            <li><Link to='/technology'>Technology </Link></li>
                                                            <li><Link to='/bootstrapped'>Bootstrapped </Link></li>
                                                        </ul>
                                                    </Col>
                                                    <Col md={12}>
                                                        <div className="Pricings Talents">
                                                            <h2>Talents</h2>
                                                            <ul>
                                                                <li><Link to='/careers'>We’re Hiring </Link></li>
                                                                <li><Link to='/hiring-process'>Hiring Process</Link></li>
                                                                <li><Link to='/'>Gallery</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </ul>
                                <Col md={12}>
                                    <div className="partners">
                                        <h2>Pricing</h2>
                                        <ul>
                                            <li><Link to='/'>Pricing </Link></li>
                                        </ul>
                                    </div>
                                </Col>
                            </div>
                        </Col> */}
                    </Row>
                </Container>
                <div className='mobile-footer-accordion'>
                    <Accordion>
                        <div className="footer_about_us">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><h2>About Us</h2></Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        <li><Link to='/our-mission'>Our Mission</Link></li>
                                        <li><Link to='/our-people'>Our People</Link></li>
                                        <li><Link to='/work-with-us'>Why Work with Us</Link></li>
                                        <li><Link to='/our-services'>Our Services</Link></li>
                                        <li><Link to='/benefits'>Benefits</Link></li>
                                        <li><Link to='/the-philippines'>The Philippines</Link></li>
                                        <li><Link to='/engagement-process'>Engagement Process</Link></li>
                                        <li><Link to='/community'>Community</Link></li>
                                        <li><Link to='/legal?title=terms-of-service'>Legal</Link></li>
                                        <li><Link to='/contact-us'>Contact Us</Link></li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        </div>
                        <div className="our-solution employers-section">
                            <Accordion.Item eventKey="1">
                                <Accordion.Header><h2>Employers</h2></Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        <div className="employers-section-inner">
                                            <Row className="g-0">
                                                <Col md={12} lg={4}>
                                                    <h3>Sector</h3>
                                                    <ul>
                                                        <li><Link to='/information-technology'> Information Technology</Link></li>
                                                        <li><Link to='/real-estate'> Real Estate</Link></li>
                                                        <li><Link to='/hospitality'> Hospitality</Link></li>
                                                        <li><Link to='/legal-services'> Legal Services</Link></li>
                                                        <li><Link to='/data-management'> Data Management</Link></li>
                                                        <li><Link to='/telecommunications'> Telecommunications</Link></li>
                                                        <li><Link to='/healthcare'> Healthcare</Link></li>
                                                        <li><Link to='/transportation'> Transportation</Link></li>
                                                        <li><Link to='/utilities-and-energy'> Utilities and Energy </Link></li>
                                                        <li><Link to='/travel'> Travel</Link></li>
                                                        <li><Link to='/e-commerce'> e-Commerce</Link></li>
                                                        <li><Link to='/education'> Education</Link></li>
                                                        <li><Link to='/engineering'> Engineering </Link></li>
                                                        <li><Link to='/financial-services'>Financial Services </Link></li>
                                                        <li><Link to='/logistics'>Logistics </Link></li>
                                                    </ul>

                                                </Col>
                                                <Col md={12} lg={4}>
                                                    <h3>Role</h3>
                                                    <ul>
                                                        <li><Link to='/finance-and-accounting'>Finance & Accounting </Link></li>
                                                        <li><Link to='/customer-service'>Customer Service </Link></li>
                                                        <li><Link to='/lead-gen-and-sales'>Lead Gen & Sales </Link></li>
                                                        <li><Link to='/digital-marketing'>Digital Marketing </Link></li>
                                                        <li><Link to='/human-resources'>Human Resources </Link></li>
                                                        <li><Link to='/design-and-graphics'>Design & Graphics </Link></li>
                                                        <li><Link to='/software-development'>Software Development </Link></li>
                                                        <li><Link to='/virtual-assistance'>Virtual Assistance </Link></li>
                                                        <li><Link to='/engineer'>Engineering </Link></li>
                                                        <li><Link to='/medical'>Medical </Link></li>
                                                        <li><Link to='/data-science'>Data Science </Link></li>
                                                        <li><Link to='/technical-support'>Technical Support </Link></li>
                                                        <li><Link to='/project-management'>Project Management </Link></li>
                                                        <li><Link to='/c-executives'>C-Executives </Link></li>
                                                    </ul>
                                                </Col>
                                                <Col md={12} lg={4}>
                                                    <Row className="g-0">
                                                        <Col md={12}>
                                                            <h3>Company</h3>
                                                            <ul>
                                                                <li><Link to='/startup'>Startups </Link></li>
                                                                <li><Link to='/small-and-medium-sized-businesses'>Small & Medium Sized Businesses </Link></li>
                                                                <li><Link to='/enterprise'>Enterprise </Link></li>
                                                                <li><Link to='/high-growth'>High-Growth </Link></li>
                                                                <li><Link to='/cost-cutting'>Cost-Cutting </Link></li>
                                                                <li><Link to='/agency'>Agency </Link></li>
                                                                <li><Link to='/shared-services'>Shared Services </Link></li>
                                                                <li><Link to='/technology'>Technology </Link></li>
                                                                <li><Link to='/bootstrapped'>Bootstrapped </Link></li>
                                                            </ul>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        </div>
                        <div className="knowledge">
                            <Accordion.Item eventKey="2">
                                <Accordion.Header><h2>Talents</h2></Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        <li><Link to='/careers'>We’re Hiring </Link></li>
                                        <li><Link to='/hiring-process'>Hiring Process</Link></li>
                                        {/* <li><Link to='/gallery'>Gallery</Link></li> */}
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        </div>
                        <div className="knowledge">
                            <Accordion.Item eventKey="3">
                                <Accordion.Header><h2>Knowledge</h2></Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        {/* <li><Link to='/'>Webinars</Link></li>
                                        <li><Link to='/'>Templates</Link></li>
                                        <li><Link to='/'>E-Course</Link></li>
                                        <li><Link to='/'>E-Book</Link></li> */}
                                        <li><a href='https://www.remotephilippines.com/blog/'>Blog</a></li>
                                        <li><Link to='/faq'>FAQ</Link></li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        </div>
                        {/* <div className="knowledge">
                            <Accordion.Item eventKey="4">
                                <Accordion.Header><h2>Price guide</h2></Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        <li><Link to='/price-guide'>Price Guide</Link></li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        </div> */}
                    </Accordion>
                </div>
                <div className="footer-bottom">
                    <Container>
                        <Row>
                            <Col>
                                <div className="policy-link">
                                    <ul>
                                        <li><Link to='/legal?title=terms-of-service'>Terms of Service</Link></li>
                                        <li><Link to='/legal?title=privacy-policy'>Privacy Policy</Link></li>
                                        <li><Link to='/legal?title=cookie-policy'>Cookie Policy</Link></li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div>
                    <ScrollToTop smooth color="#fff" />
                    <MobileBottomFooter />
                </div>
            </div>
        </>
    );
}
export default MobileFooter;
