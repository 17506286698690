import Header from "../include/header";
import Footer from "../include/footer";
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Images from "../../../constant/images";
import CommonMeta from "../include/CommonMeta";
import MobileHeader from "../include/mobileHeader";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import GetRequestProposalForm from "../../getRequestProposalForm";
import MobileFooter from "../include/mobileFooter";

function EngagementProcess() {
  return (
    <div>
      <CommonMeta
        title='Engagement Process'
        description='Remote Philippines Dashboard | Engagement Process'
        keywords='Remote Philippines Dashboard | Engagement Process'
        name='Remote Philippines'
        type='article' />
      <div className='header2-wrapper'>
      {isDesktop || isTablet ? <Header /> : <MobileHeader />}
      </div>
      <div className='our-solution-finance-hero Engagement_background'>
        <Container>
          <Row className='our-solution-inner-section'>
            <Col md={6}>
              <div className='image-section'>
                <img  loading="lazy" src={Images.Engagement2} className='img-fluid' alt='' />
              </div>
            </Col>
            <Col md={6}>
              <div className='our-solution-details'>
                <h4>HIRE TOP TALENT</h4>
                <h3>Employ world-class talents, quickly and easily.</h3>
                <p>Remote Philippines offers top-notch offshore staffing solutions and recruits skilled Filipino talents with the highest standards. We support international businesses in hiring and growing their own offshore teams in the Philippines.</p>
                <div className='our-solution-details-btn'> <Link className='link-btn' to='/contact-us'>CONNECT WITH AN EXPERT</Link></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='our-benefits-wrapper'>
        <Container>
          <Row>
            <Col md={12}>
              <div className='benefits-heading'>
                <p>BENEFITS</p>
                <h4>Overcome the challenges of rising labor costs and get access to top talent.</h4>
              </div>
            </Col>
            <Col md={6}>
              <div className='our-benefits-services'>
                <div className='icon'><img  loading="lazy" src={Images.Registered} className='img-fluid' alt='' /></div>
                <div className='details'>
                  <h4>We are a local registered entity</h4>
                  <p>Scale your organization quickly, easily, and cost-effectively with a dedicated Filipino staff.</p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className='our-benefits-services'>
                <div className='icon'><img  loading="lazy" src={Images.benefitsPackage} className='img-fluid' alt='' /></div>
                <div className='details'>
                  <h4>Attract top talents with competitive benefits packages.</h4>
                  <p>Attract the best local candidates. Offer a market-leading compensation and benefits package.</p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className='our-benefits-services'>
                <div className='icon'>  <img  loading="lazy" src={Images.Compliance} className='img-fluid' alt='' /></div>
                <div className='details'>
                  <h4>We are responsible for local compliance.</h4>
                  <p>We take the complexity out of your hands and comply with local labor laws.</p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className='our-benefits-services'>
                <div className='icon'><img  loading="lazy" src={Images.Experts} className='img-fluid' alt='' /></div>
                <div className='details'>
                  <h4>Our talent acquisition experts are the best at their craft.</h4>
                  <p>We go out and uncover the best talent in the market and position your opportunity as the right move for them.</p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className='our-benefits-services'>
                <div className='icon'><img  loading="lazy" src={Images.teamSimple} className='img-fluid' alt='' /></div>
                <div className='details'>
                  <h4>We make scaling your team so simple and easy.</h4>
                  <p>We do this by committing to unparalleled service, strategy, and passion. Just keep hiring.</p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className='our-benefits-services'>
                <div className='icon'><img  loading="lazy" src={Images.payrollTaxes} className='img-fluid' alt='' /></div>
                <div className='details'>
                  <h4>Payroll and taxes made easy.</h4>
                  <p>You can rely on paying your staff on time and in the local currency.</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="how-it-work-wrapper">
        <Container>
          <Row>
            <Col md={12}>
              <div className="heading">
                <h4>HOW IT WORKS</h4>
                <h2>Attain maximum value potential with a skilled workforce.</h2>
              </div>
            </Col>
            <Col md={6}>
              <img  loading="lazy" src={Images.HowItWorks} className='img-fluid' alt='' />
            </Col>
            <Col md={5} className="offset-md-1">
              <div className="how-it-work-text">
                <div className="icon"><i className="fa-regular fa-circle-check"></i></div>
                <div className="text">
                  <h6><span>Step 1: </span> Sign our engagement agreement.</h6>
                  <p>Our engagement commences upon execution of our client services agreement.</p>
                </div>
              </div>
              <div className="how-it-work-text">
                <div className="icon"><i className="fa-regular fa-circle-check"></i></div>
                <div className="text">
                  <h6><span>Step 2: </span> Provide us with a position description.</h6>
                  <p>Share with us a brief description of the role, responsibilities, headcount, and target date to hire.</p>
                </div>
              </div>
              <div className="how-it-work-text">
                <div className="icon"><i className="fa-regular fa-circle-check"></i></div>
                <div className="text">
                  <h6><span>Step 3: </span> Our team commences with recruitment work.</h6>
                  <p>Our talent acquisition team begins sourcing candidates from our database and job marketplace.</p>
                </div>
              </div>
              <div className="how-it-work-text">
                <div className="icon"><i className="fa-regular fa-circle-check"></i></div>
                <div className="text">
                  <h6><span>Step 4: </span> Identify the candidate you wish to hire.</h6>
                  <p>This is the selection process, from technical assessments to interviews and background checks.</p>
                </div>
              </div>
              <div className="how-it-work-text">
                <div className="icon"><i className="fa-regular fa-circle-check"></i></div>
                <div className="text">
                  <h6><span>Step 5: </span> Submit a staff work order.</h6>
                  <p>You choose the candidate to hire, and we make a job offer.</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='get-started-form'>
        <Container>
          <Row>
            <Col className="col-12 col-lg-10 offset-lg-1">
              <div className='request-contact-form'>
                <h2 className='text-center'>Look no further. Get started with your outsourcing journey today!</h2>
                <p className='text-center'>Book a free session with one of Remote Philippines’s expert sales consultants. We'll get in touch with you to arrange a call, respond to any enquiries you might have, and begin onboarding your staff.</p>
                <GetRequestProposalForm />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {isMobile ? <MobileFooter /> : <Footer />}
    </div>
  );
}

export default EngagementProcess;
