import Header from "../include/header";
import Footer from "../include/footer";
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';

import { isDesktop, isMobile, isTablet } from 'react-device-detect';
import CommonMeta from '../include/CommonMeta';
import MobileHeader from '../include/mobileHeader';
import GetRequestProposalForm from '../../getRequestProposalForm';
import MobileFooter from "../include/mobileFooter";

import { useLocation } from "react-router-dom"
import { useEffect, useState } from "react"

function Legal() {
  const [activeTab, setActiveTab] = useState('terms-of-service')
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const title = queryParams.get("title")

  useEffect(() => {
    setActiveTab(title)

  }, [])

  return (
    <div id="go-top">
      <CommonMeta
        title='Legal'
        description='Remote Philippines Dashboard | Legal'
        keywords='Remote Philippines Dashboard | Legal'
        name='Remote Philippines'
        type='article' />
      {isDesktop || isTablet ? <Header /> : <MobileHeader />}
      <div className='global-banner-2 bg-sea-1 lagal-top-section'>
        <Container>
          <Row>
            <Col md={9}>
              <div className="recruitment-process-header-text">
                <div className="news-media-heading"> <h2>Legal </h2></div>
              </div>
            </Col>
            <Col md={3}>
              <div className="global-banner-2__gradient-overlay d-flex justify-content-end">
                <div className="gradient-bar gradient-primary-2 d-lg-block d-none"></div>
                <div className="gradient-bar gradient-primarys-1 d-lg-block d-none" ></div>
              </div>
            </Col>
            {/* <Col md={12}>
            <div className="news-media-heading"> <h2>News & Media</h2></div>
              <div className="news-media-wrapper">
                <Row>
                  <Col md={12}>
                    <div className="news-media-inner mb-5">
                      <div className="image-section">
                        <img loading="lazy" src={Images.ContactPageImage} alt='' className='img-fluid' />
                      </div>
                      <div className="image-desc">
                        <h3>AWARDS, PRESS RELEASES</h3>
                        <h2><Link to='/'> G-P Named Employer of Record Industry Leader in The IEC Group Global EOR Study 2023 </Link></h2>
                        <div><Link className='link' to='/request-proposal'>READ MORE <i className="fa-solid fa-arrow-right-long"></i></Link></div>
                      </div>
                    </div>
                  </Col>
                  <Col md={12} lg={6}>
                    <div className="news-media-inner">
                      <div className="image-section">
                        <img loading="lazy" src={Images.ContactPageImage} alt='' className='img-fluid' />
                      </div>
                      <div className="image-desc">
                        <h5>AWARDS, PRESS RELEASES</h5>
                        <h4><Link to='/'> G-P Wins 2023 ADP Marketplace Sales Choice Award </Link></h4>
                        <div><Link className='link' to='/request-proposal'>READ MORE <i className="fa-solid fa-arrow-right-long"></i></Link></div>
                      </div>
                    </div>
                  </Col>
                  <Col md={12} lg={6}>
                    <div className="news-media-inner">
                      <div className="image-section">
                        <img loading="lazy" src={Images.ContactPageImage} alt='' className='img-fluid' />
                      </div>
                      <div className="image-desc">
                        <h5>AWARDS, PRESS RELEASES</h5>
                        <h4><Link to='/'> G-P Sets a New Standard with G-P Meridian </Link></h4>
                        <div><Link className='link' to='/request-proposal'>READ MORE <i className="fa-solid fa-arrow-right-long"></i></Link></div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col> */}
          </Row>
        </Container>
      </div>
      <div className="legal-page-wrapper">
        <Row className="g-0">
          <Col>
            <Tabs defaultActiveKey={'terms-of-service'}
              activeKey={activeTab}
              onSelect={(k) => setActiveTab(k)}
              id="uncontrolled-tab-example" className="">
              <Tab eventKey="terms-of-service" title="Terms of Service">
                <Container>
                  <Row className="justify-content-center">
                    <div className="terms-of-service-wrapper col-md-10">
                      <h1>Terms of Service</h1>
                      <h2>Last Updated: May 2023</h2>
                      <p>These Terms of Service ("Terms") govern your use of RemotePhilippines.com Inc. ("we," "us," or "our") website located at <a href="www.remotephilippines.com">www.remotephilippines.com</a> (the "Website"). By accessing or using the Website, you agree to be bound by these Terms. If you do not agree to these Terms, please do not access or use the Website.</p>
                      <div className="heading">1.	Website Use:</div>
                      <ul>
                        <li>You may use the Website for lawful purposes and in compliance with these Terms. You are responsible for ensuring that your use of the Website does not violate any applicable laws or regulations.</li>
                        <li>You must not engage in any activity that could damage, disable, or impair the functionality or security of the Website or interfere with other users' access to the Website.</li>
                      </ul>
                      <div className="heading">2.	Intellectual Property:</div>
                      <ul>
                        <li>The content, trademarks, logos, and other intellectual property displayed on the Website are owned by or licensed to us. You may not use, modify, reproduce, distribute, or exploit any of the intellectual property without our prior written consent.</li>
                      </ul>
                      <div className="heading">3.	Third-Party Links:</div>
                      <ul>
                        <li>The Website may contain links to third-party websites or resources. We do not endorse or control these third-party websites and are not responsible for their content or any damages or losses incurred as a result of your use of these websites.</li>
                      </ul>
                      <div className="heading">4.	Confidentiality:</div>
                      <ul>
                        <li>Any information or materials provided by you through the Website will be treated as confidential in accordance with our Privacy Policy. However, we do not guarantee the confidentiality or security of any information or materials transmitted through the Website.</li>
                      </ul>
                      <div className="heading">5.	Disclaimer of Warranties:</div>
                      <ul>
                        <li>The Website is provided on an "as is" and "as available" basis. We make no representations or warranties of any kind, whether express or implied, regarding the accuracy, reliability, or availability of the Website.</li>
                        <li>We do not guarantee that the Website will be error-free, uninterrupted, or free from viruses or other harmful components. You acknowledge and agree that your use of the Website is at your own risk.</li>
                      </ul>
                      <div className="heading">6.	Limitation of Liability:</div>
                      <ul>
                        <li>To the maximum extent permitted by applicable law, we shall not be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in connection with your use of the Website, including but not limited to damages for lost profits, business interruption, or loss of data.</li>
                        <li>Our total liability, whether in contract, tort, or otherwise, shall not exceed the amount paid by you, if any, for accessing the Website.</li>
                      </ul>
                      <div className="heading">7.	Indemnification:</div>
                      <ul>
                        <li>You agree to indemnify and hold us harmless from any claims, damages, losses, liabilities, and expenses (including attorneys' fees) arising out of or in connection with your use of the Website or any violation of these Terms.</li>
                      </ul>
                      <div className="heading">8.	Modifications to the Terms:</div>
                      <ul>
                        <li>We reserve the right to modify or update these Terms at any time. Any changes to the Terms will be effective upon posting the revised version on the Website. Your continued use of the Website after the changes are posted signifies your acceptance of the modified Terms.</li>
                      </ul>
                      <div className="heading">9.	Termination:</div>
                      <ul>
                        <li>We may terminate or suspend your access to the Website, with or without cause, at any time and without prior notice.</li>
                      </ul>
                      <div className="heading">10. Governing Law:</div>
                      <ul>
                        <li>These Terms shall be governed by and construed in accordance with the laws of Taguig City, Philippines. Any disputes arising out of or relating to these Terms or the Website shall be subject to the exclusive jurisdiction of the courts located in Taguig City, Philippines.</li>
                      </ul>
                      <h3>If you have any questions or concerns regarding these Terms, please contact us at <a href="mailto:hello@remotephilippines.com.">hello@remotephilippines.com.</a></h3>
                      <p>By accessing or using the Website, you acknowledge that you have read, understood, and agreed to these Terms of Service.</p>
                    </div>
                  </Row>
                </Container>
              </Tab>
              <Tab eventKey="privacy-policy" title="Privacy Policy">
                <Container>
                  <Row className="justify-content-center">
                    <div className="terms-of-service-wrapper col-md-10">
                      <h1>Privacy Policy</h1>
                      <h2>Last Updated: May 2023</h2>
                      <p>This Privacy Policy describes how RemotePhilippines.com Inc. ("we," "us," or "our") collects, uses, and protects the personal information obtained from users ("you" or "your") of our website <a href="www.remotephilippines.com">www.remotephilippines.com</a> (the "Website"). We are committed to ensuring the privacy and security of your personal information. By using our Website, you consent to the collection and use of your personal information as described in this Privacy Policy.</p>
                      <div className="heading">1.	Information We Collect:</div>
                      <ul>
                        <li><strong> Personal Information:</strong> When you visit our Website or interact with our services, we may collect personal information that you voluntarily provide to us, such as your name, email address, phone number, and other contact details.</li>
                        <li><strong>Log Data:</strong> We automatically collect certain information when you access our Website, including your IP address, browser type, operating system, referring URLs, and pages visited on our Website.</li>
                        <li><strong>Cookies:</strong> Our Website uses cookies and similar technologies to enhance your browsing experience and collect information about your usage patterns. You can control the use of cookies through your browser settings.</li>
                      </ul>
                      <div className="heading">2.	Use of Information:</div>
                      <ul>
                        <li>We may use the personal information we collect for the following purposes:</li>
                        <li>To provide and improve our services, respond to inquiries, and fulfill your requests.</li>
                        <li>To personalize your experience on our Website and tailor our content and offerings to your preferences.</li>
                        <li>To send you important updates, newsletters, and promotional materials related to our services. You can opt out of receiving such communications at any time.</li>
                        <li>To analyze and monitor the usage of our Website, perform data analytics, and enhance our Website's functionality and security.</li>
                      </ul>
                      <div className="heading">3.	Data Sharing and Disclosure:</div>
                      <ul>
                        <li>We may share your personal information with trusted third-party service providers who assist us in operating our business, managing our Website, or providing services to you.</li>
                        <li>We may also share your information when required by law, to protect our rights, comply with legal processes, or respond to legal requests.</li>
                        <li>We will not sell, rent, or lease your personal information to third parties for their marketing purposes.</li>
                      </ul>
                      <div className="heading">4.	Data Security:</div>
                      <ul>
                        <li>We implement reasonable security measures to protect your personal information from unauthorized access, use, or disclosure.</li>
                        <li>Despite our efforts, please note that no security system is impenetrable, and we cannot guarantee the absolute security of your personal information.</li>
                      </ul>
                      <div className="heading">5.	Third-Party Links:</div>
                      <ul>
                        <li>Our Website may contain links to third-party websites or services. This Privacy Policy does not apply to those websites. We recommend reviewing the privacy policies of those third-party websites before providing any personal information.</li>
                      </ul>
                      <div className="heading">6.	Children's Privacy:</div>
                      <ul>
                        <li>Our Website is not intended for individuals under the age of 18. We do not knowingly collect personal information from children. If you believe we have inadvertently collected information from a child, please contact us immediately.</li>
                      </ul>
                      <div className="heading">7.	Changes to this Privacy Policy:</div>
                      <ul>
                        <li>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated Privacy Policy on our Website. Your continued use of the Website after the changes are made will signify your acceptance of the updated Privacy Policy.</li>
                      </ul>
                      <div className="heading">8.	Contact Us:</div>
                      <ul>
                        <li>If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at <a href="mailto:hello@remotephilippines.com.">hello@remotephilippines.com.</a></li>
                      </ul>
                      <p>By using our Website, you acknowledge that you have read, understood, and agreed to this Privacy Policy.</p>
                    </div>
                  </Row>
                </Container>
              </Tab>
              <Tab eventKey="cookie-policy" title="Cookie Policy">
                <Container>
                  <Row className="justify-content-center">
                    <div className="terms-of-service-wrapper col-md-10">
                      <h1>Cookie Policy</h1>
                      <h2>Last Updated: May 2023</h2>
                      <p>This Cookie Policy explains how RemotePhilippines.com Inc. ("we," "us," or "our") uses cookies and similar tracking technologies on our website <a href="www.remotephilippines.com">www.remotephilippines.com</a> (the "Website"). By using our Website, you consent to the use of cookies as described in this Cookie Policy.</p>
                      <div className="heading">1. What are Cookies:</div>
                      <ul>
                        <li>Cookies are small text files that are placed on your device (computer, mobile phone, or tablet) when you visit our Website. They help us enhance your browsing experience and provide certain functionalities on our Website.</li>
                      </ul>
                      <div className="heading">2.	Types of Cookies We Use:</div>
                      <ul>
                        <li>Necessary Cookies: These cookies are essential for the proper functioning of our Website. They enable basic features such as page navigation, access to secure areas, and user authentication.</li>
                        <li>Performance and Analytics Cookies: These cookies collect information about how visitors use our Website, such as which pages are visited most frequently and any error messages encountered. They help us analyze and improve the performance of our Website.</li>
                        <li>Functionality Cookies: These cookies allow our Website to remember choices you make (such as your preferred language or region) and provide enhanced features. They enable us to personalize your browsing experience.</li>
                        <li>Targeting and Advertising Cookies: These cookies are used to deliver relevant advertisements to you based on your interests. They may also be used to limit the number of times you see an ad and measure the effectiveness of advertising campaigns.</li>
                      </ul>
                      <div className="heading">3.	Third-Party Cookies:</div>
                      <ul>
                        <li>We may also allow third-party service providers, such as analytics or advertising companies, to place cookies on our Website. These third parties may use cookies to collect information about your online activities across different websites and services.</li>
                      </ul>
                      <div className="heading">4. Your Cookie Choices:</div>
                      <ul>
                        <li>You can manage your cookie preferences by adjusting the settings in your web browser. Most web browsers allow you to delete or block cookies and provide options to control how cookies are stored and accessed.</li>
                        <li>Please note that blocking or disabling certain cookies may impact the functionality and performance of our Website or limit your access to certain features.</li>
                      </ul>
                      <div className="heading">5. Data Retention:</div>
                      <ul>
                        <li>We retain cookie data for the duration necessary to fulfill the purposes outlined in this Cookie Policy, unless a longer retention period is required or permitted by law.</li>
                      </ul>
                      <div className="heading">6. Updates to this Cookie Policy:</div>
                      <ul>
                        <li>We may update this Cookie Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated Cookie Policy on our Website. Your continued use of the Website after the changes are made will signify your acceptance of the updated Cookie Policy.</li>
                      </ul>
                      <div className="heading">7.	Contact Us:</div>
                      <ul>
                        <li>If you have any questions, concerns, or requests regarding this Cookie Policy, please contact us at <a href="mailto:hello@remotephilippines.com.">hello@remotephilippines.com.</a> </li>
                      </ul>
                      <p>By using our Website, you acknowledge that you have read, understood, and agreed to this Cookie Policy.</p>
                    </div>
                  </Row>
                </Container>
              </Tab>
            </Tabs>
          </Col>
        </Row>

      </div>
      <div className='get-started-form'>
        <Container>
          <Row>
            <Col className="col-12 col-lg-10 offset-lg-1">
              <div className='request-contact-form'>
                <h2 className='text-center'>Look no further. Get started with your outsourcing journey today!</h2>
                <p className='text-center'>Book a free session with one of Remote Philippines’s expert sales consultants. We'll get in touch with you to arrange a call, respond to any enquiries you might have, and begin onboarding your staff.</p>
                <GetRequestProposalForm />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {isMobile ? <MobileFooter /> : <Footer />}
    </div >
  );
}

export default Legal;
