import React, { useEffect, useState } from 'react';
import Header from "../include/header";
import Footer from "../include/footer";
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Images from '../../../constant/images';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Partners from '../../Partners';
import { isDesktop, isMobile, isTablet } from 'react-device-detect';
import PartnersSlider from '../../PartnersSlider';
import CommonMeta from '../include/CommonMeta';
import MobileHeader from '../include/mobileHeader';
import GetRequestProposalForm from '../../getRequestProposalForm';
import MobileFooter from "../include/mobileFooter";
function HiringProcess() {

  const YOUTUBE_PLAYLIST_ITEMS_API = 'https://www.googleapis.com/youtube/v3/playlistItems';
  const [youTubedata, setyouTubedata] = useState([]);
  const getYoutubePlayList = async () => {
    const res = await fetch(`${YOUTUBE_PLAYLIST_ITEMS_API}?part=snippet&playlistId=PLeTyYUgffKJBZJR01jg0G0ta6Ta1jKEEr&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`);
    const data = await res.json();
    setyouTubedata(data.items)
  }

  useEffect(() => {
    getYoutubePlayList();
  }, [])

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    autoplay: false,
    slidesToScroll: 1,
    speed: 1000,
    centerMode: true,
    centerPadding: '150px',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScrol1: 1,
        },
      },
      {
        breakpoint: 1008,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: '0px',
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: '0px',
        },
      },
    ],
  };

  return (
    <div id="go-top">
      <CommonMeta
        title='Hiring Process'
        description='Remote Philippines Dashboard | Hiring Process'
        keywords='Remote Philippines Dashboard | Hiring Process'
        name='Remote Philippines'
        type='article' />
      {isDesktop || isTablet ? <Header /> : <MobileHeader />}
      <div className='global-banner-2 bg-sea-1'>
        <Container>
          <Row>
            <Col md={10}>
              <div className="recruitment-process-header-text">
                <h1 className="">Beyond the job title and responsibilities, <br />lies a person with aspirations, <br /> passions, and a unique story.</h1>
                <p className="">We offer a supportive and connected experience while giving every Filipino access to global employment opportunities.</p>
                <div className='recruitment-process-btn'>
                  <Link className='link-btn2' to='https://www.youtube.com/@remotephilippines'>WATCH OUR VIDEOS</Link>
                  <Link className='link-btn' to='/request-proposal'> REQUEST A PROPOSAL</Link>
                </div>
              </div>
            </Col>
            <Col md={2}>
              <div className="global-banner-2__gradient-overlay d-flex justify-content-end">
                <div className="gradient-bar gradient-primary-2 d-lg-block d-none"></div>
                <div className="gradient-bar gradient-primarys-1 d-lg-block d-none" ></div>
                {/* <div className="gradient-bar gradient-secondary-1 d-lg-block d-none" ></div>
                <div className="gradient-bar gradient-secondary-5 d-lg-block d-none" ></div>
                <div className="gradient-bar gradient-secondary-8"></div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='recruitment-process-wrapper-services text-center text-lg-start pt-lg-9 pb-lg-6 pb-0 py-6'>
        <Container fluid className='p-0'>
          <Row className='mb-lg-9 col-reverse-wrapper'>
            <Col md={12} lg={6} className="align-self-center mb-5 mb-lg-0">
              <div className='recruitment-process-inner-services ms-xl-6 ps-lg-5 first position-relative'>
                {/* <div className='gradient-bar gradient-primary-1'></div> */}
                <div className='number-section'>1</div>
                <h2>Recruit the position.</h2>
                <p>Beyond just posting jobs, our talent acquisition staff directly contacts qualified candidates via LinkedIn, social media, and employment groups. Active recruitment will assist in generating applications from possible applicants who may be ideal for the open position but are not actively looking for new employment.</p>
                {/* <div><Link className='link-btn mt-3' to='/'> LEARN MORE</Link></div> */}
              </div>
            </Col>
            <Col md={12} lg={6} className="mb-3 mb-lg-0 p-0">
              <div className='recruitment-process-inner-services-img'>
                <img  loading="lazy" src={Images.Recruit_Position} alt='' className='img-fluid' />
              </div>
            </Col>
          </Row>
          <Row className='mb-lg-9'>
            <Col md={12} lg={6} className="mb-3 mb-lg-0 p-0">
              <div className='recruitment-process-inner-services-img'>
                <img loading="lazy" src={Images.Review_Applications} alt='' className='img-fluid' />
              </div>
            </Col>
            <Col md={12} lg={6} className="align-self-center mb-5 mb-lg-0">
              <div className='recruitment-process-inner-services2 ms-xl-6 ps-lg-5 first position-relative'>
                {/* <div className='gradient-bar2 gradient-primary-2'></div> */}
                <div className='number-section'>2</div>
                <h2>Review applications.</h2>
                <p>Our review process begins with our talent specialist reviewing the applications and eliminating any candidate who does not meet the minimum requirements for the position or the company more generally. Once a batch of qualified applications are put together, our talent specialist notifies the candidates and schedules them for an initial interview.</p>
                {/* <div><Link className='link-btn mt-3' to='/'> LEARN MORE</Link></div> */}
              </div>
            </Col>
          </Row>
          <Row className='mb-lg-9 col-reverse-wrapper'>
            <Col md={12} lg={6} className="align-self-center mb-5 mb-lg-0">
              <div className='recruitment-process-inner-services ms-xl-6 ps-lg-5 first position-relative'>
                {/* <div className='gradient-bar gradient-primary-3'></div> */}
                <div className='number-section'>3</div>
                <h2>Candidate screening.</h2>
                <p>We usually start first interviews with a video call with one of our talent specialists. This aids us in determining whether candidates meet the requirements to fill the position and are compatible with the culture and values of the company.</p>
                {/* <div><Link className='link-btn mt-3' to='/'> LEARN MORE</Link></div> */}
              </div>
            </Col>
            <Col md={12} lg={6} className="mb-3 mb-lg-0 p-0">
              <div className='recruitment-process-inner-services-img'>
                <img  loading="lazy" src={Images.Candidate_Screening} alt='' className='img-fluid' />
              </div>
            </Col>
          </Row>
          <Row className='mb-lg-9'>
            <Col md={12} lg={6} className="mb-3 mb-lg-0 p-0">
              <div className='recruitment-process-inner-services-img'>
                <img  loading="lazy" src={Images.Technical_Assessment} alt='' className='img-fluid' />
              </div>
            </Col>
            <Col md={12} lg={6} className="align-self-center mb-5 mb-lg-0">
              <div className='recruitment-process-inner-services2 ms-xl-6 ps-lg-5 first position-relative'>
                {/* <div className='gradient-bar2 gradient-secondary-3'></div> */}
                <div className='number-section'>4</div>
                <h2>Technical assessment.</h2>
                <p>Companies frequently give candidates one or more standardized exams after or during the interview process. These exams measure a wide range of factors, technical acumen, problem-solving abilities, reasoning, reading comprehension, emotional intelligence, and more.</p>
                {/* <div><Link className='link-btn mt-3' to='/'> LEARN MORE</Link></div> */}
              </div>
            </Col>
          </Row>
          <Row className='mb-lg-9 col-reverse-wrapper'>
            <Col md={12} lg={6} className="align-self-center mb-5 mb-lg-0">
              <div className='recruitment-process-inner-services ms-xl-6 ps-lg-5 first position-relative'>
                {/* <div className='gradient-bar gradient-secondary-5'></div> */}
                <div className='number-section'>5</div>
                <h2>Client interview.</h2>
                <p>Final interviews often include conversations with our client’s top leadership or executive on a more in-depth discussion of the role and your potential suitability to their requirement. Only a relatively limited number of the best candidates are usually invited for final interviews.</p>
                {/* <div><Link className='link-btn mt-3' to='/'> LEARN MORE</Link></div> */}
              </div>
            </Col>
            <Col md={12} lg={6} className="mb-3 mb-lg-0 p-0">
              <div className='recruitment-process-inner-services-img'>
                <img  loading="lazy" src={Images.Client_Interview} alt='' className='img-fluid' />
              </div>
            </Col>
          </Row>
          <Row className='mb-lg-9'>
            <Col md={12} lg={6} className="mb-3 mb-lg-0 p-0">
              <div className='recruitment-process-inner-services-img'>
                <img  loading="lazy" src={Images.Decision} alt='' className='img-fluid' />
              </div>
            </Col>
            <Col md={12} lg={6} className="align-self-center mb-5 mb-lg-0">
              <div className='recruitment-process-inner-services2 ms-xl-6 ps-lg-5 first position-relative'>
                {/* <div className='gradient-bar2 gradient-secondary-6'></div> */}
                <div className='number-section'>6</div>
                <h2>Decision.</h2>
                <p>Upon completion of all candidate interviews with our client, this is the step whereby our talent specialist requests feedback from our client and the client makes a decision on which candidate to make an offer.</p>
                {/* <div><Link className='link-btn mt-3' to='/'> LEARN MORE</Link></div> */}
              </div>
            </Col>
          </Row>
          <Row className='mb-lg-9 col-reverse-wrapper'>
            <Col md={12} lg={6} className="align-self-center mb-5 mb-lg-0">
              <div className='recruitment-process-inner-services ms-xl-6 ps-lg-5 first position-relative'>
                {/* <div className='gradient-bar gradient-secondary-8'></div> */}
                <div className='number-section'>7</div>
                <h2>Background check.</h2>
                <p>Background checks examine applicants' criminal histories, confirm work eligibility, history, and reference checks. Any relevant information provided by the candidate about prior employment, such as job performance, experience, responsibilities, workplace behavior, etc., should be confirmed by reference checks.</p>
                {/* <div><Link className='link-btn mt-3' to='/'> LEARN MORE</Link></div> */}
              </div>
            </Col>
            <Col md={12} lg={6} className="mb-3 mb-lg-0 p-0">
              <div className='recruitment-process-inner-services-img'>
                <img  loading="lazy" src={Images.Background_check} alt='' className='img-fluid' />
              </div>
            </Col>
          </Row>
          <Row className='mb-lg-9'>
            <Col md={12} lg={6} className="mb-3 mb-lg-0 p-0">
              <div className='recruitment-process-inner-services-img'>
                <img  loading="lazy" src={Images.Send_Offer} alt='' className='img-fluid' />
              </div>
            </Col>
            <Col md={12} lg={6} className="align-self-center mb-5 mb-lg-0">
              <div className='recruitment-process-inner-services2 ms-xl-6 ps-lg-5 first position-relative'>
                {/* <div className='gradient-bar2 gradient-secondary-11'></div> */}
                <div className='number-section'>8</div>
                <h2>Send an offer.</h2>
                <p>This is the part where we send you a congratulatory email and inform you of the job offer. The salary, benefits, paid time off, start date, policy on working remotely, provided corporate equipment, and other terms and circumstances of employment should all be stated in the offer letter.</p>
                {/* <div><Link className='link-btn mt-3' to='/'> LEARN MORE</Link></div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="trusted-global-partner pt-0">
        <Container>
          <Row>
            <Col md={12}>
              <div className="trusted-global-partner-box">
                <div className="headings">A trusted global partner in providing premium <br /> talent - <span className="gradient-text">ready to join your team.</span></div>
                <p className="text-center">Here are a few of the cutting-edge tools in which our team members excel.</p>
                {isMobile ? <PartnersSlider /> : <Partners />}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="outsourcing-any-role-section">
        <Container>
          <Row>
            <Col md={12}>
              <div className="global-banner-2__gradient-overlay d-flex justify-content-end">
                <div className="gradient-bar gradient-primary-2 d-lg-block" ></div>
                <div className="gradient-bar gradient-primarys-1 d-lg-block"></div>
                {/* <div className="gradient-bar gradient-secondary-1 d-lg-block d-none" ></div>
                <div className="gradient-bar gradient-secondary-5 d-lg-block d-none" ></div>
                <div className="gradient-bar gradient-secondary-8"></div> */}
              </div>
            </Col>
            <Col>
              <div className='outsourcing-any-role-slider'>
                <h4 className='success-story-text'>Job opening updates</h4>
                <Slider {...settings}>

                  {
                    youTubedata.map((item, i) => {
                      const { snippet = {} } = item;
                      const { title, thumbnails = {}, resourceId } = snippet;
                      const { standard = {} } = thumbnails;
                      return (
                        <div key={i}>
                          <div className="customer-box">
                            <div className="customer-image yuotube-list">
                              <iframe width={"100%"} height={"440px"} src={`https://www.youtube.com/embed/${resourceId.videoId}`} title={title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                            </div>
                          </div>
                        </div>
                      );
                    })
                  }
                  {/* <div>
                    <div className="video-testimonials card-sky-1">
                      <div className='logo-icon'>
                        <img  loading="lazy" src={Images.zoom} alt='' className='img-fluid' />
                      </div>
                      <p> Zoom wants to be everywhere its customers are. G‑P has played a critical role in making that happen. The company is entering a number of markets quickly by relying on G‑P's agility and speed, providing Zoom the support and flexibility required to be successful.</p>
                    </div>
                  </div>
                  <div>
                    <div className="video-testimonials card-magenta">
                      <div className='logo-icon'>
                        <img  loading="lazy" src={Images.neurala} alt='' className='img-fluid' />
                      </div>
                      <p> Zoom wants to be everywhere its customers are. G‑P has played a critical role in making that happen. The company is entering a number of markets quickly by relying on G‑P's agility and speed, providing Zoom the support and flexibility required to be successful.</p>
                    </div>
                  </div>
                  <div>
                    <div className="video-testimonials card-purple">
                      <div className='logo-icon'>
                        <img  loading="lazy" src={Images.sonicWall} alt='' className='img-fluid' />
                      </div>
                      <p> Zoom wants to be everywhere its customers are. G‑P has played a critical role in making that happen. The company is entering a number of markets quickly by relying on G‑P's agility and speed, providing Zoom the support and flexibility required to be successful.</p>
                    </div>
                  </div>
                  <div>
                    <div className="video-testimonials card-sky-1">
                      <div className='logo-icon'>
                        <img  loading="lazy" src={Images.zoom} alt='' className='img-fluid' />
                      </div>
                      <p> Zoom wants to be everywhere its customers are. G‑P has played a critical role in making that happen. The company is entering a number of markets quickly by relying on G‑P's agility and speed, providing Zoom the support and flexibility required to be successful.</p>
                    </div>
                  </div>
                  <div>
                    <div className="video-testimonials card-magenta">
                      <div className='logo-icon'>
                        <img  loading="lazy" src={Images.neurala} alt='' className='img-fluid' />
                      </div>
                      <p> Zoom wants to be everywhere its customers are. G‑P has played a critical role in making that happen. The company is entering a number of markets quickly by relying on G‑P's agility and speed, providing Zoom the support and flexibility required to be successful.</p>
                    </div>
                  </div>
                  <div>
                    <div className="video-testimonials card-purple">
                      <div className='logo-icon'>
                        <img  loading="lazy" src={Images.sonicWall} alt='' className='img-fluid' />
                      </div>
                      <p> Zoom wants to be everywhere its customers are. G‑P has played a critical role in making that happen. The company is entering a number of markets quickly by relying on G‑P's agility and speed, providing Zoom the support and flexibility required to be successful.</p>
                    </div>
                  </div> */}
                </Slider>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='get-started-form'>
        <Container>
          <Row>
            <Col className="col-12 col-lg-10 offset-lg-1">
              <div className='request-contact-form'>
                <h2 className='text-center'>Look no further. Get started with your outsourcing journey today!</h2>
                <p className='text-center'>Book a free session with one of Remote Philippines’s expert sales consultants. We'll get in touch with you to arrange a call, respond to any enquiries you might have, and begin onboarding your staff.</p>
                <GetRequestProposalForm />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {isMobile ? <MobileFooter /> : <Footer />}
    </div >
  );
}

export default HiringProcess;
