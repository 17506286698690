import { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Images from "../../../../constant/images";

function OurServicesPriceTableMobile() {

    var OurServicesPriceTable = {
        dots: false,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        autoplay: false,
        slidesToScroll: 1,
        speed: 1000,
    };

    return (
        <Container>
            <div className="position-relative our-services-price-table">
                <Slider {...OurServicesPriceTable}>
                    <div className="position-sticky border-section-mobile">
                        <div className="pricing-table-header">
                            <div className="pricing-table-cell">
                                <img loading="lazy" width={'50px'} src={Images.RemoteStaff} alt='' className='img-fluid' />
                                <h3>Remote Staff</h3>
                                <div className='my-4 text-center'> <a className='link-btn' href='#RemoteStaff'>LEARN MORE</a></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Staff Recruitment</p>
                                <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Background Checks</p>
                                <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Staff Employment</p>
                                <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Onboarding of Staff</p>
                                <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Payroll Management</p>
                                <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Benefits Management</p>
                                <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Tax Duties</p>
                                <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Staff HR Support</p>
                                <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Employment Compliance</p>
                                <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Offboarding of Staff</p>
                                <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Customer Support</p>
                                <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Shared Office Space</p>
                                <div className="pricing-table-cell-col"><i className="line"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Premium Office Facilities</p>
                                <div className="pricing-table-cell-col"><i className="line"></i></div>
                            </div>
                        </div>
                    </div>
                    <div className="position-sticky">
                        <div className="pricing-table-header">
                            <div className="pricing-table-cell">
                                <img loading="lazy" width={'50px'} src={Images.OfficeBasedStaff} alt='' className='img-fluid' />
                                <h3>Office-based Staff</h3>
                                <div className='my-4 text-center'> <a className='link-btn' href='#OfficeBasedStaff'>LEARN MORE</a></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Staff Recruitment</p>
                                <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Background Checks</p>
                                <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Staff Employment</p>
                                <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Onboarding of Staff</p>
                                <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Payroll Management</p>
                                <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Benefits Management</p>
                                <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Tax Duties</p>
                                <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Staff HR Support</p>
                                <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Employment Compliance</p>
                                <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Offboarding of Staff</p>
                                <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Customer Support</p>
                                <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Shared Office Space</p>
                                <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Premium Office Facilities</p>
                                <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
                            </div>
                        </div>
                    </div>
                    <div className="position-sticky">
                        <div className="pricing-table-header">
                            <div className="pricing-table-cell">
                                <img loading="lazy" width={'50px'} src={Images.ExecutiveSearch} alt='' className='img-fluid' />
                                <h3>Executive Search</h3>
                                <div className='my-4 text-center'> <a className='link-btn' href='#ExecutiveSearch'>LEARN MORE</a></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Staff Recruitment</p>
                                <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Background Checks</p>
                                <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Staff Employment</p>
                                <div className="pricing-table-cell-col"><i className="line"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Onboarding of Staff</p>
                                <div className="pricing-table-cell-col"><i className="line"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Payroll Management</p>
                                <div className="pricing-table-cell-col"><i className="line"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Benefits Management</p>
                                <div className="pricing-table-cell-col"><i className="line"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Tax Duties</p>
                                <div className="pricing-table-cell-col"><i className="line"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Staff HR Support</p>
                                <div className="pricing-table-cell-col"><i className="line"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Employment Compliance</p>
                                <div className="pricing-table-cell-col"><i className="line"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Offboarding of Staff</p>
                                <div className="pricing-table-cell-col"><i className="line"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Customer Support</p>
                                <div className="pricing-table-cell-col"><i className="line"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Shared Office Space</p>
                                <div className="pricing-table-cell-col"><i className="line"></i></div>
                            </div>
                            <div className="pricing-table-row">
                                <p>Premium Office Facilities</p>
                                <div className="pricing-table-cell-col"><i className="line"></i></div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        </Container >
    );
}

export default OurServicesPriceTableMobile;