import { useState } from "react";
import Images from "../constant/images";

function Partners() {
    const [visible, setVisible] = useState(false);

    return (
        <div className="logo-section">
            <div className="logo-section-box1">
                {/* <div className="logo-image"> <img  loading="lazy" src={Images.laravel} className='img-fluid' alt='' /></div>
                <div className="logo-image"> <img  loading="lazy" src={Images.codeigniter} className='img-fluid' alt='' /></div>
                <div className="logo-image"> <img  loading="lazy" src={Images.php} className='img-fluid' alt='' /></div>
                <div className="logo-image"> <img  loading="lazy" src={Images.yii} className='img-fluid' alt='' /></div>
                <div className="logo-image"> <img  loading="lazy" src={Images.zend} className='img-fluid' alt='' /></div>
                <div className="logo-image"> <img  loading="lazy" src={Images.drupal} className='img-fluid' alt='' /></div>
                <div className="logo-image"> <img  loading="lazy" src={Images.magento} className='img-fluid' alt='' /></div>
                <div className="logo-image"> <img  loading="lazy" src={Images.joomla} className='img-fluid' alt='' /></div> */}
                <div className="logo-image"> <img  loading="lazy" src={Images.logo1} className='img-fluid' alt='' /></div>
                <div className="logo-image"> <img  loading="lazy" src={Images.logo2} className='img-fluid' alt='' /></div>
                <div className="logo-image"> <img  loading="lazy" src={Images.logo3} className='img-fluid' alt='' /></div>
                <div className="logo-image"> <img  loading="lazy" src={Images.logo4} className='img-fluid' alt='' /></div>
                <div className="logo-image"> <img  loading="lazy" src={Images.logo5} className='img-fluid' alt='' /></div>
                <div className="logo-image"> <img  loading="lazy" src={Images.logo6} className='img-fluid' alt='' /></div>
                <div className="logo-image"> <img  loading="lazy" src={Images.logo7} className='img-fluid' alt='' /></div>
                <div className="logo-image"> <img  loading="lazy" src={Images.logo8} className='img-fluid' alt='' /></div>
                <div className="logo-image"> <img  loading="lazy" src={Images.logo9} className='img-fluid' alt='' /></div>
                <div className="logo-image"> <img  loading="lazy" src={Images.logo10} className='img-fluid' alt='' /></div>
                <div className="logo-image"> <img  loading="lazy" src={Images.logo11} className='img-fluid' alt='' /></div>
                <div className="logo-image"> <img  loading="lazy" src={Images.logo12} className='img-fluid' alt='' /></div>
                <div className="logo-image"> <img  loading="lazy" src={Images.logo17} className='img-fluid' alt='' /></div>
                <div className="logo-image"> <img  loading="lazy" src={Images.logo18} className='img-fluid' alt='' /></div>
                <div className="logo-image"> <img  loading="lazy" src={Images.logo21} className='img-fluid' alt='' /></div>
                <div className="logo-image"> <img  loading="lazy" src={Images.logo22} className='img-fluid' alt='' /></div>
                <div className="logo-image"> <img  loading="lazy" src={Images.logo23} className='img-fluid' alt='' /></div>
                <div className="logo-image"> <img  loading="lazy" src={Images.logo25} className='img-fluid' alt='' /></div>

            </div>
            <div className="logo-section-box2">
                {visible &&
                    <>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo24} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo26} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo27} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo28} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo29} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo30} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo31} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo32} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo33} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo34} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo35} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo36} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo37} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo38} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo39} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo40} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo41} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo13} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo14} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo15} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo16} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.php} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.wordpress} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.java} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.sql} className='img-fluid' alt='' /></div>
                        {/* <div className="logo-image"> <img  loading="lazy" src={Images.logo42} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo43} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo44} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo45} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo46} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo47} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo48} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo49} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo50} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo51} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo52} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo53} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo54} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo55} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo56} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo57} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo58} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo59} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo60} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo61} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo62} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo63} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo64} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo65} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo66} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo67} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo68} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo69} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo70} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo71} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo72} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo73} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo74} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo75} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo76} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo77} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo78} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo79} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo80} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo81} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo82} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo83} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo84} className='img-fluid' alt='' /></div>
                        <div className="logo-image"> <img  loading="lazy" src={Images.logo85} className='img-fluid' alt='' /></div> */}
                    </>
                }
            </div>
            <div className='logo-section-button'>
                <button onClick={() => setVisible(!visible)}>{visible ?
                    (
                        <>
                            Collapse <img  loading="lazy" width={'18px'} src={Images.downArrow1} className="img-fluid collapse-img" alt='downArrow' />
                        </>
                    ) : (
                        <>
                            More <img  loading="lazy" width={'18px'} src={Images.downArrow1} className="img-fluid" alt='downArrow' />
                        </>
                    )}
                </button>
            </div>
        </div>
    );
}

export default Partners;