import Header from "./include/header";
import Footer from "./include/footer";
import { Col, Container, Row } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isDesktop, isMobile, isTablet } from 'react-device-detect';
import CommonMeta from './include/CommonMeta';
import MobileHeader from './include/mobileHeader';
import GetRequestProposalForm from '../getRequestProposalForm';
import MobileFooter from "./include/mobileFooter";
import PriceGuideTableMobile from "../priceGuideTableMobile";
import PriceGuideTable from "../priceGuideTable";

function PriceGuide() {

  return (
    <div id="go-top">
      <CommonMeta
        title='Price Guide'
        description='Remote Philippines Dashboard | Price Guide'
        keywords='Remote Philippines Dashboard | Price Guide'
        name='Remote Philippines'
        type='article' />
      {isDesktop || isTablet ? <Header /> : <MobileHeader />}
      <div className='global-banner-2 bg-sea-1'>
        <Container>
          <Row>
            <Col md={12} lg={9}>
              <div className="recruitment-process-header-text">
                <h1 className="">Price Guide</h1>
                <p className="">Discover the potential cost savings you can achieve by partnering with Remote Philippines. Refer to our cost savings guide, which showcases the value you can unlock through our services. Please note that the actual pricing will be tailored to your specific requirements, considering the experience level of your desired employees and the precise job description.</p>
                <p className="">Get in touch with our office today to schedule a complimentary consultation. We are ready to discuss your unique needs and provide personalised solutions.</p>
                <div className='mt-4'>
                  <a target="_blank" className='link-btn' href='https://calendly.com/remotephilippines/'> BOOK A DISCOVERY CALL</a>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="global-banner-2__gradient-overlay d-flex justify-content-end">
                <div className="gradient-bar gradient-primary-2 d-lg-block d-none"></div>
                <div className="gradient-bar gradient-primarys-1 d-lg-block d-none" ></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="price-guide-wrapper">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <div className="heading">*Actual pricing will depend on the candidate's work experience, skill sets and seniority level matched with your exact job description.</div>
            </Col>
          </Row>
          {isMobile ? <PriceGuideTableMobile /> : <PriceGuideTable />}
        </Container>
      </div >
      <div className='get-started-form'>
        <Container>
          <Row>
            <Col className="col-12 col-lg-10 offset-lg-1">
              <div className='request-contact-form'>
                <h2 className='text-center'>Look no further. Get started with your outsourcing journey today!</h2>
                <p className='text-center'>Book a free session with one of Remote Philippines’s expert sales consultants. We'll get in touch with you to arrange a call, respond to any enquiries you might have, and begin onboarding your staff.</p>
                <GetRequestProposalForm />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {isMobile ? <MobileFooter /> : <Footer />}
    </div >
  );
}

export default PriceGuide;
