import Header from "../include/header";
import Footer from "../include/footer";
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Images from "../../../constant/images";
import CommonMeta from "../include/CommonMeta";
import MobileHeader from "../include/mobileHeader";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import GetRequestProposalForm from "../../getRequestProposalForm";
import MobileFooter from "../include/mobileFooter";
import OurServicesPriceTable from "./ourServicesPricingTable/ourServicesPriceTable";
import OurServicesPriceTableMobile from "./ourServicesPricingTable/ourServicesPriceTableMobile";

function OurServices() {
  return (
    <div>
      <CommonMeta
        title='Our Services'
        description='Remote Philippines Dashboard | Our Services'
        keywords='Remote Philippines Dashboard | Our Services'
        name='Remote Philippines'
        type='article' />
      {isDesktop || isTablet ? <Header /> : <MobileHeader />}
      <div className='our-services-wrapper'>
        <Container>
          <Row>
            <Col sm={12} md={12} lg={12} xl={12}>
              <div className="our-services-wrapper-top-text">
                <h1 className="">Simple outsourcing solutions to find global success faster.</h1>
                <h2>We are your dedicated support throughout the entire outsourcing journey in the Philippines.</h2>
                <p className="">With our most comprehensive and hassle-free staffing solutions, you won't need to look elsewhere. Choose from a range of options that perfectly suit your needs, ensuring that you have everything in one place.</p>
                <div className='mt-4 text-center'> <Link className='link-btn' to='/request-proposal'>GET STARTED</Link></div>
                <div className='mt-3 text-center'> <img loading="lazy" src={Images.heroSuite} alt='' className='img-fluid'></img></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="start-hiring-staff">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={12} xl={12}>
              <h4 className="heading">Start hiring a staff and kickstart the growth and management of your Filipino dream team.</h4>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row className="align-items-center">
            <Col sm={12} md={12} lg={4} xl={4}>
              <div className="card-box-wrapper">
                <div className="heading-text">
                  <img loading="lazy" width={'83px'} src={Images.RemoteStaff} alt='' className='img-fluid' />
                  <h4> Remote Staff</h4>
                </div>
                <p>Opting for home-based virtual or remote staff offers several advantages that may align with your needs. One primary benefit is the cost reduction due to the absence of office infrastructure. Additionally, you will have access to a larger talent pool, as numerous individuals on our team appreciate the flexibility and convenience that comes with working from home.</p>
                <div className='mt-4'> <a className='link-btn' href='#RemoteStaff'>Learn More</a></div>
              </div>
            </Col>
            <Col sm={12} md={12} lg={4} xl={4}>
              <div className="card-box-wrapper office-based-staff-box">
                <div className="heading-text">
                  <img loading="lazy" width={'83px'} src={Images.OfficeBasedStaff} alt='' className='img-fluid' />
                  <h4>Office-Based Staff</h4>
                </div>
                <p>Embark on a low-risk option to establish your office-based team in the Philippines. Start with one member who will be working in a shared working environment and slowly grow your offshore team. We have built partnerships with select office-based facilities in the Philippines, ensuring that our clients have access to these exclusive spaces at competitive prices.</p>
                <div className='mt-4'> <a className='link-btn' href='#OfficeBasedStaff'>Learn More</a></div>
              </div>
            </Col>
            <Col sm={12} md={12} lg={4} xl={4}>
              <div className="card-box-wrapper">
                <div className="heading-text">
                  <img loading="lazy" width={'83px'} src={Images.ExecutiveSearch} alt='' className='img-fluid' />
                  <h4>Executive Search</h4>
                </div>
                <p>For clients who prefer to employ their own staff, we can help you form your in-house teams through our recruitment-only service. This is designed to streamline the hiring process, saving you time and effort. With our expertise in sourcing and selecting top talent, we will identify the most qualified candidates who align with your specific needs and organizational culture.</p>
                <div className='mt-4'> <a className='link-btn' href='#ExecutiveSearch'>Learn More</a></div>
              </div>
            </Col>
            <Col sm={12} md={12} lg={4} xl={4}>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="Hire-Remote-Staff" id="RemoteStaff">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={12} xl={12}>
              <div className="top-heading-wrapper">
                <h4 className="heading">Hire a Remote Staff</h4>
                <p>A significant portion of our workforce embraces remote work. We have observed that many of our employees have extensive experience in office-based positions. However, due to the considerable travel time in Manila, which can reach up to two hours each way, they have eagerly transitioned to the option of working from home. Imagine spending a staggering four hours commuting alone, every single day!</p>
                <p>In the Philippines, the opportunity to work from the comfort of home while maintaining a rewarding career is highly sought after. Our solutions cater to this preference, offering a range of options tailored to the needs of small businesses like yours. Discover the possibilities for your company in the Philippines with our comprehensive solutions.</p>
              </div>
            </Col>
            <div className="Hire-Remote-Staff-wrapper">
              <Col sm={12} md={12} lg={6} xl={6}>
                <div className="Hire-Remote-Staff-left">
                  <div className="icon"><img width={"50px"} loading="lazy" src={Images.visaIcon} alt='' className='img-fluid'></img></div>
                  <div className="text">This is the most cost-effective outsourcing service</div>
                </div>
                <div className="Hire-Remote-Staff-left">
                  <div className="icon"><img width={"50px"} loading="lazy" src={Images.visaIcon} alt='' className='img-fluid'></img></div>
                  <div className="text">Maintain full control of your team’s tasks</div>
                </div>
                <div className="Hire-Remote-Staff-left">
                  <div className="icon"><img width={"50px"} loading="lazy" src={Images.visaIcon} alt='' className='img-fluid'></img></div>
                  <div className="text">Scale discounts for volume hire</div>
                </div>
              </Col>
              <Col sm={12} md={12} lg={6} xl={6}>
                <div className="Hire-Remote-Staff-right">
                  <div className="icon"><img width={"50px"} loading="lazy" src={Images.visaIcon} alt='' className='img-fluid'></img></div>
                  <div className="text">Recruit and employ a staff in days</div>
                </div>
                <div className="Hire-Remote-Staff-right">
                  <div className="icon"><img width={"50px"} loading="lazy" src={Images.visaIcon} alt='' className='img-fluid'></img></div>
                  <div className="text">Includes HR, Payroll and Compliance support</div>
                </div>
                <div className="Hire-Remote-Staff-right">
                  <div className="icon"><img width={"50px"} loading="lazy" src={Images.visaIcon} alt='' className='img-fluid'></img></div>
                  <div className="text">Tap into a vast pool of talent </div>
                </div>
              </Col>
            </div>
          </Row>
        </Container>
      </div>
      <div className="office-based-staff" id="OfficeBasedStaff">
        <Container>
          <Row className="align-items-center">
            <Col sm={12} md={12} lg={6} xl={6}>
              <img loading="lazy" src={Images.HireOfficeBased} alt='' className='img-fluid'></img>
            </Col>
            <Col sm={12} md={12} lg={6} xl={6}>
              <div className="ps-lg-5 ">
                <h4>Hire an Office-Based Staff</h4>
                <p>Unlock the power of office-based staff outsourcing for your business and experience the best of both worlds. We understand that some businesses prefer the traditional office-working environment, and that's why we offer tailored solutions to meet your specific needs.</p>
                <p>With our office-based staff outsourcing service, you can enjoy the benefits of a dedicated team working in a shared office space, equipped with state-of-the-art facilities and a professional work environment. Our handpicked professionals are skilled, experienced, and ready to seamlessly integrate into your operations.</p>
                <div className="office-based-staff-list">
                  <div className="office-based-staff-list-inner">
                  <div className="icon"><img width={"50px"} loading="lazy" src={Images.visaIcon} alt='' className='img-fluid'></img></div>
                    <p>Maintain direct oversight and collaboration between team members</p>
                  </div>
                  <div className="office-based-staff-list-inner">
                  <div className="icon"><img width={"50px"} loading="lazy" src={Images.visaIcon} alt='' className='img-fluid'></img></div>
                    <p>Easy access to Resources and Infrastructure</p>
                  </div>
                  <div className="office-based-staff-list-inner">
                  <div className="icon"><img width={"50px"} loading="lazy" src={Images.visaIcon} alt='' className='img-fluid'></img></div>
                    <p>Offers enhanced communication and building relationships</p>
                  </div>
                  <div className="office-based-staff-list-inner">
                  <div className="icon"><img width={"50px"} loading="lazy" src={Images.visaIcon} alt='' className='img-fluid'></img></div>
                    <p>Provides a conducive environment for professional development</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="recruit-the-staff" id="ExecutiveSearch">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={12} xl={12}>
              <div className="top-heading-wrapper">
                <h4>Executive Search Solution</h4>
                <p>Introducing our specialized recruitment-only service tailored to businesses that prioritize finding the perfect talent for their organization. We understand that finding the right candidates can be a challenging and time-consuming process, which is why we are here to revolutionize your hiring experience.</p>
                <p>With our recruitment-only service, you can focus on your core business while we handle the intricacies of the hiring process. Our team of expert recruiters possesses an in-depth understanding of the Philippine talents marketplace, ensuring that we identify candidates who align perfectly with your requirements and company culture.</p>
              </div>
            </Col>
            {/* <Col sm={12} md={12} lg={6} xl={6}>
              <div className="image mt-5">
                <img loading="lazy" src={Images.hiringIllustration} alt='' className='img-fluid'></img>
              </div>
            </Col> */}
            <Col sm={12} md={12} lg={12} xl={12}>
              <div className="recruit-the-staff-list-wrapper">
                <div className="recruit-the-staff-list-inner">
                <div className="icon"><img width={"50px"} loading="lazy" src={Images.visaIcon} alt='' className='img-fluid'></img></div>
                  <p>Extensive network</p>
                </div>
                <div className="recruit-the-staff-list-inner">
                <div className="icon"><img width={"50px"} loading="lazy" src={Images.visaIcon} alt='' className='img-fluid'></img></div>
                  <p>Cost-effective solution</p>
                </div>
                <div className="recruit-the-staff-list-inner">
                <div className="icon"><img width={"50px"} loading="lazy" src={Images.visaIcon} alt='' className='img-fluid'></img></div>
                  <p>Competitive advantage</p>
                </div>
                <div className="recruit-the-staff-list-inner">
                <div className="icon"><img width={"50px"} loading="lazy" src={Images.visaIcon} alt='' className='img-fluid'></img></div>
                  <p>Focus on core competencies</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='boost-efficiency'>
        <Container>
          <Row>
            <Col md={6}>
              <div className="boost-efficiency-heading">
                <h3>Boost efficiency, cut costs, and drive success with outsourcing.</h3>
                <p> Outsourcing enables companies to tap into a global talent pool, accessing top-notch professionals without geographical limitations. This vast talent pool brings diverse perspectives, fresh ideas, and innovative approaches, fueling creativity and driving success. Additionally, outsourcing provides flexibility, allowing organizations to adapt swiftly to market changes, seasonal demands, and business fluctuations. By partnering with trusted outsourcing providers, companies can achieve operational excellence, gain a competitive edge, and focus on strategic initiatives that propel growth. </p>
                <div><Link className='link-btn' to='/request-proposal'>GET STARTED</Link></div>
              </div>
            </Col>

            <Col md={12} lg={6}>
              <div className="boost-efficiency-business gradient-bar-wrapper">
                <div className="gradient-bar gradient-primary-2"></div>
                <div className="gradient-bar gradient-primarys-1">
                  <div className="gradient-secondary-5-img">
                    <img  loading="lazy" src={Images.valuepropLogos} className='img-fluid' alt='' />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="price-guide-wrapper">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <div className="pricing-heading">
              Supercharge your growth by leveraging <br /> our comprehensive suite of outsourcing services.
              </div>
            </Col>
          </Row>
          {isMobile ? <OurServicesPriceTableMobile /> : <OurServicesPriceTable />}
        </Container>
      </div>
      <div className='home-request-propsal top-companies'>
        <Container>
          <Row>
            {/* <Col md={12} lg={12}>
              <h5>Why do <span>top companies</span> choose Remote Philippines ? <br />
                It's our industry‑leading reputation.</h5>
            </Col> */}
            <Col md={12} lg={6} className="mt-4">
              <Row>
                <Col md={6}>
                  <div className='home-request-propsal-card'>
                    <div className="number gradient-text">USD25bn</div>
                    <div className='text'>sector revenue</div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className='home-request-propsal-card'>
                    <div className="number gradient-text">48M</div>
                    <div className='text'>Filipino workforce</div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className='home-request-propsal-card'>
                    <div className="number gradient-text">59%</div>
                    <div className='text'>firms who outsource</div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className='home-request-propsal-card'>
                    <div className="number gradient-text ">70%</div>
                    <div className='text'>cost savings</div>
                  </div>
                </Col>
                <Col md={12}>
                  <div className='mt-5 text-center'><Link className='link-btn' to='/request-proposal'>  REQUEST A PROPOSAL </Link> </div>
                </Col>
              </Row>
            </Col>
            <Col md={6}></Col>
          </Row>
        </Container>
      </div>

      <div className='get-started-form'>
        <Container>
          <Row>
            <Col className="col-12 col-lg-10 offset-lg-1">
              <div className='request-contact-form'>
                <h2 className='text-center'>Look no further. Get started with your outsourcing journey today!</h2>
                <p className='text-center'>Book a free session with one of Remote Philippines’s expert sales consultants. We'll get in touch with you to arrange a call, respond to any enquiries you might have, and begin onboarding your staff.</p>
                <GetRequestProposalForm />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {isMobile ? <MobileFooter /> : <Footer />}
    </div>
  );
}

export default OurServices;
