import Header from "../include/header";
import Footer from "../include/footer";
import { Col, Container, Row } from 'react-bootstrap';
import Images from "../../../constant/images";
import CommonMeta from "../include/CommonMeta";
import MobileHeader from "../include/mobileHeader";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import GetRequestProposalForm from "../../getRequestProposalForm";
import MobileFooter from "../include/mobileFooter";

function Community() {
  return (
    <div>
      <CommonMeta
        title='Community'
        description='Remote Philippines Dashboard | Community'
        keywords='Remote Philippines Dashboard | Community'
        name='Remote Philippines'
        type='article' />
      <div className='header2-wrapper header3-wrapper'>
        {isDesktop || isTablet ? <Header /> : <MobileHeader />}
      </div>
      <div className="Join-the-community">
        <Container>
          <Row>
            <Col md={6}>
              <div className="heading-text me-md-5">
                <h1>Join the R-P community</h1>
                <p>Connect with people of similar interests, share your insights and experiences, ask for guidance and build valuable connections. The Remote Philippines community is the place to be for professionals looking to belong and aiming to cultivate a valuable community. Get first access to new jobs, webinars, and benefits that you can only obtain here. Take advantage of networking chances with the Filipino working community and Remote Philippines crew as well as many other skilled members.</p>
                <div className="my-5">
                  <img loading="lazy" src={Images.join_The_RP} alt='' className='img-fluid' />
                </div>
                <p>The Remote Philippines Community is a collection of people and groups who are looking for remote work opportunities, connect with other remote working professionals and learn more about the future of work. </p>
                <p>In order to bring various communities together into a single, collaborative setting where like-minded people can discuss, brainstorm, and network as well as learn and share learnings and opportunities, Remote Philippines launched its online community in 2019. </p>
                <div className="my-5">
                  <img loading="lazy" src={Images.join_The_RP2} alt='' className='img-fluid' />
                </div>
                <p>Remote Philippines aspires to lead in providing jobs and remote opportunities to our population of skilled Filipino workers. We aim to gather a cohesive community and create an environment where everyone can express themselves, share learnings and experiences, and network with other users and our team.</p>
                <p>We strive to initiate community-led initiatives and we are just beginning. We want to expand and create a powerful network of Filipino talents. We are overjoyed to have you join us.</p>
              </div>
            </Col>
            <Col md={6}>
              <div className="Join-the-community-form">
                <div className='request-contact-form'>
                  <h2>Unlock a new world of talent with outsourcing.</h2>
                  <p>One of our outsourcing experts will get in touch with you right away.</p>
                  <GetRequestProposalForm />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="remote-philippines-social-group">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <div className="remote-philippines-social-group-link">
                <a target="_blank" href="https://facebook.com/groups/remotephilippines">JOIN OUR FACEBOOK GROUP</a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="remote-philippines-social-group linkdin">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <div className="remote-philippines-social-group-link text-lg-end">
                <a target="_blank" href="https://linkedin.com/groups/remotephilippines">JOIN OUR LINKEDIN GROUP</a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="about-community">
        <Container>
          <Row>
            <Col md={6}>
              <h2>About Us</h2>
              <p className="col-xl-10">Remote Philippines enables growing businesses to realize their full potential by allowing them to build highly-skilled Filipino teams fast. We help find, hire, onboard and pay team members quickly and compliantly through our streamlined in-house processes. We empower Filipino talents by connecting them with global opportunities and contribute to the future of remote work.</p>
              <h4><strong>Delivering exceptional outsourcing</strong></h4>
            </Col>
            <Col md={6}>
              <img loading="lazy" src={Images.ContactPageImage} alt='' className='img-fluid' />
            </Col>
          </Row>
        </Container>
      </div>
      {isMobile ? <MobileFooter /> : <Footer />}
    </div>
  );
}

export default Community;
