import Header from "../include/header";
import Footer from "../include/footer";
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Images from "../../../constant/images";
import CommonMeta from "../include/CommonMeta";
import MobileHeader from "../include/mobileHeader";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import GetRequestProposalForm from "../../getRequestProposalForm";
import MobileFooter from "../include/mobileFooter";

function Benefits() {
  return (
    <div>
      <CommonMeta
        title='Benefits'
        description='Remote Philippines Dashboard | Benefits'
        keywords='Remote Philippines Dashboard | Benefits'
        name='Remote Philippines'
        type='article' />
      <div className='header2-wrapper'>
        {isDesktop || isTablet ? <Header /> : <MobileHeader />}
      </div>
      <div className='our-solution-finance-hero Benefits_background'>
        <Container>
          <Row className='our-solution-inner-section'>
            <Col md={6}>
              <div className='image-section'>
                <img  loading="lazy" src={Images.Benefits_top} className='img-fluid' alt='' />
              </div>
            </Col>
            <Col md={6}>
              <div className='our-solution-details'>
                <h4>TRANSFORMATION</h4>
                <h3>Experience explosive growth with outsourcing.</h3>
                <p>Invest more time on your business. Utilize our local resources and knowledge to reduce the time, expense, risk and headache of building a high-performing team. We are not just an outsourcing company; we are also your business growth partner.</p>
                <div className='our-solution-details-btn'> <Link className='link-btn' to='/request-proposal'>CONNECT WITH US</Link></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='our-benefits-wrapper'>
        <Container>
          <Row>
            <Col md={12}>
              <div className='benefits-heading'>
                <p>BENEFITS</p>
                <h4>Hire the right team to transform your business.</h4>
              </div>
            </Col>
            <Col md={6}>
              <div className='our-benefits-services'>
                <div className='icon'><img  loading="lazy" src={Images.superiorExpertise} className='img-fluid' alt='' /></div>
                <div className='details'>
                  <h4>Superior expertise</h4>
                  <p>Every step of the way, we are driven by passion to deliver a wonderful outsourcing experience for you and your team.</p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className='our-benefits-services'>
                <div className='icon'><img  loading="lazy" src={Images.fairPricing} className='img-fluid' alt='' /></div>
                <div className='details'>
                  <h4>Fair Pricing</h4>
                  <p>There are no minimums, exclusivity clauses, hidden costs, or lengths of contracts. Select the strategy that works best for your team.</p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className='our-benefits-services'>
                <div className='icon'>  <img  loading="lazy" src={Images.reliablePartner} className='img-fluid' alt='' /></div>
                <div className='details'>
                  <h4>Reliable partner</h4>
                  <p>Our account specialists, talent acquisition experts and human resource specialists are actively working together seamlessly and enhancing our processes in order to serve you and our client staff better.</p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className='our-benefits-services'>
                <div className='icon'><img  loading="lazy" src={Images.hireStaff} className='img-fluid' alt='' /></div>
                <div className='details'>
                  <h4>Hire a staff in days</h4>
                  <p>Spend less time and money creating a local entity in the Philippines. Quickly hire (remove “an”) offshore staff legally using our local presence in the Philippines.</p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className='our-benefits-services'>
                <div className='icon'><img  loading="lazy" src={Images.multiCurrencyPayments} className='img-fluid' alt='' /></div>
                <div className='details'>
                  <h4>Multi-currency payments</h4>
                  <p>We made paying your staff in the Philippines simple. You can choose from a number of major currencies to pay us and we pay your staff in Pesos (local Philippines currency).</p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className='our-benefits-services'>
                <div className='icon'><img  loading="lazy" src={Images.employmentTaxesCompliance} className='img-fluid' alt='' /></div>
                <div className='details'>
                  <h4>Employment tax compliance</h4>
                  <p>As the official Employer of Record, it is our responsibility to deal with evolving tax requirements in the Philippines.</p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className='our-benefits-services'>
                <div className='icon'><img  loading="lazy" src={Images.saveUp} className='img-fluid' alt='' /></div>
                <div className='details'>
                  <h4>Save up to 70% on staffing costs</h4>
                  <p>Reduce workforce expenses by 70% while increasing output, quality, and revenue. Guaranteed.</p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className='our-benefits-services'>
                <div className='icon'><img  loading="lazy" src={Images.hrSupport} className='img-fluid' alt='' /></div>
                <div className='details'>
                  <h4>Local HR support.</h4>
                  <p>Our local HR team ensures adherence to labor regulations and offers your staff local support.</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='get-started-form'>
        <Container>
          <Row>
            <Col className="col-12 col-lg-10 offset-lg-1">
              <div className='request-contact-form'>
                <h2 className='text-center'>Look no further. Get started with your outsourcing journey today!</h2>
                <p className='text-center'>Book a free session with one of Remote Philippines’s expert sales consultants. We'll get in touch with you to arrange a call, respond to any enquiries you might have, and begin onboarding your staff.</p>
                <GetRequestProposalForm />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {isMobile ? <MobileFooter /> : <Footer />}
    </div>
  );
}

export default Benefits;
