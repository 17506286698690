import Header from "../../include/header";
import Footer from "../../include/footer";
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Images from "../../../../constant/images";
import CommonMeta from "../../include/CommonMeta";
import MobileHeader from "../../include/mobileHeader";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import GetRequestProposalForm from "../../../getRequestProposalForm";
import MobileFooter from "../../include/mobileFooter";
function Agency() {
  return (
    <div>
      <CommonMeta
        title='Agency'
        description='Remote Philippines Dashboard | Agency'
        keywords='Remote Philippines Dashboard | Agency'
        name='Remote Philippines'
        type='article' />
      <div className='header2-wrapper'>
        {isDesktop || isTablet ? <Header /> : <MobileHeader />}
      </div>
      <div className='our-solution-finance-hero agency-wrapper'>
        <Container>
          <Row className='our-solution-inner-section'>
            <Col md={6}>
              <div className='image-section'>
                <img  loading="lazy" src={Images.pexelsNataliya} className='img-fluid' alt='' />
              </div>
            </Col>
            <Col md={6}>
              <div className='our-solution-details'>
                <h4>WE WORK WITH ALL SIZES OF BUSINESS</h4>
                <h3>Agency Business Outsourcing</h3>
                <p>A company or firm that performs a certain service for another business, an individual, or other entities is referred to as an agency. There is no way to sugarcoat how stressful it is to expand an agency the conventional way.</p>
                <p>You need more employees as you receive more clients. You add more employees, and you require more customers. It's a downward spiral that has everyone (including you) working long hours or with slimmer profit margins.</p>
                <p>The good news is that we live in a world where companies can grow without adding more long-term employees. There exists now the ability to outsource project-based staff for short-term requirements.</p>
                <p>It expedites the hiring process and lowers the possibility of high overhead costs.</p>
                <div className='our-solution-details-btn'> <Link className='link-btn' to='/request-proposal'>CONNECT WITH US</Link></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='about-agency'>
        <Container>
          <Row className='align-items-center about-agency-inner'>
            <Col md={12} lg={5}>
              <div className='image-section'>
                <img  loading="lazy" src={Images.Asset7} className='img-fluid' alt='' />
              </div>
            </Col>
            <Col md={12} lg={7}>
              <div className='our-solution-details'>
                <h4 className="ms-md-5">FINANCIALS</h4>
                <h3 className="ms-md-5">Keep resources in-tack. Remain effective.</h3>
                <p className="ms-md-5">A variety of financial procedures can be readily and successfully outsourced because most companies don't really need to engage a full-time accounting team internally until they experience significant growth and a change towards a more complicated organisational structure.</p>
                <div className='mt-4 ms-md-5'> <Link className='link-btn' to='/benefits'>LEARN MORE</Link></div>
              </div>
            </Col>
          </Row>
          <Row className='align-items-center about-agency-inner col-reverse'>
            <Col md={12} lg={7}>
              <div className='our-solution-details '>
                <h4 className="me-md-5">HUMAN RESOURCES</h4>
                <h3 className="me-md-5">Hire expert workers for any job.</h3>
                <p className="me-md-5 col-xl-10">The truth is that you'll likely need to assemble a sizable team to handle every aspect of hiring, onboarding, benefits administration, and so forth. Businesses can delegate to Remote Philippines to handle the most important components of human resources and some of the operations that are most prone to error. Remote Philippines manages administration, payroll, health benefits and other aspects of human resource management. </p>
                <div className='mt-4'> <Link className='link-btn' to='/benefits'>LEARN MORE</Link></div>
              </div>
            </Col>
            <Col md={12} lg={5}>
              <div className='image-section'>
                <img  loading="lazy" src={Images.officeWorker} className='img-fluid' alt='' />
              </div>
            </Col>
          </Row>
          <Row className='align-items-center about-agency-inner'>
            <Col md={12} lg={5}>
              <div className='image-section'>
                <img  loading="lazy" src={Images.officeMale} className='img-fluid' alt='' />
              </div>
            </Col>
            <Col md={12} lg={7}>
              <div className='our-solution-details'>
                <h4 className="ms-md-5">CUSTOMER SERVICE</h4>
                <h3 className="ms-md-5">Ensure customer satisfaction at all times.</h3>
                <p className="ms-md-5">The success of your business depends on how well you treat your customers. It might be difficult to make an informed decision when you're outsourcing customer service because it can seem like you're entrusting someone else with the care of your child.</p>
                <p className="ms-md-5">Although it could seem intimidating, outsourcing is a tried-and-true strategy to support your expanding business and scale your customer service. Outsourcing to Remote Philippines could be the key to unlocking future growth if you're having trouble keeping up with your support queue or providing exceptional customer service.</p>
                <div className='mt-4 ms-md-5'> <Link className='link-btn' to='/benefits'>LEARN MORE</Link></div>
              </div>
            </Col>
          </Row>
          <Row className='align-items-center about-agency-inner col-reverse'>
            <Col md={12} lg={7}>
              <div className='our-solution-details'>
                <h4 className="me-md-5">SOFTWARE DEVELOPMENT</h4>
                <h3 className="me-md-5">Access to superior tech talents at an affordable price.</h3>
                <p className="me-md-5 col-xl-10">A global talent pool makes it possible to embrace cultural variety and capitalize on individuals' capacity for original thought to drive innovation. Today, many firms pay particular attention to outsourcing software development to the Philippines. Our experience has shown that, on the whole, the developers we hire in the Philippines have wonderful attitudes, make co-workers happy, and are highly devoted.</p>
                <div className='mt-4'> <Link className='link-btn' to='/benefits'>LEARN MORE</Link></div>
              </div>
            </Col>
            <Col md={12} lg={5}>
              <div className='image-section'>
                <img  loading="lazy" src={Images.officeFemale} className='img-fluid' alt='' />
              </div>
            </Col>
          </Row>
          <Row className='align-items-center about-agency-inner'>
            <Col md={12} lg={5}>
              <div className='image-section'>
                <img  loading="lazy" src={Images.marketing} className='img-fluid' alt='' />
              </div>
            </Col>
            <Col md={12} lg={7}>
              <div className='our-solution-details'>
                <h4 className="ms-md-5">MARKETING</h4>
                <h3 className="ms-md-5">Get ahead. Beat the competition to market.</h3>
                <p className="ms-md-5">To prosper, a company needs to establish a sizable market presence. Experts in marketing and advertising are needed by businesses so they can plan their operational strategy. You will be able to scale and grow your firm to achieve your goals by working with a Philippine offshore team. For the same salary as an in-house employee, you can hire a full marketing staff overseas. You will be able to use the savings to fund your subsequent marketing effort.</p>
                <div className='mt-4 ms-md-5'> <Link className='link-btn' to='/benefits'>LEARN MORE</Link></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='get-started-form'>
        <Container>
          <Row>
            <Col className="col-12 col-lg-10 offset-lg-1">
              <div className='request-contact-form'>
                <h2 className='text-center'>Look no further. Get started with your outsourcing journey today!</h2>
                <p className='text-center'>Book a free session with one of Remote Philippines’s expert sales consultants. We'll get in touch with you to arrange a call, respond to any enquiries you might have, and begin onboarding your staff.</p>
                <GetRequestProposalForm />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {isMobile ? <MobileFooter /> : <Footer />}
    </div>
  );
}

export default Agency;
