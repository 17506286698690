import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Images from "../../../../constant/images";

function OurServicesPriceTable() {

  return (
    <div className="position-relative">
      <div className="pricing-table-header position-sticky ">
        <div className="d-flex">
          <Col md={4}></Col>
          <Col className="px-1">
            <div className="pricing-table-cell pricing-table-header1-border">
              <img loading="lazy" width={'83px'} src={Images.RemoteStaff} alt='' className='img-fluid' />
              <h3>Remote Staff</h3>
              <div className='my-4 text-center'> <a className='link-btn' href='#RemoteStaff'>LEARN MORE</a></div>
            </div>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell">
              <img loading="lazy" width={'83px'} src={Images.OfficeBasedStaff} alt='' className='img-fluid' />
              <h3>Office-based Staff</h3>
              <div className='my-4 text-center'> <a className='link-btn' href='#OfficeBasedStaff'>LEARN MORE</a></div>
            </div>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell">
              <img loading="lazy" width={'83px'} src={Images.ExecutiveSearch} alt='' className='img-fluid' />
              <h3>Executive Search</h3>
              <div className='my-4 text-center'> <a className='link-btn' href='#ExecutiveSearch'>LEARN MORE</a></div>
            </div>
          </Col>
        </div>
      </div>
      <Col md={12}>
        <div className="pricing-table-row d-flex">
          <Col md={4}>
            <p>Staff Recruitment</p>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col pricing-table-header1-border"><i className="fa-solid fa-check"></i></div>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
          </Col>
        </div>
        <div className="pricing-table-row d-flex">
          <Col md={4}>
            <p>Background Checks</p>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col pricing-table-header1-border"><i className="fa-solid fa-check"></i></div>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col"><span className="fa-solid fa-check">  </span> </div>
          </Col>
        </div>
        <div className="pricing-table-row d-flex">
          <Col md={4}>
            <p>Staff Employment</p>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col pricing-table-header1-border"><i className="fa-solid fa-check"></i></div>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col"><span className="line">  </span> </div>
          </Col>
        </div>
        <div className="pricing-table-row d-flex">
          <Col md={4}>
            <p>Onboarding of Staff</p>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col pricing-table-header1-border"><i className="fa-solid fa-check"></i></div>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col"><span className="line">  </span> </div>
          </Col>
        </div>
        <div className="pricing-table-row d-flex">
          <Col md={4}>
            <p>Payroll Management</p>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col pricing-table-header1-border"><i className="fa-solid fa-check"></i></div>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col"><span className="line">  </span> </div>
          </Col>
        </div>
        <div className="pricing-table-row d-flex">
          <Col md={4}>
            <p>Benefits Management</p>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col pricing-table-header1-border"><i className="fa-solid fa-check"></i></div>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col"><span className="line">  </span> </div>
          </Col>
        </div>
        <div className="pricing-table-row d-flex">
          <Col md={4}>
            <p>Tax Duties</p>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col pricing-table-header1-border"><i className="fa-solid fa-check"></i></div>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col"><span className="line">  </span> </div>
          </Col>
        </div>
        <div className="pricing-table-row d-flex">
          <Col md={4}>
            <p>Staff HR Support</p>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col pricing-table-header1-border"><i className="fa-solid fa-check"></i></div>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col"><span className="line">  </span> </div>
          </Col>
        </div>
        <div className="pricing-table-row d-flex">
          <Col md={4}>
            <p>Employment Compliance</p>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col pricing-table-header1-border"><i className="fa-solid fa-check"></i></div>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col"><span className="line">  </span> </div>
          </Col>
        </div>
        <div className="pricing-table-row d-flex">
          <Col md={4}>
            <p>Offboarding of Staff</p>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col pricing-table-header1-border"><i className="fa-solid fa-check"></i> </div>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col"><span className="line">  </span> </div>
          </Col>
        </div>
        <div className="pricing-table-row d-flex">
          <Col md={4}>
            <p>Customer Support</p>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col pricing-table-header1-border"><i className="fa-solid fa-check"></i></div>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col"><span className="line">  </span> </div>
          </Col>
        </div>
        <div className="pricing-table-row d-flex">
          <Col md={4}>
            <p>Shared Office Space</p>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col pricing-table-header1-border"><i className="line"></i></div>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col"><i className="fa-solid fa-check"></i></div>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col"><span className="line">  </span> </div>
          </Col>
        </div>
        <div className="pricing-table-row d-flex">
          <Col md={4}>
            <p>Premium Office Facilities</p>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col pricing-table-header1-border pricing-table-header1-border-bottom"><span className="line">  </span></div>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col"><span className="fa-solid fa-check">  </span></div>
          </Col>
          <Col className="px-1">
            <div className="pricing-table-cell-col"><span className="line">  </span> </div>
          </Col>
        </div>
      </Col>
    </div>
  );
}

export default OurServicesPriceTable;