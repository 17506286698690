import Images from "../constant/images";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Col, Container, Row } from "react-bootstrap";

function ServicesSlider() {

    var settings = {
        dots: false,
        arrows: true,
        infinite: true,
        loop: true,
        slidesToShow: 3,
        slidesToScrol1: 1,
        autoplay: true,
        speed: 3000,
        // easing: 'linear',
        pauseOnHover: true,
        // swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScrol1: 1,

                },
            },
            {
                breakpoint: 1008,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,

                },
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,

                },
            },
        ],
    };

    return (
        <Container>
            <Row>
                <Col md={12}>
                    <div className="global-banner-2__gradient-overlay d-flex justify-content-end">
                        <div className="gradient-bar gradient-primary-2 d-lg-block d-none" ></div>
                        <div className="gradient-bar gradient-primarys-1 d-lg-block d-none" ></div>
                    </div>
                </Col>
                <Col>
                    <div className="outsourcing-any-role-slider">
                        <Slider {...settings}>
                            <div>
                                <div className="customer-box">
                                    <div className="customer-image">
                                        <img  loading="lazy" src={Images.Asset13} className='img-fluid' alt='' />
                                    </div>
                                    <div className="customer-details">
                                        <h2>Admin & Customer Support</h2>
                                        <p>Outsource experienced admin and CS roles in the Philippines.</p>
                                        <h5>Some roles you can hire with us:</h5>
                                        <ul>
                                            <li>-	Data entry expert</li>
                                            <li>-	Customer support</li>
                                            <li>-	Transcriptionist</li>
                                            <li>-	Virtual assistant</li>
                                            <li>-	HR & Recruitment</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="customer-box">
                                    <div className="customer-image">
                                        <img  loading="lazy" src={Images.Asset12} className='img-fluid' alt='' />
                                    </div>
                                    <div className="customer-details">
                                        <h2>Software Development</h2>
                                        <p>Outsource experienced IT and web developer roles in the Philippines.</p>
                                        <h5>Some roles you can hire with us:</h5>
                                        <ul>
                                            <li>-	Software Developer</li>
                                            <li>-	Web Developer</li>
                                            <li>-	Technical Support</li>
                                            <li>-	Project Manager</li>
                                            <li>-	Quality Analyst</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="customer-box">
                                    <div className="customer-image">
                                        <img  loading="lazy" src={Images.Asset11} className='img-fluid' alt='' />
                                    </div>
                                    <div className="customer-details">
                                        <h2>Marketing & Sales</h2>
                                        <p>Outsource experienced marketing and sales roles in the Philippines.</p>
                                        <h5>Some roles you can hire with us:</h5>
                                        <ul>
                                            <li>-	Digital Marketer</li>
                                            <li>-	Media Buyer</li>
                                            <li>-	Sales agent</li>
                                            <li>-	Copywriter</li>
                                            <li>-	Lead Generation Specialist</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="customer-box">
                                    <div className="customer-image">
                                        <img  loading="lazy" src={Images.Asset10} className='img-fluid' alt='' />
                                    </div>
                                    <div className="customer-details">
                                        <h2>Finance & Accounting</h2>
                                        <p>Outsource experienced finance and accounting roles in the Philippines.</p>
                                        <h5>Some roles you can hire with us:</h5>
                                        <ul>
                                            <li>-	Bookkeeper</li>
                                            <li>-	Payables Officer</li>
                                            <li>-	Receivables Officer</li>
                                            <li>-	Accountant</li>
                                            <li>-	Claims Processor</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default ServicesSlider;