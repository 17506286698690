import Header from "../include/header";
import Footer from "../include/footer";
import { Accordion, Col, Container, Row } from 'react-bootstrap';
import { isDesktop, isMobile, isTablet } from 'react-device-detect';
import CommonMeta from '../include/CommonMeta';
import MobileHeader from '../include/mobileHeader';
import GetRequestProposalForm from '../../getRequestProposalForm';
import MobileFooter from "../include/mobileFooter";
import { Link } from "react-router-dom";

function Faq() {

  return (
    <>
      <CommonMeta
        title='Frequently Asked Questions'
        description='Remote Philippines Dashboard | Frequently Asked Questions'
        keywords='Remote Philippines Dashboard | Frequently Asked Questions'
        name='Remote Philippines'
        type='article' />
      <div className='header2-wrapper'>
        {isDesktop || isTablet ? <Header /> : <MobileHeader />}
      </div>
      <div className='faq-wrapper-section'>
        <Container>
          <Row className="gallery-inner-section">
            <Col md={12} lg={12}>
              <p className="page-heading">FAQ</p>
              <h1>Can't find what you're searching for?</h1>
              <div className='mt-4'><Link className='link-btn2' to='/request-proposal'>  CONTACT US </Link> </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="faq-question-section">
        <Container>
          <Row>
            <Col>
              <h4>Getting started with Outsourcing</h4>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>How do I get started with my outsourcing journey?</Accordion.Header>
                  <Accordion.Body>
                    <h2>To embark on your outsourcing journey, follow these steps:</h2>
                    <ul>
                      <li>	Define your goals: Determine the specific objectives you want to achieve through outsourcing. Whether it's cost savings, access to specialized skills, or expanding your global reach, having clear goals will guide your outsourcing strategy.</li>
                      <li>	Identify your needs: Assess your business processes and identify which tasks or functions can be outsourced effectively. Consider areas where outsourcing can add the most value and enhance your operations.</li>
                      <li>	Research outsourcing providers: Look for reputable outsourcing providers that align with your requirements. Consider factors such as their expertise, industry experience, client reviews, and cultural compatibility.</li>
                      <li>	Define project requirements: Prepare a detailed project brief that outlines your expectations, deliverables, timelines, and budget. Clearly communicate your requirements to potential outsourcing partners to ensure they have a comprehensive understanding of your needs.</li>
                      <li>	Evaluate and select a partner: Request proposals or quotes from shortlisted outsourcing providers. Evaluate them based on their capabilities, pricing, service levels, and track record. Conduct interviews or meetings to assess their communication, responsiveness, and cultural fit.</li>
                      <li>	Develop a transition plan: Collaborate with your chosen outsourcing partner to develop a transition plan. This plan should outline the onboarding process, communication channels, performance metrics, and any necessary training or knowledge transfer.</li>
                      <li>	Establish clear communication: Maintain open and transparent communication with your outsourcing partner. Clearly communicate your expectations, provide feedback, and address any concerns promptly. Regularly scheduled meetings and progress updates will help ensure a smooth outsourcing journey.</li>
                      <li>	Monitor and evaluate performance: Continuously monitor the performance of your outsourced tasks or team. Establish key performance indicators (KPIs) to assess their effectiveness and efficiency. Regularly review the partnership to identify areas for improvement and address any issues that may arise.</li>
                    </ul>
                    <p>Remember, outsourcing is an ongoing process that requires collaboration and partnership. By taking these steps, you can set yourself up for a successful outsourcing journey and unlock the benefits it offers for your business.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>How do I engage Remote Philippines for my staffing requirements?</Accordion.Header>
                  <Accordion.Body>
                    <h2>To get started, simply provide us with:</h2>
                    <ul>
                      <li>	A signed Client Services Agreement with Remote Philippines.</li>
                      <li>	A Position Description of the job role you are looking for which states the job description, the desired level of experience, qualifications, and personal characteristics of candidates to look for.</li>
                      <li>	The target date to hire.</li>
                      <h3>In return, we will:</h3>
                      <li>	Review your plan and provide feedback, identifying any potential challenges.</li>
                      <li>	Present a detailed cost breakdown based on your specific requirements.</li>
                      <li>	Conduct a thorough talent search, pre-qualify candidates, and present them to you for final selection.</li>
                      <li>	Share as much information as possible about your business, company profile, and the career opportunities associated with the job offer. This will enable us to position the employment offer effectively and attract the most qualified candidates.</li>
                    </ul>
                    <p>It is crucial that you provide our talent recruitment team with a comprehensive brief outlining the essential skills, experience, and other desired attributes you seek in a candidate. This will assist us in identifying the ideal candidates for your specific needs.</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <h4>Tax Compliance</h4>
              <Accordion>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>How do I compute my income tax?</Accordion.Header>
                  <Accordion.Body>
                    <p>Income tax computation is based on the individual's taxable income and the tax rates corresponding to different income brackets. You can use the tax table provided by the Bureau of Internal Revenue (BIR) or consult a tax professional to accurately calculate your income tax.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>What are the tax rates for different income brackets?</Accordion.Header>
                  <Accordion.Body>
                    <p>The tax rates for different income brackets are determined by the BIR and are subject to annual updates. It is advisable to refer to the latest tax tables provided by the BIR or consult a tax professional for the applicable tax rates.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>What are the allowable deductions for tax purposes?</Accordion.Header>
                  <Accordion.Body>
                    <p>Allowable deductions for tax purposes include personal exemptions, contributions to certain government-mandated and private retirement plans, health insurance premiums, and qualified expenses related to education, among others. It is essential to consult the BIR guidelines or a tax expert for a comprehensive list of allowable deductions.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>How do I file my annual income tax return?</Accordion.Header>
                  <Accordion.Body>
                    <p>You can file your annual income tax return by completing the necessary forms, such as BIR Form 1700 for individuals or BIR Form 1701 for self-employed individuals and professionals. These forms, along with supporting documents and payment of any tax due, should be submitted to the BIR within the prescribed deadline.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>What is the deadline for paying taxes?</Accordion.Header>
                  <Accordion.Body>
                    <p>The deadline for paying taxes in the Philippines varies depending on the type of tax and the applicable tax period. For annual income tax returns, the deadline is typically on or before April 15th of the following year. However, it is advisable to consult the BIR or a tax expert for the specific deadlines relevant to your situation.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                  <Accordion.Header>What are the tax obligations for self-employed individuals?</Accordion.Header>
                  <Accordion.Body>
                    <p>Self-employed individuals have tax obligations that include filing and paying their own income tax, contributing to government-mandated programs such as SSS (Social Security System) and PhilHealth, and complying with other tax requirements applicable to their business or profession.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                  <Accordion.Header>How do I obtain a Tax Identification Number (TIN)?</Accordion.Header>
                  <Accordion.Body>
                    <p>To obtain a Tax Identification Number (TIN), you need to visit the BIR office and submit the necessary documents and forms. The BIR will then process your application and provide you with a TIN.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10">
                  <Accordion.Header>What are the tax implications of receiving bonuses or incentives?</Accordion.Header>
                  <Accordion.Body>
                    <p>Bonuses and incentives are generally subject to income tax in the Philippines. The tax treatment may vary depending on the type of bonus or incentive, such as a 13th-month pay, performance-based bonus, or other forms of compensation. It is advisable to consult the BIR guidelines or a tax professional for the specific tax implications.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="11">
                  <Accordion.Header>Can I claim tax exemptions for dependents?</Accordion.Header>
                  <Accordion.Body>
                    <p>Yes, you can claim tax exemptions for qualified dependents, such as children or parents, subject to specific conditions and limitations set by the BIR. It is important to review the BIR guidelines or seek advice from a tax expert to determine your eligibility for claiming tax exemptions.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="12">
                  <Accordion.Header>What are the consequences of tax evasion or non-compliance?</Accordion.Header>
                  <Accordion.Body>
                    <p>Tax evasion or non-compliance can lead to penalties, fines, and legal consequences. These may include hefty monetary fines, imprisonment, and other legal actions as determined by the BIR. It is crucial to fulfill your tax obligations and comply with applicable tax laws to avoid these consequences.</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <h4>Benefits Compliance</h4>
              <Accordion>
                <Accordion.Item eventKey="13">
                  <Accordion.Header>What are the mandated benefits in the Philippines?</Accordion.Header>
                  <Accordion.Body>
                    <p>The mandated benefits in the Philippines include contributions to the Social Security System (SSS), Philippine Health Insurance Corporation (PhilHealth), Home Development Mutual Fund (Pag-IBIG Fund), and Employees' Compensation Program (ECP).</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="14">
                  <Accordion.Header>What is the Social Security System (SSS)?</Accordion.Header>
                  <Accordion.Body>
                    <p>The Social Security System (SSS) is a government agency that provides social security protection to employees in the Philippines. It covers benefits such as retirement, disability, sickness, maternity, and death benefits.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="15">
                  <Accordion.Header>What is the Philippine Health Insurance Corporation (PhilHealth)?</Accordion.Header>
                  <Accordion.Body>
                    <p>The Philippine Health Insurance Corporation (PhilHealth) is a national health insurance program that aims to provide accessible and affordable healthcare services to all Filipinos. It covers hospitalization, outpatient care, and other medical services.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="16">
                  <Accordion.Header>What is the Home Development Mutual Fund (Pag-IBIG Fund)?</Accordion.Header>
                  <Accordion.Body>
                    <p>The Home Development Mutual Fund (Pag-IBIG Fund) is a government agency that offers affordable housing financing to Filipinos. It also provides savings programs and multi-purpose loans for various purposes.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="17">
                  <Accordion.Header>What is the Employees' Compensation Program (ECP)?</Accordion.Header>
                  <Accordion.Body>
                    <p>The Employees' Compensation Program (ECP) is a program that provides benefits and assistance to employees who suffer work-related injuries, illnesses, or disabilities. It covers medical expenses, disability benefits, and other related support.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="18">
                  <Accordion.Header>Are these mandated benefits applicable to all employees?</Accordion.Header>
                  <Accordion.Body>
                    <p>Yes, these mandated benefits are generally applicable to all employees, including both local and foreign employees, subject to specific conditions and qualifying factors set by the respective government agencies.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="19">
                  <Accordion.Header>How are these mandated benefits funded?</Accordion.Header>
                  <Accordion.Body>
                    <p>These mandated benefits are funded through contributions from both employees and employers. The contributions are typically based on a percentage of the employee's salary or income, with the employer also making a corresponding contribution.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="20">
                  <Accordion.Header>What are the consequences of non-compliance with mandated benefits?</Accordion.Header>
                  <Accordion.Body>
                    <p>Non-compliance with mandated benefits can result in penalties, fines, and legal consequences for employers. It can also deprive employees of their entitled benefits. It is crucial for both employers and employees to fulfill their obligations and comply with the applicable laws and regulations to avoid these consequences.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="21">
                  <Accordion.Header>How can employees ensure they receive their mandated benefits?</Accordion.Header>
                  <Accordion.Body>
                    <p>Employees can ensure they receive their mandated benefits by verifying that their employers are remitting the required contributions to the respective government agencies. They can also regularly check their contribution records and keep track of their entitlements.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="22">
                  <Accordion.Header>Where can I find more information about these mandated benefits?</Accordion.Header>
                  <Accordion.Body>
                    <p>For more detailed information about these mandated benefits, it is advisable to visit the official websites of the respective government agencies, such as the Social Security System (SSS), Philippine Health Insurance Corporation (PhilHealth), Home Development Mutual Fund (Pag-IBIG Fund), and the Employees' Compensation Commission (ECC). These agencies provide comprehensive information, guidelines, and contact details for further inquiries.</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='get-started-form'>
        <Container>
          <Row>
            <Col className="col-12 col-lg-10 offset-lg-1">
              <div className='request-contact-form'>
                <h2 className='text-center'>Look no further. Get started with your outsourcing journey today!</h2>
                <p className='text-center'>Book a free session with one of Remote Philippines’s expert sales consultants. We'll get in touch with you to arrange a call, respond to any enquiries you might have, and begin onboarding your staff.</p>
                <GetRequestProposalForm />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {isMobile ? <MobileFooter /> : <Footer />}
    </ >
  );
}

export default Faq;
