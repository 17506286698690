import Header from "../include/header";
import Footer from "../include/footer";
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Images from "../../../constant/images";
import CommonMeta from "../include/CommonMeta";
import MobileHeader from "../include/mobileHeader";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import GetRequestProposalForm from "../../getRequestProposalForm";
import { useEffect, useState } from "react";
import MobileFooter from "../include/mobileFooter";

function OurMission() {
  
  const YOUTUBE_PLAYLIST_ITEMS_API = 'https://www.googleapis.com/youtube/v3/playlistItems';
  const [youTubedata, setyouTubedata] = useState([]);
  const getYoutubePlayList = async () => {
    const res = await fetch(`${YOUTUBE_PLAYLIST_ITEMS_API}?part=snippet&playlistId=PLeTyYUgffKJBhsRikxSZdOtLK5miEPyEZ&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`);
    const data = await res.json();
    setyouTubedata(data.items)
  }
  
  useEffect(() => {
    getYoutubePlayList();
  }, [])

  var ourTeam = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    autoplay: false,
    slidesToScroll: 3,
    speed: 1000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScrol1: 1,
        },
      },
      {
        breakpoint: 1008,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    autoplay:true,
    slidesToScroll: 1,
    speed: 1000,
    centerMode: true,
    centerPadding: '150px',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScrol1: 1,
        },
      },
      {
        breakpoint: 1008,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: '0px',
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: '0px',
        },
      },
    ],
  };

  return (
    <div>
      <CommonMeta
        title='Our Mission'
        description='Remote Philippines Dashboard | Our Mission'
        keywords='Remote Philippines Dashboard | Our Mission'
        name='Remote Philippines'
        type='article' />
      {isDesktop || isTablet ? <Header /> : <MobileHeader />}
      <div className='our-mission-wrapper global-banner-2 global-banner-pages bg-sea-1'>
        <Container className=''>
          <Row>
            <Col sm={12} md={12} lg={12} xl={6}>
              <div className="our-mission request-a-proposal-header-text request-a-proposal-header-text2">
                <h1 className="">About us</h1>
                <h2>Build teams quickly with us</h2>
                <div className=""><p className="">We are all about you, and that is the most important thing you need to know about us. We help you build your offshore team as easily, cheaply, and adaptably as possible.</p></div>
                <div className=""><p className="">We make an investment by working with you and your organization to develop a solution, locating the best employees with the best capabilities who fit your company, and preparing and getting your offshore crew set up and familiarized with your operations.</p></div>
                <div className=""><p className="">While we are aware of the variables that might derail even the most well-planned offshore projects, we help you when you run into problems and support you.</p></div>
              </div>
            </Col>
            <Col sm={12} md={12} lg={12} xl={6}>
              <div className="global-banner-2__gradient-overlay global-banner-role-pages d-flex justify-content-end">
                <div className="gradient-bar gradient-primary-2 d-lg-block" ></div>
                <div className="gradient-bar gradient-primarys-1 d-lg-block"></div>
              </div>
              <div className="our-mission-image-section">
                <div className="our-mission-image-top">
                  <img  loading="lazy" src={Images.ourMissionTop} className='img-fluid' alt='' />
                </div>
                {/* <div className="our-mission-image-bottom">
                  <div className="img-color gradient-primary-6"></div>
                  <img  loading="lazy" src={Images.bannerImgBottom} className='img-fluid' alt='' />
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="leadership-team-wrapper">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <div className="heading"><h3>Meet our core team</h3></div>
            </Col>
            <Col sm={12} md={12} lg={12}>
              <Slider {...ourTeam}>
                <div>
                  <div className="team-box border1">
                    <div className="team-image">
                      <img  loading="lazy" src={Images.team1} className='img-fluid' alt='' />
                    </div>
                    <div className="team-details">
                      <Link target="_blank" to='https://www.linkedin.com/in/jemicah-bentor-457527268/'> <img  loading="lazy" src={Images.linkedin} className='img-fluid' alt='' /></Link>
                      <div className="name">Jemicah Bentor</div>
                      <div className="profile">HR Specialist</div>
                      <p> Jemicah Bentor is a committed HR Specialist of Remote Philippines. She is responsible for employee relations, labor laws, and training programs. She has a proven track record of successfully managing employee issues and resolving conflicts.</p>
                      <div className="read-more-link"><Link to=''>  READ MORE <i className="fa-solid fa-arrow-right"></i></Link></div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="team-box border2">
                    <div className="team-image">
                      <img  loading="lazy" src={Images.team2} className='img-fluid' alt='' />
                    </div>
                    <div className="team-details">
                      <Link target="_blank" to='https://www.linkedin.com/in/johnzen-bandal-104598226/'> <img  loading="lazy" src={Images.linkedin} className='img-fluid' alt='' /></Link>
                      <div className="name">Johnzen Bandal</div>
                      <div className="profile">Business Developer</div>
                      <p> Johnzen Bandal serves as the lead point of contact for all client related matters and escalation. He is committed in delivering results and exceeding targets, while maintaining a high level of professionalism and integrity in all dealings.</p>
                      <div className="read-more-link"><Link to=''>  READ MORE <i className="fa-solid fa-arrow-right"></i></Link></div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="team-box border3">
                    <div className="team-image">
                      <img  loading="lazy" src={Images.team3} className='img-fluid' alt='' />
                    </div>
                    <div className="team-details">
                      <Link to=''> <img  loading="lazy" src={Images.linkedin} className='img-fluid' alt='' /></Link>
                      <div className="name">Debbie Latoza</div>
                      <div className="profile">Managing Director</div>
                      <p> Debbie Latoza is a visionary entrepreneur and leader, who founded Remote Philippines with the mission to transform the way businesses operate and create opportunities for talented professionals in the Philippines to work remotely and thrive in their careers.</p>
                      <div className="read-more-link"><Link to=''>  READ MORE <i className="fa-solid fa-arrow-right"></i></Link></div>
                    </div>
                  </div>
                </div>
                {/* <div>
                  <div className="team-box border1">
                    <div className="team-image">
                      <img  loading="lazy" src={Images.team4} className='img-fluid' alt='' />
                    </div>
                    <div className="team-details">
                      <Link target="_blank" to='https://www.linkedin.com/in/anjlandicho/'> <img  loading="lazy" src={Images.linkedin} className='img-fluid' alt='' /></Link>
                      <div className="name">Angela Mae Landicho</div>
                      <div className="profile">General Manager</div>
                      <p> Angela plays a crucial role in overseeing operations, setting strategic direction, and achieving organisational goals. She leads and inspire teams, make critical decisions, manage resources, ensure efficient processes, foster a positive work culture, and drive business growth and success.</p>
                      <div className="read-more-link"><Link to=''>  READ MORE <i className="fa-solid fa-arrow-right"></i></Link></div>
                    </div>
                  </div>
                </div> */}
                <div>
                  <div className="team-box border2">
                    <div className="team-image">
                      <img  loading="lazy" src={Images.team13} className='img-fluid' alt='' />
                    </div>
                    <div className="team-details">
                    <Link target="_blank" to='https://www.linkedin.com/in/christian-paul-racadio'> <img  loading="lazy" src={Images.linkedin} className='img-fluid' alt='' /></Link>
                      <div className="name">Christian Paul Racadio</div>
                      <div className="profile">Digital Content Creator</div>
                      <p> Christian Paul Racadio upholds the company’s brand guidelines to create visually appealing graphic designs, marketing collaterals, video and photography. Actively engages with the social media community through live streaming and posting.</p>
                      <div className="read-more-link"><Link to=''>  READ MORE <i className="fa-solid fa-arrow-right"></i></Link></div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="team-box border3">
                    <div className="team-image">
                      <img  loading="lazy" src={Images.team6} className='img-fluid' alt='' />
                    </div>
                    <div className="team-details">
                      <Link target="_blank" to='https://www.linkedin.com/in/princess-ann-olivaria-aa8991255/'> <img  loading="lazy" src={Images.linkedin} className='img-fluid' alt='' /></Link>
                      <div className="name">Princess Olivaria</div>
                      <div className="profile">Lead Generation Specialist</div>
                      <p> Princess Ann Olivaria is one of the team members of the Lead Generation team. She is mainly responsible for gathering leads and finding potential clients for the sales team in order to meet business plans, quotas and company objectives.</p>
                      <div className="read-more-link"><Link to=''>  READ MORE <i className="fa-solid fa-arrow-right"></i></Link></div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="team-box border1">
                    <div className="team-image">
                      <img  loading="lazy" src={Images.team7} className='img-fluid' alt='' />
                    </div>
                    <div className="team-details">
                      <Link target="_blank" to='https://www.linkedin.com/in/syena-alix-b90260120/'> <img  loading="lazy" src={Images.linkedin} className='img-fluid' alt='' /></Link>
                      <div className="name">Syena Alix</div>
                      <div className="profile">Talent Specialist</div>
                      <p> Syena Cyril Alix is the Senior Technical Recruiter of Remote Philippines. She is leading the full recruitment process and hiring exceptional Filipino talent. Delivering excellent service for both technical and non-technical professions worldwide.</p>
                      <div className="read-more-link"><Link to=''>  READ MORE <i className="fa-solid fa-arrow-right"></i></Link></div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="team-box border2">
                    <div className="team-image">
                      <img  loading="lazy" src={Images.team8} className='img-fluid' alt='' />
                    </div>
                    <div className="team-details">
                      <Link target="_blank" to='https://www.linkedin.com/in/jazelalcantara/'> <img  loading="lazy" src={Images.linkedin} className='img-fluid' alt='' /></Link>
                      <div className="name">Jazel Alcantara</div>
                      <div className="profile">Talent Specialist</div>
                      <p> Jazel Alcantara is the Technical Recruiter of Remote Philippines. She is in charge of full-cycle recruitment and delivering high-caliber Filipino talents globally. Continuously updated with new recruitment trends to keep up with a fast-paced environment.</p>
                      <div className="read-more-link"><Link to=''>  READ MORE <i className="fa-solid fa-arrow-right"></i></Link></div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="team-box border3">
                    <div className="team-image">
                      <img  loading="lazy" src={Images.team9} className='img-fluid' alt='' />
                    </div>
                    <div className="team-details">
                      <Link target="_blank" to='https://www.linkedin.com/in/danica-parra/'> <img  loading="lazy" src={Images.linkedin} className='img-fluid' alt='' /></Link>
                      <div className="name">Danica Abenis</div>
                      <div className="profile">Social Media Manager</div>
                      <p> Danica Parra - Abenis is the Social Media Manager of Remote Philippines. Her duties include managing all the social media accounts of the company, designing graphics and marketing collateral, video editing, conducting live and recorded webinars and interviews.</p>
                      <div className="read-more-link"><Link to=''>  READ MORE <i className="fa-solid fa-arrow-right"></i></Link></div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="team-box border1">
                    <div className="team-image">
                      <img  loading="lazy" src={Images.team10} className='img-fluid' alt='' />
                    </div>
                    <div className="team-details">
                      <Link target="_blank" to='https://www.linkedin.com/in/aerika-francisco-4389aa127/'> <img  loading="lazy" src={Images.linkedin} className='img-fluid' alt='' /></Link>
                      <div className="name">Aerika Francisco</div>
                      <div className="profile">Business Developer</div>
                      <p> Aerika Francisco has worked with a variety of industries and clients to help achieve business goals. She uses her experience in sales and business-related roles to contribute innovative ideas to the company and its future growth within an industry.</p>
                      <div className="read-more-link"><Link to=''>  READ MORE <i className="fa-solid fa-arrow-right"></i></Link></div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="team-box border2">
                    <div className="team-image">
                      <img  loading="lazy" src={Images.team11} className='img-fluid' alt='' />
                    </div>
                    <div className="team-details">
                      <Link target="_blank" to='https://www.linkedin.com/in/msaireenva/'> <img  loading="lazy" src={Images.linkedin} className='img-fluid' alt='' /></Link>
                      <div className="name">Mary Brillantes</div>
                      <div className="profile">Lead Generation Specialist</div>
                      <p> Mary Brillantes is a lead generation expert. She has shown credible results in LinkedIn outreach, running marketing campaigns and internet research. She assists sales and marketing in developing a lead pipeline to meet the company's objectives and quota.</p>
                      <div className="read-more-link"><Link to=''>  READ MORE <i className="fa-solid fa-arrow-right"></i></Link></div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="team-box border2">
                    <div className="team-image">
                      <img  loading="lazy" src={Images.team12} className='img-fluid' alt='' />
                    </div>
                    <div className="team-details">
                      <Link target="_blank" to='https://www.linkedin.com/in/jhon-rockie-magracia-5a3217232'> <img  loading="lazy" src={Images.linkedin} className='img-fluid' alt='' /></Link>
                      <div className="name">Rockie Magracia</div>
                      <div className="profile">Talent Specialist</div>
                      <p> Rockie Magracia is one of the Talent Specialists of Remote Philippines. He is responsible for recruiting top-quality Filipino talents. He aims to keep up with the latest innovations about recruitment to adapt with the ever-changing global demands.</p>
                      <div className="read-more-link"><Link to=''>  READ MORE <i className="fa-solid fa-arrow-right"></i></Link></div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="team-box border2">
                    <div className="team-image">
                      <img  loading="lazy" src={Images.team14} className='img-fluid' alt='' />
                    </div>
                    <div className="team-details">
                      <Link target="_blank" to='https://www.linkedin.com/in/krystel-quintal'> <img  loading="lazy" src={Images.linkedin} className='img-fluid' alt='' /></Link>
                      <div className="name">Arriadnah Krystel Quintal</div>
                      <div className="profile">HR Associate</div>
                      <p> Arriadnah Krystel Quintal is actively engaged in onboarding, employee relations, and ensuring compliance with HR policies. She is dedicated to fostering a positive work environment and supporting the growth and development of the employees.</p>
                      <div className="read-more-link"><Link to=''>  READ MORE <i className="fa-solid fa-arrow-right"></i></Link></div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="team-box border2">
                    <div className="team-image">
                      <img  loading="lazy" src={Images.team15} className='img-fluid' alt='' />
                    </div>
                    <div className="team-details">
                      <Link target="_blank" to='https://www.linkedin.com/in/zildjian-santos-b88b24286'> <img  loading="lazy" src={Images.linkedin} className='img-fluid' alt='' /></Link>
                      <div className="name">Zildian Santos</div>
                      <div className="profile">Digital Marketing Associate</div>
                      <p> Zildjian Santos is the Digital Marketing Associate of Remote Philippines. He is responsible for supporting the marketing team through content creation, social media management, community management and many more. He dedicates himself to quality work so that the company can reach its audiences.</p>
                      <div className="read-more-link"><Link to=''>  READ MORE <i className="fa-solid fa-arrow-right"></i></Link></div>
                    </div>
                  </div>
                </div>
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="drives-us">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <div className="drives-us-inner-section ">
                {/* <div className="border-left gradient-secondary-11"></div> */}
                <h4>What drives us</h4>
                <h5 className="col-sm-12 col-md-12 col-lg-6">Our <strong> vision</strong> is to be the top provider of Filipino talents in the world.</h5>
                <h5 className="col-sm-12 col-md-12 col-lg-6">Our <strong>mission</strong> is to create great jobs for every Filipino for a better life.</h5>
                <p className="col-sm-12 col-md-12 col-lg-6">Our staff's well-being and best interests will always come first in all that we do. Our ability to draw in and keep the best talent is a key component of our entire business model. We simply cannot run our business without a thriving talent network. Our employees' best interests will always come first - before anything else — in every business decision we make.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="outsourcing-any-role-section youtube-video hear-our-team our-mission">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <div className="global-banner-2__gradient-overlay d-flex justify-content-end">
                <div className="gradient-bar gradient-primary-2 d-lg-block" ></div>
                <div className="gradient-bar gradient-primarys-1 d-lg-block"></div>
                {/* <div className="gradient-bar gradient-secondary-1 d-lg-block" ></div>
                <div className="gradient-bar gradient-secondary-5 d-lg-block" ></div>
                <div className="gradient-bar gradient-secondary-6 d-lg-block"></div> */}
              </div>
            </Col>
            <Col sm={12} md={12} lg={12}>
              <div className="customer-slider-section outsourcing-any-role-slider">
                <div className='slider-heading'>
                  <h4>OUR STORIES</h4>
                  <h2>Our staff mean the world to us and their happiness is the key to our success. Here are some of the stories of inspiration which create profound purpose and meaning to what we do.</h2>
                </div>
                <Slider {...settings}>

                  {
                    youTubedata.map((item, i) => {
                      const { id, snippet = {} } = item;
                      const { title, thumbnails = {}, resourceId } = snippet;
                      const { standard = {} } = thumbnails;
                      return (
                        <div key={i}>
                          <div className="customer-box">
                            <div className="customer-image yuotube-list">
                              <iframe width={"100%"} height={"440px"} src={`https://www.youtube.com/embed/${resourceId.videoId}`} title={title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                            </div>
                          </div>
                        </div>
                      );
                    })
                  }

                  {/* <div>
                    <div className="customer-box">
                      <div className="customer-image">
                        <img  loading="lazy" src={Images.pangeoAwards} className='img-fluid' alt='' />
                      </div>
                      <div className="customer-details">
                        <h2><Link to=''> G-P Unveils the Winners of the Inaugural 2021 PANGEO Awards</Link></h2>
                        <div className="read-more-link"><Link to=''>  READ MORE <i className="fa-solid fa-arrow-right"></i></Link></div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="customer-box">
                      <div className="customer-image">
                        <img  loading="lazy" src={Images.pangeoAwards} className='img-fluid' alt='' />
                      </div>
                      <div className="customer-details">
                        <h2><Link to=''> G-P Unveils the Winners of the Inaugural 2021 PANGEO Awards</Link></h2>
                        <div className="read-more-link"><Link to=''>  READ MORE <i className="fa-solid fa-arrow-right"></i></Link></div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="customer-box">
                      <div className="customer-image">
                        <img  loading="lazy" src={Images.pangeoAwards} className='img-fluid' alt='' />
                      </div>
                      <div className="customer-details">
                        <h2><Link to=''> G-P Unveils the Winners of the Inaugural 2021 PANGEO Awards</Link></h2>
                        <div className="read-more-link"><Link to=''>  READ MORE <i className="fa-solid fa-arrow-right"></i></Link></div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="customer-box">
                      <div className="customer-image">
                        <img  loading="lazy" src={Images.pangeoAwards} className='img-fluid' alt='' />
                      </div>
                      <div className="customer-details">
                        <h2><Link to=''> G-P Unveils the Winners of the Inaugural 2021 PANGEO Awards</Link></h2>
                        <div className="read-more-link"><Link to=''>  READ MORE <i className="fa-solid fa-arrow-right"></i></Link></div>
                      </div>
                    </div>
                  </div> */}
                </Slider>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='join-our-team'>
        <Row className='g-0'>
          <div className='general-join-gallery'>
            <div className='gallery-item'>
              <img  loading="lazy" src={Images.collage} alt='' className='img-fluid' />
            </div>
            <div className='gallery-item'>
              <img  loading="lazy" src={Images.Office} alt='' className='img-fluid' />
            </div>
            <div className='gallery-item'>
              <img  loading="lazy" src={Images.Profile_with_Gradient_Crop} alt='' className='img-fluid' />
            </div>
            <div className='gallery-item'>
              <img  loading="lazy" src={Images.Work_freedom} alt='' className='img-fluid' />
            </div>
            <div className='gallery-item'>
              <img  loading="lazy" src={Images.Ms_Jai} alt='' className='img-fluid' />
            </div>
            <div className='gallery-item'>
              <img  loading="lazy" src={Images.Ms_Grace_and_client} alt='' className='img-fluid' />
            </div>
            <div className='gallery-item px-5 pt-lg-0 px-xl-7 px-xxl-8 d-flex'>
              <div className='gallery-content my-5 mx-auto align-self-center overflow-hidden text-center text-sm-start'>
                <h3>Join our</h3>
                <img  loading="lazy" src={Images.teamWord} alt='' className='img-fluid' />
                <p>The key to our success depends on our people. We empower a work-from-home lifestyle that fully engages a remote workforce, promotes trust, teamwork, and professional advancement to realize their potential, wherever they are, while honoring personal and professional boundaries.</p>
                <div className='mt-4'><a target="_blank" className='link-btn' href='https://docs.google.com/forms/d/e/1FAIpQLSdsQCLNBYHXTm6QAnrQg7byRJK2FIGXO3ET-IN5tQEuNXTlVw/viewform?fbclid=IwAR3iZPYYPTNeiFpNf1SQuAUC1-05L25BLZXPpGxslMz5xkgB4QFNnP5wWZw'>APPLY NOW</a></div>
              </div>
            </div>
            <div className='gallery-item'>
              <img  loading="lazy" src={Images.Profile_Gradient_Crop} alt='' className='img-fluid' />
            </div>
            <div className='gallery-item'>
              <img  loading="lazy" src={Images.Profile_with_Gradient} alt='' className='img-fluid' />
            </div>
            <div className='gallery-item'>
              <img  loading="lazy" src={Images.Ms_Debbie} alt='' className='img-fluid' />
            </div>
            <div className='gallery-item'>
              <img  loading="lazy" src={Images.Aica} alt='' className='img-fluid' />
            </div>
          </div>
        </Row>
      </div>
      <div className="outsourcing-journey">
        <Container>
          <Row className="align-items-center">
            <Col sm={12} md={12} lg={6}>
              <div className="outsourcing-journey-inner  col-md-11">
                {/* <div className="border-left gradient-secondary-5"></div> */}
                <h3>Start your outsourcing journey today</h3>
                <p>We assist your team by matching you with an offshore employee or team to the Philippines who will be entirely focused on assisting your company's operations. Your team will be able to concentrate on the essential aspects of your organization, strategic initiatives, maintaining relationships with clients, and business expansion that will boost productivity, drive profits, and enhance employee happiness.</p>
                <p>The talent we offer to our clients should, in the first place, feel like a seamless extension of their current operations. We will work closely with you in cooperation delivering to you a team of top performers who are well matched to your company vision, mission, and culture. </p>
                <div className="mt-4"><Link className='link-btn' to='/request-proposal'>REQUEST A PROPOSAL</Link></div>
              </div>
            </Col>
            <Col sm={12} md={12} lg={6}>
              <div className="outsourcing-journey-image-section">
                <img  loading="lazy" src={Images.Our_Mission} alt='' className='img-fluid' />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="outsourcing-numbers">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={6}>
              <div className="outsourcing-numbers-inner">
                {/* <div className="gradient-bg gradient-primary-2"></div> */}
                <h4>Outsourcing by the numbers:</h4>
                <div className="number-section">
                  <div className="number gradient-text">USD25bn</div>
                  <div className="text">sector revenue</div>
                </div>
                <div className="number-section">
                  <div className="number gradient-text">48M</div>
                  <div className="text">Filipino workforce</div>
                </div>
                <div className="number-section">
                  <div className="number gradient-text">59%</div>
                  <div className="text">firms who outsource</div>
                </div>
                <div className="number-section">
                  <div className="number gradient-text ">70%</div>
                  <div className="text">cost savings</div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='get-hire-form'>
        <Row className="g-0">
          <Col>
            <div className='request-contact-form'>
              <div className='bg-right-wrapper'>
                <div className="bg-right"></div>
                <div className="bg-right2"></div>
              </div>
              <Container className='position-relative'>
                <Row>
                  <Col md="12">
                    <div className="form-heading">
                      <h2 className=''>Build amazing teams, hire world-class talent.</h2>
                    </div>
                  </Col>
                  <Col sm={12} md={12} lg={5}>
                    <div className="form-heading">
                      <p className=''>If you’re ready to build your team, grow or have questions about our services, just complete the form and one of our experts will be in touch shortly.</p>
                    </div>
                    <div className="form-image-wrapper">
                      <img  loading="lazy" src={Images.Asset9} alt='' className='img-fluid'></img>
                    </div>
                  </Col>
                  <Col sm={12} md={12} lg={7}>
                    <GetRequestProposalForm />
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </div>
      {isMobile ? <MobileFooter /> : <Footer />}
    </div>
  );
}

export default OurMission;
