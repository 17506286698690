import Header from "../include/header";
import Footer from "../include/footer";
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Images from "../../../constant/images";
import CommonMeta from "../include/CommonMeta";
import MobileHeader from "../include/mobileHeader";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import GetRequestProposalForm from "../../getRequestProposalForm";
import MobileFooter from "../include/mobileFooter";

function OurPeople() {
  return (
    <div>
      <CommonMeta
        title='Our People'
        description='Remote Philippines Dashboard | Our People'
        keywords='Remote Philippines Dashboard | Our People'
        name='Remote Philippines'
        type='article' />
      <div className='header2-wrapper'>
      {isDesktop || isTablet ? <Header /> : <MobileHeader />}
      </div>
      <div className='our-people-hero'>
        <Container>
          <Row className=''>
            <Col md={10} >
              <div className='our-people-hero-inner our-solution-details our-solution-inner-section'>
                <h4>ABOUT US</h4>
                <h3>Meet our people</h3>
                <p>These are the people who make Remote Philippines the thriving organization it is today by going above and beyond for our employees, our clients, and our business.</p>
              </div>
            </Col>
            <Col md={2}></Col>
          </Row>
        </Container>
      </div>
      <div className="our-people-meet">
        <Container>
          <Row>
            <Col md={4}>
              <div className="card-wrapper">
                <div className="team-image">
                  <img  loading="lazy" src={Images.team3} className='img-fluid' alt='' />
                </div>
                <div className="card-body">
                  <Link to=''> <img  loading="lazy" src={Images.linkedin} className='img-fluid' alt='' /></Link>
                  <div className="name">Debbie Latoza</div>
                  <div className="profile">Managing Director</div>
                </div>
              </div>
            </Col>
            {/* <Col md={4}>
              <div className="card-wrapper">
                <div className="team-image">
                  <img  loading="lazy" src={Images.team4} className='img-fluid' alt='' />
                </div>
                <div className="card-body">
                  <Link target="_blank" to='https://www.linkedin.com/in/anjlandicho/'> <img  loading="lazy" src={Images.linkedin} className='img-fluid' alt='' /></Link>
                  <div className="name">Angela Mae Landicho</div>
                  <div className="profile">General Manager</div>
                </div>
              </div>
            </Col> */}
            <Col md={4}>
              <div className="card-wrapper">
                <div className="team-image">
                  <img  loading="lazy" src={Images.team7} className='img-fluid' alt='' />
                </div>
                <div className="card-body">
                  <Link target="_blank" to='https://www.linkedin.com/in/syena-alix-b90260120/'> <img  loading="lazy" src={Images.linkedin} className='img-fluid' alt='' /></Link>
                  <div className="name">Syena Alix</div>
                  <div className="profile">Talent Specialist</div>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="card-wrapper">
                <div className="team-image">
                  <img  loading="lazy" src={Images.team8} className='img-fluid' alt='' />
                </div>
                <div className="card-body">
                  <Link target="_blank" to='https://www.linkedin.com/in/jazelalcantara/'> <img  loading="lazy" src={Images.linkedin} className='img-fluid' alt='' /></Link>
                  <div className="name">Jazel Alcantara</div>
                  <div className="profile">Talent Specialist</div>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="card-wrapper">
                <div className="team-image">
                  <img  loading="lazy" src={Images.team1} className='img-fluid' alt='' />
                </div>
                <div className="card-body">
                  <Link target="_blank" to='https://www.linkedin.com/in/jemicah-bentor-457527268/'> <img  loading="lazy" src={Images.linkedin} className='img-fluid' alt='' /></Link>
                  <div className="name">Jemicah Bentor</div>
                  <div className="profile">HR Specialist</div>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="card-wrapper">
                <div className="team-image">
                  <img  loading="lazy" src={Images.team2} className='img-fluid' alt='' />
                </div>
                <div className="card-body">
                  <Link target="_blank" to='https://www.linkedin.com/in/johnzen-bandal-104598226/'> <img  loading="lazy" src={Images.linkedin} className='img-fluid' alt='' /></Link>
                  <div className="name">Johnzen Bandal</div>
                  <div className="profile">Business Developer</div>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="card-wrapper">
                <div className="team-image">
                  <img  loading="lazy" src={Images.team10} className='img-fluid' alt='' />
                </div>
                <div className="card-body">
                  <Link target="_blank" to='https://www.linkedin.com/in/aerika-francisco-4389aa127/'> <img  loading="lazy" src={Images.linkedin} className='img-fluid' alt='' /></Link>
                  <div className="name">Aerika Francisco</div>
                  <div className="profile">Business Developer</div>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="card-wrapper">
                <div className="team-image">
                  <img  loading="lazy" src={Images.team13} className='img-fluid' alt='' />
                </div>
                <div className="card-body">
                <Link target="_blank" to='https://www.linkedin.com/in/christian-paul-racadio'> <img  loading="lazy" src={Images.linkedin} className='img-fluid' alt='' /></Link>
                  <div className="name">Christian Paul Racadio</div>
                  <div className="profile">Digital Content Creator</div>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="card-wrapper">
                <div className="team-image">
                  <img  loading="lazy" src={Images.team11} className='img-fluid' alt='' />
                </div>
                <div className="card-body">
                  <Link target="_blank" to='https://www.linkedin.com/in/msaireenva/'> <img  loading="lazy" src={Images.linkedin} className='img-fluid' alt='' /></Link>
                  <div className="name">Mary Brillantes</div>
                  <div className="profile">Lead Generation Specialist</div>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="card-wrapper">
                <div className="team-image">
                  <img  loading="lazy" src={Images.team6} className='img-fluid' alt='' />
                </div>
                <div className="card-body">
                  <Link target="_blank" to='https://www.linkedin.com/in/princess-ann-olivaria-aa8991255/'> <img  loading="lazy" src={Images.linkedin} className='img-fluid' alt='' /></Link>
                  <div className="name">Princess Olivaria</div>
                  <div className="profile">Lead Generation Specialist</div>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="card-wrapper">
                <div className="team-image">
                  <img  loading="lazy" src={Images.team9} className='img-fluid' alt='' />
                </div>
                <div className="card-body">
                  <Link target="_blank" to='https://www.linkedin.com/in/danica-parra/'> <img  loading="lazy" src={Images.linkedin} className='img-fluid' alt='' /></Link>
                  <div className="name">Danica Abenis</div>
                  <div className="profile">Social Media Manager</div>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="card-wrapper">
                <div className="team-image">
                  <img  loading="lazy" src={Images.team12} className='img-fluid' alt='' />
                </div>
                <div className="card-body">
                <Link target="_blank" to='https://www.linkedin.com/in/jhon-rockie-magracia-5a3217232'> <img  loading="lazy" src={Images.linkedin} className='img-fluid' alt='' /></Link>
                  <div className="name">Rockie Magracia</div>
                  <div className="profile">Talent Specialist</div>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="card-wrapper">
                <div className="team-image">
                  <img  loading="lazy" src={Images.team14} className='img-fluid' alt='' />
                </div>
                <div className="card-body">
                <Link target="_blank" to='https://www.linkedin.com/in/krystel-quintal'> <img  loading="lazy" src={Images.linkedin} className='img-fluid' alt='' /></Link>
                  <div className="name">Arriadnah Krystel Quintal</div>
                  <div className="profile">HR Associate</div>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="card-wrapper">
                <div className="team-image">
                  <img  loading="lazy" src={Images.team15} className='img-fluid' alt='' />
                </div>
                <div className="card-body">
                <Link target="_blank" to='https://www.linkedin.com/in/zildjian-santos-b88b24286'> <img  loading="lazy" src={Images.linkedin} className='img-fluid' alt='' /></Link>
                  <div className="name">Zildian Santos</div>
                  <div className="profile">Digital Marketing Associate</div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="join-team-wrapper">
        <Container>
          <Row>
            <Col md={6}>
              <h5>CAREERS</h5>
              <h2>Join Our Team</h2>
              <p>The key to our success depends on our people. We empower a work-from-home lifestyle that fully engages a remote workforce, promotes trust, teamwork, and professional advancement to realize their potential, wherever they are, while honoring personal and professional boundaries.</p>
              <div className="mt-4 mb-3"><Link className='link-btn' to='/careers'>SEE CAREERS</Link></div>
            </Col>
            <Col md={6}>
              <img  loading="lazy" src={Images.Join_Our_Team} className='img-fluid' alt='' />
            </Col>
          </Row>
        </Container>
      </div>
      <div className='get-started-form'>
        <Container>
          <Row>
            <Col className="col-12 col-lg-10 offset-lg-1">
              <div className='request-contact-form'>
                <h2 className='text-center'>Look no further. Get started with your outsourcing journey today!</h2>
                <p className='text-center'>Book a free session with one of Remote Philippines’s expert sales consultants. We'll get in touch with you to arrange a call, respond to any enquiries you might have, and begin onboarding your staff.</p>
                <GetRequestProposalForm />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {isMobile ? <MobileFooter /> : <Footer />}
    </div>
  );
}

export default OurPeople;
